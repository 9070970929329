import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetTokenService } from './get-token.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {

  constructor(private http: HttpClient, private tokenService: GetTokenService) { }
  isObject(item:any) {
    return item !== null && typeof item === 'object' && !Array.isArray(item);
  }
  getAPI(url: string, props?: any): Observable<any> {
    const token = this.tokenService.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });

    let params = new HttpParams()
    if (props) {
      Object.keys(props)
      .forEach(key => {
        const value = props[key];
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if(this.isObject(item)){
              Object.keys(item)
              .forEach(key2 => {
                params = params.append(`${key}[${index}].${key2}`, item[key2].toString());
              })
            }
            else{
              params = params.append(`${key}[${index}]`, item.toString());
            }
          });
        } else {
          params = params.set(key, value.toString());
        }
      });
    }

    return this.http.get(environment.apiUrl + url, { headers, params })
  }

  putApi(url: string, body:any, props?: any): Observable<any> {
    const token = this.tokenService.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });

    let params = new HttpParams()
    if (props) {
      Object.keys(props)
      .forEach(key => {
        const value = props[key];
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            params = params.append(`${key}[${index}]`, item.toString());
          });
        } else {
          params = params.set(key, value.toString());
        }
      });
    }

    return this.http.put(environment.apiUrl + url, body,  { headers, params })
  }
  
  postApi(url: string, body:any, props?: any): Observable<any> {
    const token = this.tokenService.getToken() 
    ? this.tokenService.getToken() :
    body.token
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });

    let params = new HttpParams()
    if (props) {
      Object.keys(props)
      .forEach(key => {
        const value = props[key];
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            params = params.append(`${key}[${index}]`, item.toString());
          });
        } else {
          params = params.set(key, value.toString());
        }
      });
    }

    return this.http.post(environment.apiUrl + url, body,  { headers, params })
  }

  deleteApi(url: string, props?: any): Observable<any> {
    const token = this.tokenService.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });

    let params = new HttpParams()
    if (props) {
      Object.keys(props)
      .forEach(key => {
        const value = props[key];
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if(this.isObject(item)){
              Object.keys(item)
              .forEach(key2 => {
                params = params.append(`${key}[${index}][${key2}]`, item[key2].toString());
              })
            }
            else{
              params = params.append(`${key}[${index}]`, item.toString());
            }
          });
        } else {
          params = params.set(key, value.toString());
        }
      });
    }

    return this.http.delete(environment.apiUrl + url, { headers, params })
  }

  

}
