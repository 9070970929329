<ng-container *ngIf="(isLogged$ | async) as isLogged; else login">
    <div *ngIf="isLogged" class="">
        <app-navbar></app-navbar>
        <div class="flex flex-col justify-center items-center">
            <div class="w-full flex justify-center items-center pb-[150px] max-w-[1800px] px-4 md:px-16 lg:px-8">
                <section data-aos="fade-left" data-aos-duration="800" class="w-full">
                    <router-outlet></router-outlet>
                </section>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #login>
    <router-outlet></router-outlet>
</ng-template>

<!-- Dialogs -->
<app-payment-info></app-payment-info>
<app-inscription></app-inscription>
<app-confirmacion></app-confirmacion>
<app-confirm-baja></app-confirm-baja>
<app-delete-capacitacion></app-delete-capacitacion>
<app-pay-file></app-pay-file>
<app-firm-file></app-firm-file>
<app-certificacion-file></app-certificacion-file>
<app-certificacion-confirm></app-certificacion-confirm>
<app-certificacion-show></app-certificacion-show>
<app-views-dialog></app-views-dialog>
<app-firma-file></app-firma-file>
<p-toast></p-toast>
<app-link-post></app-link-post>
<app-exam-file> </app-exam-file>
<app-delete-dialogs></app-delete-dialogs>
<app-examen-confirm></app-examen-confirm>
<app-dialog-put-form></app-dialog-put-form>
<app-dialog-with-actions></app-dialog-with-actions>
<app-view-exam></app-view-exam>