import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, DefaultValueAccessor, FormControl, UntypedFormArray } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() control!: FormControl;

  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() icon!: string
  @Input() value!: any
  @Input() background: string = 'transparent'

  @ViewChild(DefaultValueAccessor) valueAccessor!: DefaultValueAccessor;

  delegatedMethodCalls = new ReplaySubject<(_: ControlValueAccessor) => void>();

  ngAfterViewInit(): void {
    this.delegatedMethodCalls.subscribe(fn => fn(this.valueAccessor));
  }

  registerOnChange(fn: (_: any) => void): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.registerOnChange(fn));
  }
  registerOnTouched(fn: () => void): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.registerOnTouched(fn));
  }

  setDisabledState(isDisabled: boolean): void {
    this.delegatedMethodCalls.next((valueAccessor: any) => {
      valueAccessor.setDisabledState(isDisabled);
    });
  }


  writeValue(obj: any): void {
    this.delegatedMethodCalls.next(valueAccessor => valueAccessor.writeValue(obj));
  }
}