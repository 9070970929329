import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { TableI } from 'src/app/atoms/table/table.interface';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { MatriculadosService } from 'src/app/core/services/matriculados.service';
import { capitalize, fixEncoding } from 'src/app/core/utils/functions';
import { getMatriculado, getMatriculados, paginationMatriculados } from 'src/app/store/actions/matriculados.action';
import { AppState } from 'src/app/store/appReducer';
import { selectLoadingMatriculados, selectMatriculadosList } from 'src/app/store/selectors/matriculados.selector';

@Component({
  selector: 'app-matriculados',
  templateUrl: './matriculados.component.html'
})
export class MatriculadosComponent implements OnInit, OnDestroy {

  constructor(private store: Store<AppState>,
    private router: Router,
    private matServ: MatriculadosService,
    private restAPI :ApiRestService,
    private messageService:MessageService
    ) { }

  _loading!: Subscription
  loading: boolean = true
  rows!: any
  first = 0
  matriculados$!: Observable<any>
  _matriculados!: Subscription
  dataRow!: any
  columns: TableI[] = [
    {
      label: 'Nombre y Apellido',
      key: 'fullname',
      type: "string"
    },
    {
      label: 'N° de matrícula',
      key: 'matricula',
      type: "idCOPA"
    },
    {
      label: 'Puntos',
      key: 'point',
      type: "string"
    },
    {
      label: 'Acciones',
      key: '',
      type: 'action',
      action: [
        {
          action: (ev: any, row: any) => {
            // idCapacitacion: any, inscription_id:any, student_id:any
            this.navigateToRoute(row)
          },
          svg: 'assets/icons/table/eye.svg',
          svgHover: 'assets/icons/table/eyeHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        }

      ]
    }
  ]

  matOptions = [
    {
      title: 'Descargar listado',
      action: () => {
        this.downloadLista()
      },
      image: 'assets/icons/download.svg'
    },
    {
      title: 'Adjuntar listado',
      action: () =>{
        this.clickLista()
      },
      image: 'assets/icons/paperclip.svg'
    }
  ]

  @ViewChild('lista') uploadLista!: ElementRef;

  clickLista(){
    this.uploadLista.nativeElement.click()
  }

  downloadLista(){
    this.matServ.getFile().subscribe((response: any) => {
      this.saveFile(response.body, response.headers);
    });
  }

  saveFile(blob: Blob, headers: any): void {
    const contentDisposition = headers.get('content-disposition');
    const fileName = contentDisposition ? contentDisposition.split(';')[1].split('filename=')[1].trim() : 'listado';

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const fileBlob = new Blob([file]);
      // Display the selected image
     const formData = new FormData()
     
      formData.append('file', fileBlob, file.name)

      this.restAPI.postApi('users/import', formData)
      .subscribe(
        {
          next:(res)=>{
            this.messageService.add(
              {
                severity:'info',
                detail: 'Archivo de matriculado subido correctamente procesando la información.'
              }
            )
          },
          error:(err)=>{
            this.messageService.add(
              {
                severity:'error',
                detail: 'Un error ha ocurrido intente nuevamente mas tarde'
              }
            )
          }
        }
      )

    }
  }

  onPageChange(event: any) {
    this.store.dispatch(paginationMatriculados({
      props:
      {
        page: event.page ? event.page + 1 : 1
      }
    }))
    if (event.first) {
      this.first = event.first
    }
  }

  navigateToRoute(matriculado: any) {
    this.store.dispatch(getMatriculado({ matriculado: matriculado }))
    this.router.navigate([`matriculados/${matriculado.matricula}`])
  }
 

  ngOnInit(): void {

    this._loading = this.store.select(selectLoadingMatriculados)
      .subscribe((d) => this.loading = d)

    this.store.dispatch(getMatriculados())
    this.matriculados$ = this.store.select(selectMatriculadosList)
    this._matriculados = this.matriculados$.subscribe((mat) => {
      const { ['data']: matriculados, ...rows } = mat
      this.rows = rows
      this.dataRow = matriculados?.map(
        (el: any) => {
          return {
            ...el,
            point: parseFloat(el.point),
            fullname: (el.name) + ' ' + capitalize(((el.last_name as string)).toLowerCase())
          }
        }
      )
      
    })

  }
  ngOnDestroy(): void {
    this._matriculados?.unsubscribe()
    this._loading?.unsubscribe()
  }
}
