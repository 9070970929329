<section class="pt-4" data-aos="fade-left" data-aos-duration="800">
    <div class="pb-6 flex gap-4">
        <app-button-tab *ngFor="let year of years" [active]="actualYear == year" (click)="changeYear(year)" [value]="year.toString()" [title]="year.toString()"></app-button-tab>
    </div>
    <div class="flex justify-between items-center w-full pb-4">
        <app-titles-atom title="Capacitaciones realizadas"></app-titles-atom>
        <h2 class="text-4xl text-primary">
            {{
                dataRowPersonal && dataRowProf ? 
                (dataRowPersonal.length + dataRowProf.length) :
                0
            }}  
        </h2>
    </div>
    <article class="pt-8">
        <p-accordion class="text-primary "  [activeIndex]="0">
            <p-accordionTab  class="text-primary">
                <ng-template  pTemplate="header">
                    <p  class="opacity-0">
                        Header I
                    </p>
                    <div (click)="changeActive(0)"  class="flex absolute px-8 top-0 left-0 h-full items-center justify-between w-full">
                        <span class="vertical-align-middle">Capacitaciones profesionales</span>
                        <span data-aos-duration="800" [ngClass]="activeIndex == 0 ? 'rotate-0' : 'rotate-180'"  class="visible flex items-center transform transition duration-600 ease-in-out">
                            <svg 
                            class=""
                            xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M23.1187 12.8687C22.777 13.2104 22.223 13.2104 21.8813 12.8687L12 2.98744L2.11872 12.8687C1.77701 13.2104 1.22299 13.2104 0.881283 12.8687C0.539574 12.527 0.539574 11.973 0.881283 11.6313L11.3813 1.13128C11.723 0.789573 12.277 0.789573 12.6187 1.13128L23.1187 11.6313C23.4604 11.973 23.4604 12.527 23.1187 12.8687Z"
                                    fill="#C3363A" />
                            </svg>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="overflow-x-scroll">
                        <TableAtom [loading]="loading" [columns]="columns" [rows]="dataRowProf"/>
                    </div>
                </ng-template>
            </p-accordionTab>
            <p-accordionTab  class="text-primary">
                <ng-template  pTemplate="header">
                    <p  class="opacity-0">
                        Header I
                    </p>
                    <div (click)="changeActive(1)" 
                    
                    class="flex absolute px-8 top-0 left-0 h-full items-center justify-between w-full">
                        <span class="vertical-align-middle">Capacitaciones personales</span>
                        <span data-aos-duration="800"
                        [ngClass]="activeIndex == 1 ? 'rotate-0' : 'rotate-180'"
                        class="visible flex items-center transform transition duration-600 ease-in-out">
                            <svg 
                            class=""
                            xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M23.1187 12.8687C22.777 13.2104 22.223 13.2104 21.8813 12.8687L12 2.98744L2.11872 12.8687C1.77701 13.2104 1.22299 13.2104 0.881283 12.8687C0.539574 12.527 0.539574 11.973 0.881283 11.6313L11.3813 1.13128C11.723 0.789573 12.277 0.789573 12.6187 1.13128L23.1187 11.6313C23.4604 11.973 23.4604 12.527 23.1187 12.8687Z"
                                    fill="#C3363A" />
                            </svg>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="overflow-x-scroll">
                        <TableAtom [loading]="loading" [columns]="columns" [rows]="dataRowPersonal"/>
                    </div>
                </ng-template>
            </p-accordionTab>
        
        
        </p-accordion>
    </article>
</section> 