import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { HomeComponent } from "./home.component";
import { MisCapacitacionesComponent } from "./mis-capacitaciones/mis-capacitaciones.component";
import { ExamenesComponent } from "./examenes/examenes.component";
import { CertificacionesComponent } from "./certificaciones/certificaciones.component";
import { CapacitacionesComponent } from "./capacitaciones/capacitaciones.component";
import { MatriculadosComponent } from "./matriculados/matriculados.component";
import { UserFormComponent } from "src/app/components/user-form/user-form.component";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        children: [
            { path: '', component: CapacitacionesComponent },
            { path: 'matriculados', component: MatriculadosComponent },
            { path: 'mis-capacitaciones', component: MisCapacitacionesComponent },
            { path: 'examenes', component: ExamenesComponent },
            { path: 'certificaciones', component: CertificacionesComponent },
            {path: 'perfil', component: UserFormComponent}
        ]

    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule { }