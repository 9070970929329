import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../appReducer';

export const selectUiState = (state: AppState) => state.ui;


export const selectNavTabs = createSelector(
    selectUiState,
    (state) => state.navTabs
);

export const selectTabs = createSelector(
    selectUiState,
    (state) => state.capacitacionesTabs
) 
export const selectActiveTab = createSelector(
    selectUiState,
    (state) => state.activeCapt
) 

export const selectBreadCrumb = createSelector(
    selectUiState,
    (state) => state.breadCrumb
)

