<p-dialog [closable]="false" [visible]="status.open" [modal]="true" [draggable]="false" [resizable]="false">

    <div (click)="closeDialog()"
        class="text-[14px] text-secondary border-2 border-secondary absolute top-2 right-2 z-10 cursor-pointer rounded-full w-[40px] h-[40px]">
        <div class="w-full flex justify-center h-full items-center">
            X
        </div>
    </div>
    <ng-container *ngIf="status.open">
        <div class="relative"
            *ngIf="status?.file?.endsWith('.pdf') || status?.file?.endsWith('.doc') || status?.file?.endsWith('.docx'); else block">
            <iframe *ngIf="file" [src]="file" width="500px" height="600px" frameborder="0"></iframe>
        </div>
        <ng-template #block>
            <img class="max-w-[1300px] w-full" [src]="status.file" alt="">
        </ng-template>
    </ng-container>
</p-dialog>