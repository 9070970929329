import { HttpParams } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { InscriptionsService } from 'src/app/core/services/inscriptions.service';
import { actionUpdateCapacitaciones } from 'src/app/store/actions/capacitaciones.actions';
import { showConfirmarClase } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { selectInscripcionClase } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html'
})
export class ConfirmacionComponent implements OnInit, OnDestroy {
  open: boolean = false
  loading: boolean = false
  end: boolean = false
  _subOpen!: Subscription
  _capaSub!: Subscription
  _confirmSub!: Subscription
  capacitacion!: CourseI | undefined
  constructor(
    private store: Store<AppState>,
    private inscriptionService: InscriptionsService,
    private messageService: MessageService
  ) { }

  acceptConfirm() {
    const dataToSend = {
      inscription_id: this.capacitacion?.inscription_me.id
    }
    if (this.capacitacion)
      this._confirmSub = this.inscriptionService.confirmInscription(dataToSend)
        .subscribe(
          {
            next: (res: any) => {
              if (this.capacitacion) {
                this.store.dispatch(actionUpdateCapacitaciones({ id: this.capacitacion.id }))
                this.end = true
                setTimeout(() => {
                  this.closeDialog()
                  const htmlElement = document.createElement('div');
                  htmlElement.innerHTML = res.confirm;
                  const messageText = htmlElement.textContent;
                  this.messageService.add(
                    {
                      severity: res.confirm != "" ? "warn" : "success",
                      summary: res.msg,
                      detail: messageText ? messageText : ""
                    }
                  )
                }, 1000)
              }
            },
            error: err => {
              this.messageService.add(
                {
                  severity: 'error',
                  detail: 'Un error ha ocurrido intente nuevamente mas tarde'
                }
              )
            }
          }
        )
  }

  closeDialog() {
    this.loading = false
    this.end = false
    this.store.dispatch(showConfirmarClase({ confirmacionClase: false }))
  }

  ngOnInit(): void {
    this._subOpen = this.store.select(selectInscripcionClase)
      .subscribe(
        res => {
          this.open = res
        }
      )

    this._capaSub = this.store.select(selectCapacitacion)
      .subscribe(
        res => this.capacitacion = res
      )

  }
  ngOnDestroy(): void {
    this._subOpen?.unsubscribe()
    this._capaSub?.unsubscribe()
    this._confirmSub?.unsubscribe()
  }
}
