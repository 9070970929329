import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetTokenService {

  constructor() { }

  getToken(){

    const token = localStorage.getItem('access_token')
    
    if(token){
      return JSON.parse(token)
    }
  }

}
