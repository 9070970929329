import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appReducer';
import { selectPaymentInfo } from 'src/app/store/selectors/dialog.selector';
import { showAdjuntarComprobante, showPaymentInfo } from 'src/app/store/actions/dialog.actions';
import { Observable, Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard'
import { CourseI } from 'src/app/core/models/Courses.interface';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export default class PaymentInfoComponent implements OnInit, OnDestroy {

  visible$!: Observable<any>
  _capacitacion!: Subscription
  capacitacion!: CourseI | undefined
  _user!: Subscription
  user: any

  constructor(
    private store: Store<AppState>,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.visible$ = this.store.select(selectPaymentInfo)
    this._capacitacion = this.store.select(selectCapacitacion)
      .subscribe(
        capa => this.capacitacion = capa
      )
    this._user = this.store.select(selectUserAuth)
      .subscribe(user => this.user = user)
  }
  ngOnDestroy(): void {
    this._capacitacion?.unsubscribe()
    this._user?.unsubscribe()
  }

  visible: boolean = false;

  showDialog() {
    this.store.dispatch(showPaymentInfo({ showPaymentInfo: true }))
  }
  pagarModal() {
    this.closeDialog()
    this.store.dispatch(showAdjuntarComprobante({ showComprobantePago: true }))
  }

  closeDialog() {
    this.store.dispatch(showPaymentInfo({ showPaymentInfo: false }))
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
  }

}
