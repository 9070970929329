<p-dialog [closable]="false" [visible]="status.open" [modal]="true" [draggable]="false" [resizable]="false">
    <div class="absolute top-0 border-2 rounded w-[30px] h-[30px] right-2 top-2 cursor-pointer border-primary text-primary flex items-center justify-center"
        (click)="closeDialog()">X</div>

        <ng-container *ngIf="status.open">
            <div class="relative" *ngIf="status?.file?.endsWith('.pdf'); else block">
                <iframe *ngIf="file" [src]="file" width="500px" height="600px" frameborder="0"></iframe>
            </div>
            <ng-template #block>
                <img [src]="status.file" alt="">
            </ng-template>
        </ng-container>
</p-dialog>