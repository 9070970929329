import { createReducer, on } from "@ngrx/store";
import { isLoading } from "../actions/ui.actions";
import { loadCertificaciones } from "../actions/certificacion.actions";

export interface StateCert {
    isLoading: boolean,
    certificaciones: Array<any>
    certificacionesMatriculado: Array<any>
}

const initialState: StateCert = {
    isLoading: false,
    certificaciones: [
        {
            name: 'Certificacion 1',
            matriculado_name: 'Juan Carlos',
            file: ''
        },
        {
            name: 'Certificacion 2',
            matriculado_name: 'Juan Carlos',
            file: ''
        },
        {
            name: 'Certificacion 3',
            matriculado_name: 'Juan Carlos',
            file: ''
        },
        {
            name: 'Certificacion 4',
            matriculado_name: 'Juan Carlos',
            file: ''
        },
    ],
    certificacionesMatriculado: []
}

export const certificacionesReducer = createReducer(
    initialState,
    on(isLoading, (state, { isLoading }) => ({ ...state, isLoading: true })),
    on(loadCertificaciones, (state, { certificaciones })=>({...state,certificaciones}))
)
