import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, mergeMap, of, tap } from "rxjs";
import { CoursesService } from "src/app/core/services/courses.service";
import { GetTokenService } from "src/app/core/services/get-token.service";
import * as actionCapacitaciones from "../actions/capacitaciones.actions"

import { InscriptionsService } from "src/app/core/services/inscriptions.service";
import { deleteSuccesInscription, inscriptionDelete } from "../actions/inscription.actions";

@Injectable()
export class InscriptionEffect {
    constructor(
        private actions$: Actions,
        private inscriptionService: InscriptionsService,
    ) { }

    deletAction$ = createEffect(
        () => this.actions$.pipe(
            ofType(inscriptionDelete),
            mergeMap((props) =>
                this.inscriptionService.deleteInscription(props).pipe(
                    concatMap(() => [
                        actionCapacitaciones.actionUpdateCapacitaciones({ id: props.idCapacitacion }),
                        deleteSuccesInscription({successBaja:true})
                    ])
                ))
        )
    )
}