<section class="pt-4" data-aos="fade-left" data-aos-duration="800">
    <app-titles-atom title="Datos del matriculado"></app-titles-atom>
    <article >
        <div class="py-2 pointer-events-none">
            <app-input [value]="matriculado?.fullname" label="Nombre y apellido"></app-input>
        </div>

        <div class="py-2 pointer-events-none">
            <app-input [value]="matriculado?.matricula" label="Numero de matrícula"></app-input>
        </div>

        <hr class="opacity-1 border-primary-100 my-6  max-w-[456px] w-full">

        <app-titles-atom title="Datos del usuario"></app-titles-atom>
        
        <div class="py-2 pointer-events-none">
            <app-input [value]="matriculado?.email" label="Email"></app-input>
        </div>

    </article>
</section>