<div class="relative inline-block text-left">
  <div (click)="openMenu()" *ngIf="imgUrl">
    <img class="cursor-pointer opacity-100 transform ease-in-out duration-400 hover:opacity-80" [src]="imgUrl" alt="">
  </div>
  <div *ngIf="title" class="flex gap-2 justify-center uppercase items-center px-4" (click)="openMenu()">
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 15.5C4.13401 15.5 1 12.366 1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.866 1.5 15 4.63401 15 8.5C15 12.366 11.866 15.5 8 15.5ZM8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
        fill="currentColor" />
      <path
        d="M8 4.5C8.27614 4.5 8.5 4.72386 8.5 5V8H11.5C11.7761 8 12 8.22386 12 8.5C12 8.77614 11.7761 9 11.5 9H8.5V12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12V9H4.5C4.22386 9 4 8.77614 4 8.5C4 8.22386 4.22386 8 4.5 8H7.5V5C7.5 4.72386 7.72386 4.5 8 4.5Z"
        fill="currentColor" />
    </svg>
    {{title}}
  </div>


  <div [ngClass]="{'hidden':!open}"
    class="absolute z-20 transition ease duration-600 right-0 border-[2px] border-primary  mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    role="menu" [style.opacity]="open ? 1 : 0" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"
    >
    <div class="py-1 border-primary" role="none">
      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
      <div class="w-full" *ngFor="let option of options, let i = index">

        <a class="text-gray-700 flex items-center cursor-pointer gap-4 w-full block border-b-1 text-light border-b-primary px-4 py-2 transition duration-600 m hover:bg-[#00000010]"
          role="menuitem" tabindex="-1" id="menu-item-{{i}}" (click)="accionGlobal(option.action)">
          <img *ngIf="option.image" [src]="option.image" alt="">
          {{option.title}}
        </a>
        <hr class="border-1 border-primary" *ngIf="i+1 != options?.length">
      </div>

    </div>
  </div>
</div>
<div *ngIf="open" (click)="open=false" class="fixed z-10 w-full h-screen top-0 left-0 bg-transparent">

</div>