<section data-aos="fade-in" data-aos-duration="800" *ngIf="!startLoading; else skeleton">
    <app-titles-atom title="Datos de perfil"></app-titles-atom>
    <div class="flex gap-4 flex-wrap py-6">
        <div class="flex gap-4 items-center text-primary">
            <img [src]="user.profile_picture ? user.profile_picture : 'assets/icons/userpic.svg'" alt=""
                class="w-14 h-14 object-cover">
            <p>foto.jpg</p>
        </div>
        <div class="flex ps-6 gap-4 items-center">
            <input #imagenPerfil type="file" (change)="onFileChange($event)" class="hidden">
            <app-button-form [disabled]="loading" icon="pi-cloud-download" (action)="cargarImagen()" title="Cargar nueva foto" type="file"></app-button-form>
            <app-button-form [disabled]="loading" icon="pi-trash" title="Eliminar" (action)="deleteImage()" type="file"></app-button-form>
        </div>
    </div> 
    <form [formGroup]="form">
        <div class="py-4">
            <app-input label="Nombre" formControlName="name"></app-input>
        </div>
        <div class="py-4">
            <app-input label="Apellido" formControlName="last_name"></app-input>
        </div>
        <hr class="my-4 border border-primary-100 opacity-80 max-w-[1100px]">
        <div class="py-2">
            <app-input label="Email" formControlName="email"></app-input>
        </div>
        <div class="py-2">
            <div class="text-primary" *ngIf="!changePassword">
                <div class="flex gap-4 items-center pb-2">
                    <h2 class="text-md">Contraseña</h2>
                    <span class="text-primary cursor-pointer" (click)="changePassword=true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-pencil" viewBox="0 0 16 16">
                            <path
                                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                    </span>
                </div>
                <p>********</p>
            </div>
            <app-input data-aos="fade-left" *ngIf="changePassword" label="Contraseña" type="password" formControlName="password"></app-input>
        </div>
    </form>

    <div class="flex pt-6 gap-4 flex-wrap">
        <app-button-form [disabled]="loading" title="Guardar" (action)="editUser()"></app-button-form>
    </div>
</section>

<ng-template #skeleton>
    <app-skeleton-profile />
</ng-template>