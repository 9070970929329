<nav class="sm:px-4 pb-4 md:px-16 pt-8 relative flex flex-col items-center w-full"
    [ngClass]="{' border-t-[7px] border-secondary': red}">
    <section class="max-w-[1800px] w-full justify-center ">
        <img 
            class="absolute pointer-events-none w-full h-full object-cover object-bottom transition duration-700 -z-10 top-0 left-0"
            src="assets/image/bg-nav.png" alt="bg-nav">
        <article class="flex justify-between w-full ">
            <img [routerLink]="['/']" src="assets/icons/COPALOGO.svg" class="w-[50%]  md:w-[253px] cursor-pointer"
                alt="" srcset=""> 
            <div class="flex gap-4">
                <app-dropdown imgUrl="assets/icons/config.svg" [options]="dropDownOptionptions"
                    (action)="actionEmmit($event)"></app-dropdown>

                <app-dropdown-notificacion 
                imgUrl="assets/icons/notification.svg"
                [options]="dropDownNotificationOptions"
                (action)="actionEmmit($event)"
                ></app-dropdown-notificacion>
                
                <div class="flex justify-center">
                </div>
            </div>
        </article>

        <div class="px-4 pt-6" *ngIf="breadCrumb$ | async as breadCrumb">
            <app-breadcrumb *ngIf="breadCrumb.length>0" [list]="breadCrumb"></app-breadcrumb>
        </div>
        <div class=" px-4 w-full flex flex-col items-center">

            <!-- Info User Matriculado -->
            <ng-container *ngIf="(user$ | async) as user">
                <section class="w-full" *ngIf=" user.roles[0].name == 'matriculado' && !shouldShowImage()">
                    <div class="w-full pt-8">
                        <app-user-info [user]="user" [imagen]="user.profile_picture" />
                    </div>
                    <article class="w-full flex flex-wrap md:flex-no-wrap gap-4 pt-4">
                        <div class="w-full md:w-[35%] min-w-[300px] pe-16 lg:border-r-2 border-primary-100">
                            <article class="flex w-full flex-col" tooltipPosition="bottom"
                            pTooltip="En el total de cursos acreditados, el puntaje obtenido no podrá exceder el 90% del costo de la matrícula."    >
                                <div class="flex justify-between w-full pt-4" 
                                
                                 >
                                    <div class="flex gap-3 items-center">
                                        <img src="assets/icons/icono_puntos.svg" alt="copa">
                                        <span class="text-xl font-bold text-primary-100 whitespace-nowrap">Puntos
                                            obtenidos</span>
                                    </div>
                                    <span class="font-bold text-3xl text-primary-100 ">
                                        <app-number-incremetion [value]="+user.points_earned" />
                                    </span>
                                </div>
                                <app-progress-bar
                                    [expandedWidth]="+user.points_earned*100/90 > 100 ? 100 : +user.points_earned*100/90" />
                            </article>
                        </div>
                        <div class="grid mt-8 md:mt-0 grid-cols-4 w-full lg:w-[63%] gap-8">
                            <div *ngFor="let info of infoMatriculados"
                                class="col-span-4 sm:col-span-2 md:col-span-1 md:justify-center md:items-center flex">
                                <app-info-nav [img]="info.img" [value]="info.value? info.value : 0"
                                    [title]="info.title" [tooltip]="info.tooltip"/>
                            </div>
                        </div>
                    </article>
                </section>
            </ng-container>
            <!-- End Info User Matriculado -->

            <!-- Acciones Capacitaciones -->
            <div class="w-full flex justify-between gap-4  flex-wrap pt-4" *ngIf="navCapacitacionData">
                <h2 class="text-secondary text-3xl lg:w-[70%] w-full">{{navCapacitacionData?.title}}</h2>
                <div class="actions flex gap-4 items-center justify-center">
                    <ng-container
                        *ngIf="userData && userData.roles &&  userData.roles[0]?.name == 'administrador general'; else matriculado">
                        <!-- Admin -->
                        <div class="flex gap-4">

                            <button
                                (click)="changeStatus()"
                                class="border-2 border-primary flex justify-center items-center gap-4 h-[46px] rounded-[10px] py-1 px-4">
                                <span class="pointer-events-none">
                                    <p-inputSwitch  [ngModel]="capacitacion?.status_id == 1"></p-inputSwitch>
                                </span>
                                <span *ngIf="capacitacion?.status_id == 2" class="uppercase bold ffEuclid  text-primary text-[14px]">
                                    capacitación inactiva
                                </span>
                                <span *ngIf="capacitacion?.status_id == 1" class="uppercase bold ffEuclid  text-primary text-[14px]">
                                    capacitación activa
                                </span>
                            </button>
                            <app-dropdown *ngIf="navCapacitacionData.actions" imgUrl="assets/icons/optionsnav.png"
                                [options]="navCapacitacionData.actions"></app-dropdown>
                        </div>

                    </ng-container>
                    <ng-template #matriculado >
                        <!-- Matriculado -->
                        <a 
                        *ngIf="capacitacion?.inscription_me?.approved == 'Aprobado'" 
                        [href]="urlCertificate+'&&token='+token"
                        target="_blank" 
                         class="border-2 flex items-center justify-center gap-3 rounded-[8px] border-primary px-4 py-2 text-[18px]">
                         <span class="w-3 h-3"> <img src="assets/icons/award.svg" alt=""></span>
                         VER CERTIFICADO
                        </a>
                        <div 
                            class="bg-green flex rounded-[8px] items-center justify-center max-h-[46px] px-4 py-2 text-[16px] uppercase bold ffEuclid text-primary">
                            {{navCapacitacionData?.points ? navCapacitacionData?.points : 0}}
                            PUNTOS
                        </div>
                        <app-dropdown  *ngIf="navCapacitacionData.actions" imgUrl="assets/icons/optionsnav.png"
                            [options]="navCapacitacionData.actions"></app-dropdown>
                    </ng-template>
                </div>
            </div>
            <!-- END Acciones Capacitaciones -->

            <ng-container *ngIf="(tabs$ | async) as navigation">
                <div class="mt-8 flex w-full p-4  md:p-0 md:overflow-hidden overflow-scroll">
                    <ul class="flex  ffEuclid text-primary-100 font-bold" *ngFor="let tab of navigation">
                        <li class="px-4 py-[3px] cursor-pointer" [routerLinkActiveOptions]="{ exact: true }"
                            [routerLink]="tab.path" routerLinkActive="border-b-[3px] border-primary-100">
                            {{tab.name}}
                        </li>

                    </ul>
                </div>
            </ng-container>
        </div>
    </section>


</nav>