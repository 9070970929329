import { createAction, props } from "@ngrx/store";
import { CourseI } from "src/app/core/models/Courses.interface";

export const loadingCourse = createAction('[Capacitaciones] Cargando capacitacion')
export const loadingListActive = createAction(
    '[Capacitaciones] Cargando Lista',
    props<{ token: string | undefined }>()
)

export const capacitacionView = createAction('[Capacitaciones] Capacitación en vista',
    props<{ capacitacion: CourseI | undefined }>());

export const capacitacionViewId = createAction('[Capacitaciones] Obteniendo Capacitacion de API',
    props<{ id: number }>());

export const actionCapacitacionesList = createAction(
    '[Capacitaciones] Lista Capacitaciones Cargada',
    props<{ capacitaciones: CourseI[] }>()
)

export const actionLoadingMisCapacitaciones = createAction(
    '[Capacitaciones] Cargando mis capacitaciones',
    props<{ loading: boolean }>()
)

export const actionMisCapacitacionesList = createAction(
    '[Capacitaciones] Mis Capacitaciones List Cargada',
    props<{ misCapacitaciones: CourseI[] }>()
)

export const actionChangePage = createAction(
    '[Capacitaciones] Lista Cambiada la pagina',
    props<{ page: number }>()
)

export const actionPagination = createAction(
    '[Capacitaciones] Read pagination props',
    props<{ paginator: any }>()
)


export const actionUpdateCapacitaciones = createAction(
    '[Capacitaciones] Actualizando capacitaciones por capacitacion',
    props<{ id: number }>()
)

export const updateListCapacitaciones = createAction(
    '[Capacitaciones] Actualizando la lista de capacitaciones'
)

export const actionNavbarActions = createAction(
    '[Capacitaciones] Acciones de navbar cambiando',
    props<{
        navbarCapa: {
            title: any,
            actions: any,
            points: any
        } | undefined
    }>()
)


export const actionDeleteCapacitacion = createAction(
    '[Capacitaciones] Delete Capacitaciones by Id',
    props<{ id: string | number }>()
)

export const actionLoadingCapacitaciones = createAction(
    '[Capacitaciones] Loading list Capacitaciones',
    props<{ loading: boolean }>()
)

export const paymentLoading = createAction(
    '[Payment] Loading',
    props<{course_id: any}>()
)

export const paymentFile = createAction(
    '[Payment] Info',
    props<{ payment: any }>()
)

export const allPaymentsForCapa = createAction(
    '[Payments] Payments in capa',
    props<{payList:any[]}>()
)

export const paramsCapacitaciones = createAction(
    '[Params Capacitaciones] Cambiando los parametros para la paginación',
    props<{params:any}>()
)