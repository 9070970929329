import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { showAdjuntarComprobante, showCertificacionConfirm, showCertificacionFile, showFirmFile, viewCertificacionFile } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';

import { Subscription } from 'rxjs'
import { selectDialogAdjuntar, selectDialogCertificacionConfirm, selectDialogCertificacionFile, selectDialogCertificacionView, selectDialogFirmFile } from 'src/app/store/selectors/dialog.selector';
import { MessageService } from 'primeng/api';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-certificacion-show',
  templateUrl: './certificacion-show.component.html',
  providers: [MessageService]
})
export class CertificacionShowComponent implements OnInit, OnDestroy {
  open: boolean = false
  end: boolean = false
  loading: boolean = false
  certificacionData: any
  _open!: Subscription
  _subscriptionRest!: Subscription
  _subcriptionCapacitacion!: Subscription
  url: any
  capacitacion!: CourseI | undefined
  @Input() info!: any
  form!: FormGroup
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private service: ApiRestService,
    private messageService: MessageService,
    private sanitizer:DomSanitizer
  ) { }


  uploadedFiles: any[] = [];
  show = true

  onChangeValue(event: any, type: any) {
    switch (type) {
      case "puntos": {
      } break;
    }
  }

  acceptConfirm() {
   
  }

  closeDialog() {
    this.store.dispatch(viewCertificacionFile({ viewCertificacionFile: { state: false, data: null } }))
    this.end = false
    this.loading = false
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      puntos: new FormControl(""),
    });

    this._open = this.store.select(selectDialogCertificacionView)
      .subscribe(
        (res) => {
          this.open = res.state
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(res.data?.file); 
        }
      )
  }

  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._subcriptionCapacitacion?.unsubscribe()
    this._subscriptionRest?.unsubscribe()
  }
}
