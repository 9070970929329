import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InscriptionComponent } from './inscription/inscription.component';
import { AtomsModule } from '../atoms/atoms.module';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { ConfirmBajaComponent } from './confirm-baja/confirm-baja.component';
import { DeleteCapacitacionComponent } from './delete-capacitacion/delete-capacitacion.component';
import { PayFileComponent } from './pay-file/pay-file.component';
import { ViewsDialogComponent } from './views-dialog/views-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FirmFileComponent } from './firm-file/firm-file.component';
import { CertificacionFileComponent } from './certificacion-file/certificacion-file.component';
import { LinkPostComponent } from './link-post/link-post.component';
import { DeleteDialogsComponent } from './delete-dialogs/delete-dialogs.component';
import { CertificacionConfirmComponent } from './certificacion-confirm/certificacion-confirm.component';
import { ExamFileComponent } from './exam-file/exam-file.component';
import { ExamenConfirmComponent } from './examen-confirm/examen-confirm.component';
import { DialogPutFormComponent } from './dialog-put-form/dialog-put-form.component';
import { DialogWithActionsComponent } from './dialog-with-actions/dialog-with-actions.component';
import { ViewExamComponent } from './view-exam/view-exam.component';
import { CertificacionShowComponent } from './certificacion-show/certificacion-show.component';
import { FirmaGlobalComponent } from './firma-global/firma-global.component';



@NgModule({
  declarations: [
    InscriptionComponent,
    ConfirmacionComponent,
    ConfirmBajaComponent,
    DeleteCapacitacionComponent,
    PayFileComponent,
    FirmFileComponent,
    CertificacionFileComponent,
    CertificacionConfirmComponent,
    CertificacionShowComponent,
    FirmaGlobalComponent,
    ViewsDialogComponent,
    LinkPostComponent,
    DeleteDialogsComponent,
    ExamFileComponent,
    ExamenConfirmComponent,
    DialogPutFormComponent,
    DialogWithActionsComponent,
    ViewExamComponent
  ],
  imports: [
    CommonModule, 
    AtomsModule,
    PdfViewerModule,
  ],
  exports: [
    InscriptionComponent,
    ConfirmacionComponent,
    ConfirmBajaComponent,
    DeleteCapacitacionComponent,
    PayFileComponent,
    FirmFileComponent,
    CertificacionFileComponent,
    CertificacionConfirmComponent,
    CertificacionShowComponent,
    FirmaGlobalComponent,
    ExamenConfirmComponent,
    ViewsDialogComponent,
    LinkPostComponent,
    ExamFileComponent,
    DeleteDialogsComponent,
    DialogPutFormComponent,
    DialogWithActionsComponent,
    ViewExamComponent
  ]
})
export class DialogsModule { }
