import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-titles-atom',
  templateUrl: './titles-atom.component.html'
})
export class TitlesAtomComponent {
  @Input() title!:string
  @Input() redLine: boolean = false
}
