import { Component,EventEmitter,Input, Output } from '@angular/core';

@Component({
  selector: 'switch-copa',
  templateUrl: './switch-with-label.component.html'
})
export class SwitchWithLabelComponent {
  @Input() label:string = 'Un label'
  @Input() valor!:boolean 
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onValueChange(newValue: boolean) {
    this.valor = newValue;
    this.valueChange.emit(newValue);
  }
}
