<p-dialog [closable]="false" [visible]="stateDialog.open" [modal]="true" [draggable]="false" [resizable]="false">

    <div *ngIf="stateDialog?.end">
        <p class="text-md text-center pt-4 pb-2" data-aos="fade-in" data-aos-duration="900">{{stateDialog.text?.end}}
        </p>
    </div>
    <ng-container *ngIf="!stateDialog?.end">
        <p class="text-md text-center pt-4 pb-2" data-aos="fade-in" data-aos-duration="900">
            {{stateDialog.text?.question}}</p>
    </ng-container>
    <div class=" flex pt-[30px] justify-center gap-4">
        <app-button-form
            [disabled]="stateDialog.end|| stateDialog.loading"
            [title]="stateDialog?.loading ? 'cargando' : 'aceptar'" (action)="acceptConfirm()"></app-button-form>
        <app-button-form (action)="closeDialog()" [secondary]="true"
            [title]="stateDialog?.end ? 'Cerrar':'Cancelar'"></app-button-form>
    </div>
</p-dialog>