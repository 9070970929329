import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { actionDeleteCapacitacion } from 'src/app/store/actions/capacitaciones.actions';
import { showDeleteCapacitacion } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { selectDialogDelete } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-delete-capacitacion',
  templateUrl: './delete-capacitacion.component.html'
})
export class DeleteCapacitacionComponent implements OnInit, OnDestroy {
  open = false
  _open!: Subscription
  _capacitacion!: Subscription
  end = false
  activeId!: string | number

  constructor
    (
      private store: Store<AppState>,
      private router: Router
    ) { }
  closeDialog() {
    this.end = false
    this.store.dispatch(showDeleteCapacitacion({ showDelete: false }))
  }
  acceptConfirm() {
    this.store.dispatch(actionDeleteCapacitacion({ id: this.activeId }))
    this.router.navigate([''])
    this.closeDialog()
  }

  ngOnInit(): void {
    this._open = this.store.select(selectDialogDelete)
      .subscribe(res => this.open = res)
    this._capacitacion = this.store.select(selectCapacitacion)
      .subscribe(
        res=>{
          if(res) this.activeId = res?.id
        }
    )

  }
  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._capacitacion?.unsubscribe()
  }
}
