import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { InfoNavComponent } from './info-nav/info-nav.component';
import { NumberIncremetionComponent } from '../core/numberIncremetion/numberIncremetion.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { AccordionModule } from 'primeng/accordion';
import { ButtonTabComponent } from './button-tab/button-tab.component';
import { TableComponent } from './table/table.component';
import { TablepipesPipe } from '../core/pipes/tablepipes.pipe';

import { SplitButtonModule } from 'primeng/splitbutton';

import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AlertCOPAComponent } from './alert-copa/alert-copa.component';
import { TitlesAtomComponent } from './titles-atom/titles-atom.component';
import { SkeletonModule } from 'primeng/skeleton';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './input/input.component';
import { ButtonFormComponent } from './button-form/button-form.component';
import { SwitchWithLabelComponent } from './switch-with-label/switch-with-label.component';
import { OrdinalPipe } from '../core/pipes/ordinal.pipe';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RippleModule } from 'primeng/ripple';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownNotificacionComponent } from './dropdown/dropdown.notificacion.component';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [
    OrdinalPipe,

    CardComponent,
    UserInfoComponent,
    ProgressBarComponent,
    NumberIncremetionComponent,
    InfoNavComponent,
    DropdownComponent,
    DropdownNotificacionComponent,
    ButtonTabComponent,
    TableComponent,
    AlertCOPAComponent,
    TitlesAtomComponent,
    InputComponent,
    ButtonFormComponent,
    SwitchWithLabelComponent,
    TablepipesPipe,
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    AccordionModule,
    SplitButtonModule,
    ToastModule,
    InputSwitchModule,
    SkeletonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    RippleModule,
    DialogModule,
    ToastModule,
    PaginatorModule,
    FileUploadModule,
    RadioButtonModule,
    BadgeModule,
    TooltipModule
  ],
  exports: [

    OrdinalPipe,
    CardComponent,
    UserInfoComponent,
    ProgressBarComponent,
    NumberIncremetionComponent,
    InfoNavComponent,
    DropdownComponent,
    DropdownNotificacionComponent,
    ButtonTabComponent,
    TableComponent,
    AlertCOPAComponent,
    TitlesAtomComponent,
    InputComponent,
    ButtonFormComponent,
    SwitchWithLabelComponent,
    BreadcrumbComponent,


    AccordionModule,
    SplitButtonModule,
    ToastModule,
    InputSwitchModule,
    SkeletonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    RippleModule, 
    DialogModule,
    ToastModule,
    PaginatorModule,
    FileUploadModule,
    TooltipModule,
    RadioButtonModule
  ]
})
export class AtomsModule { }
