import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { CoursesService } from "src/app/core/services/courses.service";
import * as capacitacionActions from "../actions/capacitaciones.actions";
import { GetTokenService } from "src/app/core/services/get-token.service";
import { capacitacionesActive, changeView } from "../actions/ui.actions";
import { ApiRestService } from "src/app/core/services/api-rest.service";
import { AppState } from "../appReducer";
import { Store } from "@ngrx/store";
import { selectParamsCapa } from "../selectors/capacitaciones.selector";
import { selectUserAuth } from "../selectors/user.selector";
import { ClassI, CourseI } from "src/app/core/models/Courses.interface";


@Injectable()
export class CapacitacionEffect {
  params: any
  user!: any
  constructor(
    private actions$: Actions,
    private courseService: CoursesService,
    private restServie: ApiRestService,
    private getToken: GetTokenService,
    private store: Store<AppState>
  ) {
    this.store.select(selectParamsCapa)
      .subscribe(res => this.params = res)
    this.store.select(selectUserAuth)
      .subscribe(user => this.user = user)

  }



  paymentLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        capacitacionActions.paymentLoading),
      mergeMap(({ course_id }) =>
        this.restServie.getAPI('payments', { course_id: course_id }).pipe(
          mergeMap((data) =>
            [
              capacitacionActions.paymentFile({ payment: data })
            ]
          )
        )

      )
    ))


  capacitacionViewId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(capacitacionActions.capacitacionViewId),
      mergeMap((action) =>
        this.courseService.getCourseId(action.id).pipe(
          mergeMap((data) =>
            [capacitacionActions.capacitacionView({ capacitacion: data })]
          )
        )
      )
    )
  )

  cargarDatos$ = createEffect(
    () => this.actions$.pipe(
      ofType(capacitacionActions.loadingCourse),
      mergeMap(() =>
        this.courseService.getCourse().pipe(
          mergeMap((data) => [
            capacitacionActions.capacitacionView({ capacitacion: data }),
          ])
        )
      )
    )
  );

  actualizandoList = createEffect(
    () => this.actions$.pipe(
      ofType(capacitacionActions.updateListCapacitaciones),
      mergeMap(() => [
        capacitacionActions.loadingListActive({ token: undefined })
      ])
    )
  )



  cargarCapacitacionId$ = createEffect(
    () => this.actions$.pipe(
      ofType(capacitacionActions.actionUpdateCapacitaciones),
      mergeMap(({ id }) =>
        this.courseService.getCourseId(id).pipe(
          mergeMap((data) => [
            capacitacionActions.capacitacionView({ capacitacion: data }),
            capacitacionActions.loadingListActive({ token: this.getToken.getToken() })
          ])
        )
      )
    ));

  cargarCapacitacionesList$ = createEffect(
    () => this.actions$.pipe(
      ofType(capacitacionActions.loadingListActive),
      mergeMap(({ token }) =>
        this.courseService.getCourses3({ page: 1, per_page: 8 }, token).pipe(
          mergeMap((data) => [
            capacitacionActions.actionCapacitacionesList({ capacitaciones: data.data }),
            capacitacionActions.actionPagination({
              paginator:
              {
                last_page: data.last_page,
                total: data.total
              }
            })
          ])
        )
      )
    )
  )
 
  deleteCapacitacionId$ = createEffect(
    () => this.actions$.pipe(
      ofType(capacitacionActions.actionDeleteCapacitacion),
      mergeMap(({ id }) =>
        this.courseService.deleteCourse(id).pipe(
          mergeMap((data) => [
            capacitacionActions.updateListCapacitaciones()
          ])
        )
      )
    )
  )

  private _changeCapacitacionesWithProps = createEffect(() => this.actions$.pipe(
    ofType(capacitacionesActive),
    mergeMap(({ activeCapt }) => {
      capacitacionActions.actionLoadingCapacitaciones({ loading: true });

      const props = this.buildProps(activeCapt);
      this.store.dispatch(capacitacionActions.paramsCapacitaciones({ params: props }))

      if (props) {
        return this.courseService.getCourses3(props).pipe(
          mergeMap(({ data, last_page, total }) => [
            capacitacionActions.actionPagination({
              paginator:
              {
                last_page: last_page,
                total: total
              }
            }),
            capacitacionActions.actionCapacitacionesList({ capacitaciones: data }),
            capacitacionActions.actionMisCapacitacionesList({ misCapacitaciones: data }),
            capacitacionActions.actionLoadingCapacitaciones({ loading: false })
          ]),
          catchError(error => {
            // Handle the error here
            console.error('Error:', error);
            this.store.dispatch(capacitacionActions.actionLoadingCapacitaciones({ loading: false }))
            // Optionally, you can re-throw the error to propagate it further
            throw error;
          })
        );
      } else {
        return of(capacitacionActions.actionLoadingCapacitaciones({ loading: false }));
      }
    })
  ));

  private buildProps(activeCapt: string): any {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    if (activeCapt === "Profesionales" || activeCapt === "Personales") {
      return {
        page: 1,
        per_page: 8,
        sort: 'classes.date',
        start_date: formattedDate,
        type: activeCapt === "Profesionales" ? "Profesional" : "Personal"
      };
    }else if(activeCapt ==="Anteriores"){
      return{
        page:1,
        per_page:8,
        sort: 'classes.date',
        end_date:formattedDate,
        free:1
      }
    } else if (activeCapt === "A realizar" || activeCapt === "Finalizadas") {
      return activeCapt === "A realizar" ?
        {
          page: 1,
          per_page: 8,
          enrolled: 1,
          status: [1],
          sort: 'classes.date',
          start_date: formattedDate
        }
        : this.user.roles[0].id == 1 ?
          {
            page: 1,
            sort: 'classes.date',
            per_page: 8,
            end_date: formattedDate
          } :
          {
            page: 1,
            sort: 'classes.date',
            per_page: 8,
            enrolled: 1,
            end_date: formattedDate
          }
        ;
    } else {
      return activeCapt === "Inactivas" ?
        { page: 1, per_page: 8, sort: 'classes.date', status: [2] }
        :
        { page: 1, sort: 'classes.date', start_date: formattedDate, status: [1], per_page: 8 };
    }
  }


  changePageCapacitacionesList$ = createEffect(
    () => this.actions$.pipe(
      ofType(capacitacionActions.actionChangePage),
      tap(() => 
        capacitacionActions.actionLoadingCapacitaciones({ loading: true },
        ),
       
      ),
      mergeMap(({ page }) =>
        this.courseService.getCourses3({ ...this.params, page: page, per_page: 8 }).pipe(
          mergeMap(({ data }) => [
            capacitacionActions.actionCapacitacionesList({ capacitaciones: data }),
            capacitacionActions.actionMisCapacitacionesList({ misCapacitaciones: data }),
            capacitacionActions.actionLoadingCapacitaciones({ loading: false })
          ]),
          catchError(error => {
            // Handle the error here
            console.error('Error:', error);
            this.store.dispatch(capacitacionActions.actionLoadingCapacitaciones({ loading: false }))
            // Optionally, you can re-throw the error to propagate it further
            throw error;
          })
        )


      )
    )
  )
}