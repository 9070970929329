import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { AppState } from 'src/app/store/appReducer';
import { selectIsLogged } from 'src/app/store/selectors/user.selector';

export const authGuardGuard: CanActivateFn = (route, state) => {
  const store = inject(Store<AppState>)
  const router = inject(Router)

  return store.select(selectIsLogged).pipe(
    tap((isLogged)=>{
      if(!isLogged){
        router.navigate(['login'])
      }
    })
  )

  
};
