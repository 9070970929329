<div *ngIf="visible$ | async as visible">
    <p-dialog header="Info de pago" [closable]="false" [visible]="visible" [modal]="true" [draggable]="false" [resizable]="false" >
        <div class="py-6 px-10 flex flex-col justify-center items-center">
            <button (click)="closeDialog()" title="X" class="absolute w-[50px] h-[50px] right-[20px] top-[30px] rounded-[2px] text-bolder justify-center ffEuclid flex items-center uppercase text-primary text-md font-600 border-primary border-2">X</button>
            <div class="m-0 pr-[20px]" [innerHTML]="capacitacion?.payment?.details">
                    
            </div>
            <div class=" flex pt-[30px] " *ngIf ="user.roles[0].id == 2">
                <app-button-tab title="Pagar" (action)="pagarModal()"></app-button-tab>
            </div>
        </div>
    </p-dialog>
</div>