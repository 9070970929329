<section data-aos="fade-left" data-aos-duration="800">
    <ng-container *ngIf="(buttonTabs$ | async) as buttonTabs">

        <div class="flex flex-wrap sm:flex-no-wrap gap-5 pt-8">
            <app-button-tab *ngFor="let tab of buttonTabs" [title]="tab.label" [value]="tab.value" [active]="tab.value == (tabActive$ | async)"
                (action)="changeActive($event)" />
        </div>
    </ng-container>
    <main *ngIf="!loading">
        <article *ngIf="(tabActive$ | async) == 'A realizar'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of misCapacitaciones">
                <app-card [title]="capacitacion.title" [date]="capacitacion.classes[0]?.date"
                    [grabacion]="capacitacion?.grabacion" [time]="capacitacion.classes[0]?.time"
                    [inscriptos]="capacitacion.inscriptions_count" [cuposDisponibles]="cuposDisponibles(capacitacion)"
                    [rendirDistancia]="capacitacion?.point?.exam == 1" [puntos]='capacitacion?.point?.grant'
                    [showInscriptos]="false" [img]="capacitacion.images[0]?.url" [type]="capacitacion.type"
                    [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
        </article>
        <article *ngIf="(tabActive$ | async) == 'Finalizadas'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of misCapacitaciones">
                <app-card [title]="capacitacion.title" [date]="capacitacion.classes[0]?.date"
                    [grabacion]="capacitacion?.grabacion" [status]="capacitacion.inscription_me?.approved"
                    [time]="capacitacion.classes[0]?.time" [inscriptos]="capacitacion.inscriptions_count"
                    [cuposDisponibles]="cuposDisponibles(capacitacion)"
                    [rendirDistancia]="capacitacion?.point?.exam == 1" [puntos]='capacitacion?.point?.grant'
                    [showInscriptos]="false" [img]="capacitacion.images[0]?.url" [type]="capacitacion.type"
                    [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
        </article>
        <div class="mt-8 text-xl" *ngIf="misCapacitaciones?.length==0">
            <app-alert-copa [text]="'Todavía no hay cursos en esta sección.'   " image="assets/icons/alert.svg" />
        </div>
    </main>
    <div class="card flex justify-end" *ngIf="this.rows?.last_page > 1">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="8" [totalRecords]="rows.total"
            [pageLinkSize]="rows?.last_page <= 5 ? rows.last_page : 5"></p-paginator>
    </div>
    <app-cards-skeleton *ngIf="loading"></app-cards-skeleton>
</section>