import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { DeleteDialogI, DialogWithActionsI, dialogPutAction, dialogWithACtions } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectDialogActions, selectPutDialog } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-dialog-with-actions',
  templateUrl: './dialog-with-actions.component.html'
})
export class DialogWithActionsComponent {
  stateDialog: DialogWithActionsI = {
    open: false,
    loading: false,
    end: false
  }
  _restApi!: Subscription
  name = new FormControl()
  constructor(
    private store: Store<AppState>,
    private restAPI: ApiRestService,
    private messageService: MessageService
  ) { }
  acceptConfirm() {
    this.stateDialog.action()
  }

  closeDialog() {
    this.store.dispatch(dialogWithACtions({ dialogActions: { open: false, end: false, loading: false } }))
  }

  ngOnInit(): void {
    this.store.select(selectDialogActions).subscribe(
      res => {
        this.stateDialog = res
        }
    )
  }
  ngOnDestroy(): void {

  }
}
