import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { showAdjuntarComprobante, showCertificacionConfirm, showCertificacionFile, showFirmFile } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';

import { Subscription } from 'rxjs'
import { selectDialogAdjuntar, selectDialogCertificacionConfirm, selectDialogCertificacionFile, selectDialogFirmFile } from 'src/app/store/selectors/dialog.selector';
import { MessageService } from 'primeng/api';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-certificacion-confirm',
  templateUrl: './certificacion-confirm.component.html',
  providers: [MessageService]
})
export class CertificacionConfirmComponent implements OnInit, OnDestroy {
  open: boolean = false
  end: boolean = false
  loading: boolean = false
  certificacionData: any
  _open!: Subscription
  _subscriptionRest!: Subscription
  _subcriptionCapacitacion!: Subscription
  data: any
  action: any
  capacitacion!: CourseI | undefined
  @Input() info!: any
  form!: FormGroup
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private service: ApiRestService,
    private messageService: MessageService,
  ) { }


  uploadedFiles: any[] = [];
  show = true

  onChangeValue(event: any, type: any) {
    switch (type) {
      case "puntos": {
        this.form.controls['puntos'].patchValue(event)
      } break;
      case "motivo": {
        this.form.controls['motivo'].patchValue(event)
      } break;
    }
  }

  acceptConfirm() {

    if (this.form.value.puntos == "") {
      return this.messageService.add(
        {
          severity: 'error',
          summary: 'Un error ha ocurrido',
          detail: "Debe ingresar una cantidad de puntos mayor o igual a 0"
        }
      );
    }


    let setForm = {
      _method: "PUT",
      point: this.form.value.puntos,
      approved: 1
    }

    this.service.putApi("certifications/" + this.data.id, setForm, null).subscribe(
      {
        next: res => {

          this.end = true
          this.loading = false
          setTimeout(() => {
            this.action()
            this.store.dispatch(showCertificacionConfirm({ showCertificacionConfirm: { state: false, data: null, action: undefined } }))
            this.end = false
          }, 900)
        },
        error: (err) => {
          //#Error
          this.action()
          if (typeof err.error.msg !== "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              this.messageService.add(
                {
                  severity: 'error',
                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg[key]
                }
              );
            }
            )
          }

          if (typeof err.error.msg == "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              return this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg
                }
              );
            })
          }

          this.loading = false
        }
      }
    )
  }


  closeDialog() {
    this.end = false
    this.loading = false
    this.form.controls['puntos'].patchValue(0)
    this.form.controls['motivo'].patchValue("")
    this.store.dispatch(showCertificacionConfirm({ showCertificacionConfirm: { state: false, data: null } }))
  }

  disapproved() {
    let setForm = {
      _method: "PUT",
      point: 0,
      approved: 0,
      motivo: this.form.value.motivo,
    }

    this.service.putApi("certifications/" + this.data.id, setForm, null).subscribe(res => {

      this.messageService.add(
        {
          severity: 'success',
          summary: 'Éxito',
          detail: res.data.msg
        }
      );

      this.end = false
      this.loading = false
      this.form.controls['puntos'].patchValue(0)
      this.form.controls['motivo'].patchValue("")
      this.action()
      this.store.dispatch(showCertificacionConfirm({ showCertificacionConfirm: { state: false, data: null } }))
    }, (err) => {
      //#Error
      if (typeof err.error.msg !== "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg[key]
            }
          );
        })
      }

      if (typeof err.error.msg == "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          return this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg
            }
          );
        })
      }

      this.loading = false
    }
    )
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      puntos: new FormControl(""),
      motivo: new FormControl(""),
    });

    this._open = this.store.select(selectDialogCertificacionConfirm)
      .subscribe(
        (res) => {
          this.open = res.state
          this.data = res.data
          this.action = res.action
        }
      )
  }

  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._subcriptionCapacitacion?.unsubscribe()
    this._subscriptionRest?.unsubscribe()
  }
}
