import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../appReducer';

export const selectDialogState = (state: AppState) => state.dialogos;

export const selectPaymentInfo = createSelector(
    selectDialogState,
    (state) => state.showPaymentInfo
)

export const selectDialogClase = createSelector(
    selectDialogState,
    (state) => state.inscripcionClase
)

export const selectDialogClases = createSelector(
    selectDialogState,
    (state) => state.classes
)

export const selectInscripcionClase = createSelector(
    selectDialogState,
    (state) => state.confirmacionClase
)
export const selectBajaDialog = createSelector(
    selectDialogState,
    (state) => state.confirmBaja
)
export const selectLoadingDialog = createSelector(
    selectDialogState,
    (state) => state.isLoading
)

export const selectSuccessBaja = createSelector(
    selectDialogState,
    (state) => state.successBaja
)

export const selectDialogAdjuntar = createSelector(
    selectDialogState,
    (state) => state.showComprobantePago
)

export const selectDialogFirmFile = createSelector(
    selectDialogState,
    (state) => state.showFirmFile
)

export const selectDialogCertificacionFile = createSelector(
    selectDialogState,
    (state) => state.showCertificacionFile
)

export const selectDialogExamenFile = createSelector(
    selectDialogState,
    (state) => state.showExamenFile
)

export const selectDialogCertificacionConfirm = createSelector(
    selectDialogState,
    (state) => state.showCertificacionConfirm
)

export const selectDialogCertificacionView = createSelector(
    selectDialogState,
    (state) => state.viewCertificacionFile
)

export const selectDialogFirmaFile = createSelector(
    selectDialogState,
    (state) => state.viewCertificacionFile
)


export const selectDialogFirmaGloabalFile = createSelector(
    selectDialogState,
    (state) => state.firmaGlobalFile
)


export const selectDialogExamenConfirm = createSelector(
    selectDialogState,
    (state) => state.showExamenConfirm
)

export const selectDialogDelete = createSelector(
    selectDialogState,
    (state) => state.showDelete
)

export const selectDialogDeleteForm = createSelector(
    selectDialogState,
    (state) => state.deleteDialog
)

export const selectDialogFile = createSelector(
    selectDialogState,
    (state) => state.showFile
)

export const selectDialogLinksPost = createSelector(
    selectDialogState,
    (state) => state.postCapacitacion
)

export const selectDialogExamen = createSelector(
    selectDialogState,
    (state) => state.dialogExamen
)

export const selectPutDialog = createSelector(
    selectDialogState,
    (state) => state.putDialog
)

export const selectDialogActions = createSelector(
    selectDialogState,
    (state) => state.dialogActions
)
export const selectDialogRecovery = createSelector(
    selectDialogState,
    (state) => state.dialogRecovery
)

