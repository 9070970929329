import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-tab',
  templateUrl: './button-tab.component.html'
})
export class ButtonTabComponent {
  @Input() active!:boolean
  @Input() title!:string
  @Input() value!:string
  @Output() action: EventEmitter<string> = new EventEmitter
  @Input() disabled:boolean = false

  buttonAction(){
    this.action.emit(this.value)
  }
}
