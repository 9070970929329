import { createAction, props } from "@ngrx/store";
import { ClassI } from "src/app/core/models/Courses.interface";

export const showPaymentInfo = createAction('[UI Loading] Show Payment Info',
    props<{ showPaymentInfo: boolean }>()
);

export const showInscripcionClases = createAction('[Dialogs] Control Show Dialog Inscripcion',
    props<{ inscripcionClase: boolean }>()
)

export const actionLoadClases = createAction('[Dialogs] Load Classes',
    props<{ classes: ClassI[] }>()
)

export const showConfirmarClase = createAction('[Dialogs] Control Show Dialog Confirmacion',
    props<{ confirmacionClase: boolean }>()
)

export const showConfirmBaja = createAction(
    '[Dialog] Open Confirm Baja',
    props<{ confirmBaja: boolean }>()
)

export const isLoadingDialog = createAction(
    '[Dialog] Loading',
    props<{ isLoading: boolean }>()
)

export const showAdjuntarComprobante = createAction(
    '[Dialog] Adjuntar Comprobante Show',
    props<{ showComprobantePago: boolean }>()
)

export const showFirmFile = createAction(
    '[Dialog] Adjuntar Firma Show',
    props<{ showFirmFile: {
        open:boolean,
        action:any | undefined
    } }>()
)

export const showCertificacionFile = createAction(
    '[Dialog] Adjuntar Certificacion Show',
    props<{ showCertificacionFile: any }>()
)

export const viewCertificacionFile = createAction(
    '[Dialog] Adjuntar Certificacion Show',
    props<{ viewCertificacionFile: any }>()
)

export const showCertificacionConfirm = createAction(
    '[Dialog] Adjuntar Certificacion Confirm',
    props<{ showCertificacionConfirm: any }>()
)

export const firmaGlobalFile = createAction(
    '[Dialog] Adjuntar Firma Global',
    props<{ firmaGlobalFile: boolean }>()
)

export const showExamenConfirm = createAction(
    '[Dialog] Adjuntar Examen Confirm',
    props<{ showExamenConfirm: any }>()
)

export const showDeleteCapacitacion = createAction(
    '[Dialog] Mostrando Dialogo Eliminar Capacitacion',
    props<{ showDelete: boolean }>()
)

export const showDialogFile = createAction(
    '[Dialog] Mostrando File Dialog',
    props<{ showFile: { open: boolean, file: string | undefined } }>()
)

export const showExamenDialogFile = createAction(
    '[Dialog] Mostrando Examen File Dialog',
    props<{ showExamenFile: { open: boolean, file: string | undefined } }>()
)


export const dialogDeleteAction = createAction(
    '[Dialog] Abriendo dialogo eliminar',
    props<{ deleteDialog: DeleteDialogI }>()
)

export interface DeleteDialogI {
    open: boolean,
    end?:boolean,
    loading?:boolean,
    url?: string,
    text?:{
        question:string,
        end:string
    }
    params?: any,
    action?: any
}

export interface DialogWithActionsI{
    open: boolean,
    end:boolean,
    loading:boolean,
    text?:{
        question:string,
        end:string
    }
    action?: any
}

export const dialogLinkPostCapacitacion = createAction(
    '[Dialog] Open post capacitación Dialog',
    props<{ postCapacitacion: boolean }>()
)

export const dialogExamenes = createAction(
    '[Dialog] Open dialogo de examenes',
    props<{ dialogExamen: boolean }>()
)


export const dialogPutAction = createAction(
    '[Dialog] Con acción de cambiar nombre',
    props<{putDialog:DeleteDialogI}>()
)


export const dialogWithACtions = createAction(
    '[Dialog] Con cualquier tipo de accion de aceptar denegar',
    props<{dialogActions:DialogWithActionsI}>()
)

export const dialogRecovery = createAction(
    '[Dialog] Dialog recovery',
    props<{dialogRecovery:DialogWithActionsI}>()

)