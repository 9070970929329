<article class="w-full flex-col flex items-center">
  <div class="py-2 text-md text-primary w-full" *ngIf="label">{{label}} {{control?.hasError('required') ? '*' : '*'}}</div>
  <div class="flex  items-center relative w-full ">
      <img *ngIf="icon" class="w-[16px] h-[16px] absolute left-[20px]" [src]="icon" alt="">
      
      <input [type]="type" [placeholder]="placeholder" class="form-input"
    [style.background]="background"
    ngDefaultControl
    [ngClass]="{'ps-[50px]' : icon, 'ps-[20px]': !icon}"
    class="rounded-[8px] text-grey max-w-[456px] pe-[15px] py-2 outline-none border text-[16px] font-light w-full border border-secondary "
    [value]="value" 
    [formControl]="control">
  

  
  </div>
  
  <div *ngIf="control?.invalid && control?.touched" class="text-secondary text-[16px]">
      <div *ngIf="control?.hasError('required')">Campo requerido</div>
      <div *ngIf="control?.hasError('email')">El campo tiene que ser un email</div>
      <div *ngIf="control?.hasError('minlength')">Minimum length is 3 characters.</div>
  </div>
</article>