<p-dialog [closable]="false" [visible]="stateDialog.open" [modal]="true" [draggable]="false" [resizable]="false">
    <div class="flex justify-center">
        <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="99" height="99" fill="white" />
            <path
                d="M89.6468 16.0587H67.6858V10.1562C67.6858 4.55593 63.1297 0 57.5296 0H41.4709C35.8708 0 31.3147 4.55593 31.3147 10.1562V16.0585H9.35352C8.17886 16.0585 7.22656 17.0108 7.22656 18.1855V34.2441C7.22656 35.4188 8.17886 36.3711 9.35352 36.3711H15.2558V88.8436C15.2558 94.4439 19.8119 98.9998 25.4122 98.9998H73.5881C79.1882 98.9998 83.7443 94.4439 83.7443 88.8436V36.3713H89.6466C90.8212 36.3713 91.7735 35.419 91.7735 34.2443V18.1856C91.7737 17.011 90.8214 16.0587 89.6468 16.0587ZM35.5684 10.1562C35.5684 6.90158 38.2163 4.25391 41.4707 4.25391H57.5294C60.784 4.25391 63.4317 6.90158 63.4317 10.1562V16.0585H35.5684V10.1562ZM79.4906 88.8438C79.4906 92.0984 76.8427 94.7461 73.5883 94.7461H25.4122C22.1576 94.7461 19.5097 92.0984 19.5097 88.8438V36.3713H79.4906V88.8438ZM87.5198 32.1174H11.4805V20.3126H87.5198V32.1174Z"
                fill="#C3363A" />
            <path
                d="M33.4414 82.9413C32.2667 82.9413 31.3145 81.989 31.3145 80.8144V50.3029C31.3145 49.1282 32.2667 48.1759 33.4414 48.1759C34.6161 48.1759 35.5684 49.1282 35.5684 50.3029V80.8144C35.5684 81.9892 34.6163 82.9413 33.4414 82.9413Z"
                fill="#C3363A" />
            <path
                d="M49.5 82.9413C48.3253 82.9413 47.373 81.989 47.373 80.8144V50.3029C47.373 49.1282 48.3253 48.1759 49.5 48.1759C50.6747 48.1759 51.627 49.1282 51.627 50.3029V80.8144C51.627 81.9892 50.6747 82.9413 49.5 82.9413Z"
                fill="#C3363A" />
            <path
                d="M65.5586 82.9413C64.3839 82.9413 63.4316 81.989 63.4316 80.8144V50.3029C63.4316 49.1282 64.3839 48.1759 65.5586 48.1759C66.7333 48.1759 67.6855 49.1282 67.6855 50.3029V80.8144C67.6855 81.9892 66.7333 82.9413 65.5586 82.9413Z"
                fill="#C3363A" />
        </svg>

    </div>
    <div *ngIf="end">
        <p class="text-md text-center pt-4 pb-2" data-aos="fade-in" data-aos-duration="900">{{stateDialog.text?.end}}</p>
    </div>
    <ng-container *ngIf="!end">
        <p class="text-md text-center pt-4 pb-2" data-aos="fade-in" data-aos-duration="900" [innerHTML]="stateDialog.text?.question"></p>
    </ng-container>
    <div class=" flex pt-[30px] justify-center gap-4">
        <app-button-form [disabled]="end || loading" [title]="loading ? 'cargando' : 'aceptar'" (action)="acceptConfirm()"></app-button-form>
        <app-button-form 
        (action)="closeDialog()" 
        [secondary]="true"
        [title]="end ? 'Cerrar':'Cancelar'"></app-button-form>
    </div>
</p-dialog>