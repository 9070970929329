<div class="pt-4">
    <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
    <div class="flex mb-3 gap-4 p-4">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
        <div class="flex items-center">
            <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
           
        </div>
        <div class="flex justify-content-between mt-3 gap-4">
            <p-skeleton width="6rem" height="2rem"></p-skeleton>
            <p-skeleton width="6rem" height="2rem"></p-skeleton>
        </div>
    </div>
    <div class="py-4 ">
        <p-skeleton width="6rem" height="2rem"></p-skeleton>
    </div>
    <div class="w-full md:w-[50%]">
        <p-skeleton height=".5rem"></p-skeleton>
    </div>
    <div class="flex py-4 flex-col justify-content-between mt-3 gap-4">
        <p-skeleton width="10rem" height="2rem"></p-skeleton>
        <p-skeleton width="10rem" height="2rem"></p-skeleton>
        <p-skeleton width="5rem" height="2rem"></p-skeleton>
    </div>
</div>
