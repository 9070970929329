import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetTokenService } from './get-token.service';
import { environment } from 'src/environments/environment';
import { AttendanceI } from '../models/Attendance.interface';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private http: HttpClient,
    private GetTokenS: GetTokenService) { }

  token !: any

  getAttendance(id: number) {
    this.token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });
    const params = new HttpParams()
      .set('course_id', id.toString());
    return this.http.get<{ data: AttendanceI }>(environment.apiUrl + 'attendance', { headers, params }); // Adjust the path as needed
  }

  postAttendance(id: any, student_id: any, class_id: any) {
    this.token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });
    const params = new HttpParams()
      .set('inscriptiondetail_id', id.toString())
      .set('student_id', student_id.toString())
      .set('class_id', class_id.toString());
    return this.http.post<{ data: AttendanceI }>(environment.apiUrl + 'attendance', { inscriptiondetail_id: id, student_id: student_id, class_id: class_id }, { headers }); // Adjust the path as needed
  }
}

