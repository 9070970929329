import { createReducer, on } from "@ngrx/store";
import * as attendanceActions from '../actions/attendance.actions';
import {AttendanceI, Classes} from '../../core/models/Attendance.interface';

export interface StateAttendance {
    course?:{data:AttendanceI}
    class?:Classes[]
    loading:boolean
}

const initialState:StateAttendance = {
    loading:false
}

export const attendanceReducer = createReducer(
    initialState,
    on(attendanceActions.loadedClasses,
        (state, {course}) => ({...state, course, loading:false})),
    on(attendanceActions.presenteAction,
        (state, {id}) => ({...state, id})),
    on(attendanceActions.ausenteAction,
        (state, {id}) => ({...state, id})),
    on(attendanceActions.loadingAttendance,
        (state) => ({...state, loading:true})),
    on(attendanceActions.limpiarAttendance, 
        state => ({...state,course: undefined})),
    on(attendanceActions.getClasses,
        (state)=>({...state, loading:true}))
)
