import { createReducer, on } from "@ngrx/store";
import { loadedExamanesPending, loadedExamenes } from "../actions/examenes.actions";

export interface StateExamenes {
    loadingTable: boolean
    dataTable?: Array<any>
    examenesPending?:any
}

const initialState: StateExamenes = {
    loadingTable: true,
    dataTable: []
}

export const examenesReducer = createReducer(
    initialState,
    on(loadedExamenes, (state, { dataTable }) => ({ ...state, dataTable, loadingTable: false })),
    on(loadedExamanesPending, (state, {examenesPending}) => ({...state, examenesPending}))
) 