import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-form',
  templateUrl: './button-form.component.html'
})
export class ButtonFormComponent {
  @Input() title!:string
  @Output() action: EventEmitter<string> = new EventEmitter
  @Input() disabled:boolean = false
  @Input() secondary:boolean = false
  @Input() icon!:string
  @Input() type:string = 'button'
  @Input() onLoading ?:boolean = false


  buttonAction(){
    this.action.emit(this.title)
  }
}
