import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownI, DropdownN } from 'src/app/core/models/Dropdown.interface';
import { AppState } from 'src/app/store/appReducer';


@Component({
  selector: 'app-dropdown-notificacion',
  templateUrl: './dropdown.notificacion.component.html',
  styleUrls: ['./dropdown.notificacion.component.scss']
})

export class DropdownNotificacionComponent implements OnChanges{
  public open =false

  constructor( private store: Store<AppState>) { 
    
  }

  @Input() options?:Array<DropdownN>
  optionsNotRead?:DropdownN[]
  @Input() imgUrl?:string

  @Output() action: EventEmitter<any> = new EventEmitter

  accionGlobal(action:any){
    this.action.emit(action)
    action()
    this.openMenu()
  }


  ngOnChanges(changes: SimpleChanges): void {
      if(changes['options'] && this.options){
        this.optionsNotRead = this.options.filter((value)=>
          !value.read
        )
      }
  }
  openMenu(){
    this.open = !this.open

  }
}


