<div class="flex gap-4 items-center">
    <img class="w-12 h-12 rounded-full object-fit" 
    [ngClass]="imagen ? 'border-4 border-secondary' : ''" 
    [src]="imagen ? imagen :'assets/icons/userpic.svg'" alt="userPic">

    <h2 *ngIf="!titulo" class="text-secondary text-3xl">{{user.name}} {{user.last_name}}</h2>
    <div *ngIf="titulo" class="flex flex-col">
        <h2 class="text-xl text-secondary ">{{user.name}}  {{user.last_name}}</h2>
        <h2 class="text-sm text-primary-100 font-[200]">{{user.professional}} </h2>
    </div>
    <img *ngIf="!titulo" class="w-6 h-6 cursor-pointer" [routerLink]="['/perfil']" src="assets/icons/edit-user.png" alt="edit">
</div>