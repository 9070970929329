<p-dialog
  [closable]="false"
  [visible]="stateDialog.open"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div *ngIf="end">
    <p
      class="text-md text-center pt-4 pb-2"
      data-aos="fade-in"
      data-aos-duration="900"
    >
      {{ stateDialog.text?.end }}
    </p>
  </div>
  <ng-container *ngIf="!end">
    <p
      class="text-md text-center pt-4 pb-2"
      data-aos="fade-in"
      data-aos-duration="900"
    >
      {{ stateDialog.text?.question }}
    </p>
  </ng-container>
  <div class="">
    <p class="text-md text-start pt-4 pb-2">Nombre</p>
    <app-input [control]="name" placeholder="Nombre"></app-input>
  </div>
  <div class="">
    <p class="text-md text-start pt-4 pb-2">Rol</p>
    <app-input [control]="rol" placeholder="Rol"></app-input>
  </div>
  <div class="flex pt-[30px] justify-center gap-4">
    <app-button-form
      [disabled]="end || loading"
      [title]="loading ? 'cargando' : 'aceptar'"
      (action)="acceptConfirm()"
    ></app-button-form>
    <app-button-form
      (action)="closeDialog()"
      [secondary]="true"
      [title]="end ? 'Cerrar' : 'Cancelar'"
    ></app-button-form>
  </div>
</p-dialog>
