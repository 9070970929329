<iframe 
*ngIf="embedUrl" 
[src]="embedUrl" 
width="100%" 
height="500"
frameborder="0" 
style="border:0" 
allowfullscreen></iframe>
<div class="flex">
    <h2>Dirección:</h2>
    <a *ngIf="adress" target="_blank" href="{{mapsUrl}}">{{adress}}</a>
</div>
