<table class="w-full min-w-[1200px] text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-base font-normal text-white">
        <tr>
            <th *ngFor="let column of columns;let i = index" class="p-0">
                <div class='bg-primary-100 text-[18px] font-medium h-[42px] table-col flex justify-start pl-5 items-center gap-3 mb-3'
                    [ngClass]="{
                    'rounded-l-[8px]' : i == 0,
                    'rounded-r-[8px]' : i === columns.length - 1,
                    'justify-center': ( column.type =='action') || ( column.type =='status')
                }">
                    <span class="text-white"> {{column.label}} </span>
                    
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <p class="p-2" *ngIf="!rows && !loading">No hay datos</p>
        <ng-container class="p-4" *ngIf="loading">
            <tr *ngFor="let _ of [].constructor(20)">
                <td *ngFor="let column of columns;let i = index">
                    <div class="px-2 py-2">
                        <p-skeleton></p-skeleton>
                    </div>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="!loading">
            <tr  *ngFor="let row of rows">
                <td class="p-0" *ngFor="let column of columns, let i = index">
                    <div class='bg-white text-[18px] font-medium h-[42px] table-col flex justify-start pl-5 items-center mb-3'
                        [ngClass]="{
                        'rounded-l-[8px]' : i == 0,
                        'rounded-r-[8px]' : i === columns.length - 1,
                        'justify-center':( column.type =='action') || ( column.type =='status')
                    }">
                        <div *ngIf="column.type != 'action' && column.type != 'status' ">
    
                            <span *ngIf="column.type == 'money'" class="pe-[2px]">
                                $
                            </span>
                            <span>
                                {{row[column.key] | tablepipes : column.type}} 
                            </span>
                        </div>
                        
                        <div *ngIf="column.type == 'action' " class="flex justify-center text-primary items-center gap-6">
                            <span *ngFor="let item of column?.action, let index"  (click)="item.action($event, row)"
                                [ngClass]="item.class" class="max-h-[20px] flex justify-center items-center  max-w-[20px] cursor-pointer ">
                                <img [src]="item.svg" alt="" class="w-[20px] h-[20px]">
                            </span>
                        </div>
    
                        <div *ngIf="column.type == 'status'">
                            <span class="text-white uppercase font-bold py-1 px-2 rounded" 
                            [ngClass]="{'bg-primary-100': row[column.key] == 'pendiente','bg-green': (row[column.key] == 'realizado')||(row[column.key] == 'aprobado'), 'bg-secondary': (row[column.key] == 'rechazado')||(row[column.key] == 'desaprobado')}">
                                {{row[column.key]}} 
                            </span>
                        </div>
                    </div>
                </td>
            </tr>

        </ng-container>
    </tbody>
</table>