import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-info-nav',
  templateUrl: './info-nav.component.html',
  styleUrls: ['./info-nav.component.scss']
})
export class InfoNavComponent {
  @Input() value:number = 10
  @Input() img:string = 'assets/icons/prof.svg'
  @Input() title:string = 'Cursos profesionales'
  @Input() tooltip:string = ""
}
