import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";


import {  MatriculadosContainerComponent } from "./matriculados.component";
import { InfoMatriculadosComponent } from "./info-matriculados/info-matriculados.component";
import { CapacitacionesMatriculadosComponent } from "./capacitaciones-matriculados/capacitaciones-matriculados.component";
import { CertificacionesMatriculadosComponent } from "./certificaciones-matriculados/certificaciones-matriculados.component";



const routes: Routes = [
	{
		path: "",
        component:MatriculadosContainerComponent,
        children:[
            { path: ":id", component: InfoMatriculadosComponent },
            { path: ":id/capacitaciones", component: CapacitacionesMatriculadosComponent },
            { path: ":id/certificaciones", component: CertificacionesMatriculadosComponent },
        ]

	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MAtriculadosRoutingModule {}