import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],

})
export class ProgressBarComponent implements AfterViewInit {
  animationState = 'initial'; // Initial state
  width = 'scaleX(0%)'; // Initial width
  borderRadius = '2rem'; // Initial border radius
  @Input() expandedWidth!: string | number; // Default expanded width

  ngAfterViewInit() {
    setTimeout(() => {

      this.toggleWidth()
    }, 400)
  }

  toggleWidth() {
    // Toggle the animation state and width value
    this.animationState = this.animationState === 'initial' ? 'expanded' : 'initial';
    this.width = this.animationState === 'initial' ? 'scaleX(0%)' : `scaleX(${this.expandedWidth}%)`;
  }
}
