import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store/appReducer';

import * as ui from '../../store/actions/ui.actions'
import { selectUserAuth } from 'src/app/store/selectors/user.selector';
import { CoursesService } from 'src/app/core/services/courses.service';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { selectCapacitaciones, selectCapacitacionesList } from 'src/app/store/selectors/capacitaciones.selector';

export const navAdmin = [
  {
    name: 'Capacitaciones',
    path: ''
  },
  {
    name: 'Evaluaciones',
    path: '/examenes'
  },
  {
    name: 'Certificados',
    path: '/certificaciones'
  },
  {
    name: 'Profesionales',
    path: '/matriculados'
  },

]

export const navMatriculado = [
  {
    name: 'Capacitaciones',
    path: ''
  },
  {
    name: 'Mis capacitaciones',
    path: '/mis-capacitaciones'
  },
  {
    name: 'Certificados',
    path: '/certificaciones'
  }

]

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  activeView?: string
  $subscriptionActive!: Subscription
  userSubs!: Subscription

  capacitaciones!: CourseI[] | undefined
  capacitacionesSub!: Subscription

  constructor(private store: Store<AppState>, private coursesServ: CoursesService) {

  }

  ngOnInit(): void {
    this.store.dispatch(ui.changeBreadCrumb({ breadCrumb: [] }))
    this.$subscriptionActive = this.store.select('ui').subscribe(
      ui => {

        this.activeView = ui.viewScreen
      }
    )
    this.capacitacionesSub = this.store.select(selectCapacitacionesList)
      .subscribe(res => this.capacitaciones = res)
    this.userSubs = this.store.select(selectUserAuth).subscribe(
      (user) => {
        if (user)
          if (user.roles[0].name == 'administrador general') {
            this.store.dispatch(ui.changeNavTabs({ navTabs: navAdmin }))
            if(!this.capacitaciones){
              this.store.dispatch(ui.changeView({ viewScreen: 'Activas' }))
            }
          }
          else {
            this.store.dispatch(ui.changeNavTabs({ navTabs: navMatriculado }))
            if(!this.capacitaciones){
              this.store.dispatch(ui.changeView({ viewScreen: 'Disponibles' }))
            }
          }
      }
    )

  }

  ngOnDestroy(): void {
    this.$subscriptionActive?.unsubscribe()
    this.userSubs?.unsubscribe()
    this.capacitacionesSub?.unsubscribe()
  }
}
