<section  data-aos="fade-left" data-aos-duration="800" >
    <div class="w-full flex justify-end pt-4">
        <app-dropdown imgUrl="assets/icons/optionsnav.png" [options]="matOptions"></app-dropdown>
        <input #lista type="file" (change)="onFileChange($event)" class="hidden">
        <!-- <app-button-form (action)="clickLista()" title="ADJUNTAR LISTA"></app-button-form> -->
    </div>
    <div class="overflow-x-scroll lg:overflow-x-auto mt-4">
        <TableAtom [loading]="loading" [columns]="columns" [rows]="dataRow"/>
    </div>
    <div class="w-full flex  justify-end">
        <p-paginator 
        *ngIf="rows"
        (onPageChange)="onPageChange($event)" 
        [first]="first"
        [rows]="rows.per_page"
        [totalRecords]="rows?.total"
        [pageLinkSize]="rows?.last_page <= 5 ? rows.last_page : 5"
        ></p-paginator>
    </div>
</section>
