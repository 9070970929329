import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { capacitacionView, capacitacionViewId } from 'src/app/store/actions/capacitaciones.actions';
import { showConfirmBaja } from 'src/app/store/actions/dialog.actions';
import { deleteSuccesInscription, inscriptionDelete } from 'src/app/store/actions/inscription.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { selectBajaDialog, selectLoadingDialog, selectSuccessBaja } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-confirm-baja',
  templateUrl: './confirm-baja.component.html'
})
export class ConfirmBajaComponent implements OnInit, OnDestroy {
  open = false
  end = false
  loading = false
  course!: CourseI | undefined
  inscriptions: any[] = []
  selectedInscriptions!: any[]
  form!: FormGroup
  _open!: Subscription
  _loading!: Subscription
  _course!: Subscription
  _succesBaja!: Subscription
  closeDialog() {
    this.store.dispatch(showConfirmBaja({ confirmBaja: false }))
    this.end = false
    this.selectedInscriptions = []
  }
  acceptConfirm() {
    this.loading = true
    if (this.selectedInscriptions) {
      const formtoSend = {
        inscriptions: this.selectedInscriptions.map(
          (el: any) => {
            return {
              id: el.id
            }
          }
        )
      }
      this.deleteInscription(formtoSend)
    }else{
      const params = {
        inscription_id:this.course?.inscription_me.id,
        student_id: this.course?.inscription_me.student_id
      }
      this.apiRest.deleteApi('inscriptions/baja/'+this.course?.inscription_me.id,params)
      .subscribe(
        {
          next: (res)=>{
            this.succesActions()
          },
          error: ()=>{
            this.errorsActions()
          }
        }
      )
    }
  }

 
  succesActions(){
    this.end = true
    this.loading = false
    if(this.course)
    this.store.dispatch(capacitacionViewId({id: this.course?.id}))
    setTimeout(()=>{
      this.closeDialog()
    },700)
  }

  errorsActions(){
    this.messageService.add(
      {
        severity: 'error',
        detail: 'Un error ha ocurrido intente mas tarde'
      }
    )
    this.loading = false
  }

  deleteInscription(props: any) {
    this.apiRest.deleteApi(`inscriptions/baja/class`, props).subscribe(
      {
        next: (val) => {
          this.succesActions()

        },
        error: (err) => {
         
        }
      }
    )

  }

  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
    private apiRest: ApiRestService
  ) { }
  get inscripciones() {
    return this.form.controls["inscripciones"] as FormArray
  }
  ngOnInit(): void {
    this._open = this.store.select(selectBajaDialog)
      .subscribe(res => this.open = res)
    this._loading = this.store.select(selectLoadingDialog)
      .subscribe(res => this.loading = res)
    this._course = this.store.select(selectCapacitacion)
      .subscribe(res => {
        this.course = res
        if (res) {

          this.inscriptions = res?.inscription_me?.details ?
            res?.inscription_me?.details.map(insc => {
              return {
                ...insc,
                clase: res.classes.find(value => value.id.toString() == insc.class_id)
              }
            }) : []

        }

      })
    this._succesBaja = this.store.select(selectSuccessBaja)
      .subscribe(res => {
        this.end = res
      }
      )
  }
  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._loading?.unsubscribe()
    this._course?.unsubscribe()
    this._succesBaja?.unsubscribe()
  }
}
