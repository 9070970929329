import { ActionReducerMap } from "@ngrx/store";
import * as ui from './reducers/ui.reducer'
import * as user from './reducers/user.reducer'
import * as examenes from './reducers/examenes.reducer'
import * as certificaciones from './reducers/certificaciones.reducer'
import * as matriculados from './reducers/matriculados.reducer'
import * as capacitaciones from './reducers/capacitaciones.reducer'
import * as formdata from './reducers/formCapacitacion.reducer'
import * as dialogos from './reducers/dialog.reducer'
import { StateAttendance, attendanceReducer } from './reducers/attendance.reducer';


export interface AppState {
    ui: ui.StateUI,
    user: user.UserState,
    examenes: examenes.StateExamenes,
    certificaciones: certificaciones.StateCert,
    matriculados: matriculados.StateMatriculados,
    capacitaciones: capacitaciones.StateCapacitaciones,
    dialogos: dialogos.StateDialog,
    formData: formdata.StateForm,
    attendance: StateAttendance}

export const appReducers: ActionReducerMap<AppState> = {
    ui: ui.uiReducer,
    user: user.userReducer,
    examenes: examenes.examenesReducer,
    certificaciones: certificaciones.certificacionesReducer,
    matriculados: matriculados.matriculadosReducer,
    capacitaciones: capacitaciones.capacitacionesReducer,
    dialogos: dialogos.dialogReducer,
    formData: formdata.formCaptacionReducer,
    attendance: attendanceReducer,

}