<p-dialog [closable]="false" [visible]="open" [modal]="true" [draggable]="false" [resizable]="false">
  <div class="absolute top-0 border-2 rounded w-[30px] h-[30px] right-2 top-2 cursor-pointer border-primary text-primary flex items-center justify-center"
      (click)="closeDialog()">X</div>

  <ng-container *ngIf="open">
      <div class="relative">
          <div [ngClass]="loading ? 'opacity-60 pointer-events-none' : ''">
              <p-fileUpload name="demo[]" (uploadHandler)="onUpload($event)" [multiple]="false" [customUpload]="true"
                  [showCancelButton]="false" accept="image/*, application/pdf" [maxFileSize]="2000000"
                  invalidFileSizeMessageSummary = "¡Error! Tamaño de la imagen demasiado grande. Debe ser inferior a 2 MB."
                  chooseLabel="Cargar" uploadLabel="Aceptar">
                  <ng-template let-file pTemplate="file">
                      <div data-aos="fade-in" data-aos-duration="800">
                          <p>Archivo cargado</p>
                          <div class="w-full">
                              <div class="flex w-full text-[14px] pt-3">{{file.name}}</div>
                              <div class="h-[5px] w-full rounded-[5px] bg-primary"></div>
                          </div>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="content" let-file>
                      <div class="flex items-center justify-center flex-col" *ngIf="file.length == 0">
                          <svg width="93" height="67" viewBox="0 0 93 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M58.8835 47.2118L47.8028 35.0481C47.3814 34.5889 46.8021 34.4839 46.2303 34.5435C45.6661 34.4839 45.0801 34.5889 44.6586 35.0481L33.5629 47.2043C32.8635 47.9717 32.908 49.1605 33.683 49.8675C34.4579 50.5744 35.6467 50.5148 36.3384 49.7399L46.2226 38.9079L56.0992 49.7399C56.8137 50.5148 57.995 50.5526 58.7624 49.8675C59.5306 49.1606 59.5902 47.9718 58.8833 47.212L58.8835 47.2118Z"
                                  fill="#37405B" />
                              <path
                                  d="M48.1109 64.9862C48.1109 66.0314 47.2679 66.8668 46.2302 66.8668C45.1925 66.8668 44.3496 66.0314 44.3496 64.9862V36.3103C44.3496 35.2726 45.1925 34.4297 46.2302 34.4297C47.2679 34.4297 48.1109 35.2726 48.1109 36.3103V64.9862Z"
                                  fill="#37405B" />
                              <path
                                  d="M77.8481 22.5746C76.1329 9.87697 64.6384 0.133789 51.0464 0.133789C37.6637 0.133789 26.2608 9.64183 24.2963 22.0759H19.1062C8.67205 22.0759 0.1875 30.5613 0.1875 40.9946C0.1875 51.4288 8.67291 59.9211 19.1062 59.9211H37.7245V59.8993C38.7404 59.869 39.5673 59.0639 39.5673 58.0338C39.5673 57.0103 38.7395 56.1985 37.7245 56.1682V56.1531H19.0987C10.7407 56.1531 3.94124 49.3525 3.94124 40.9879C3.94124 32.6224 10.7341 25.8305 19.0987 25.8305H25.9663C26.9444 25.8305 27.7496 25.0782 27.8394 24.1077C28.7948 12.771 38.973 3.88755 51.0389 3.88755C63.2026 3.88755 73.4109 12.8617 74.2534 24.3185C74.3214 25.1908 74.9981 25.9137 75.878 26.0413C83.3779 27.0421 89.0349 33.4665 89.0349 40.9815C89.0349 49.3319 82.2343 56.1542 73.8696 56.1542L54.6343 56.1534V56.1836C53.6411 56.2289 52.8368 57.0189 52.8368 58.034C52.8368 59.0347 53.642 59.8239 54.6343 59.8693V59.9146H73.8772C84.3113 59.9146 92.8112 51.4215 92.8112 40.9806C92.796 32.0888 86.4615 24.416 77.8484 22.573L77.8481 22.5746Z"
                                  fill="#37405B" />
                          </svg>
                          <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
                          <p class="font-light pt-2 text-sm text-primary text-center">Arrastrar archivos PDF, JPG o PNG a
                              esta zona </p>
                      </div>
                  </ng-template>
              </p-fileUpload>

          </div>
      </div>
      <p-dialog [closable]="false" [visible]="end" [modal]="true" [draggable]="false" [resizable]="false">
          <div class="flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
                  <path
                      d="M75.8552 14.3995C75.7771 14.4767 75.7037 14.5586 75.6354 14.6445L37.2735 62.9752L14.1448 40.1075C10.909 36.9082 5.66268 36.9082 2.42686 40.1075C-0.808953 43.3068 -0.808955 48.4939 2.42686 51.6932L31.6642 80.6005C34.9 83.7998 40.1463 83.7998 43.3821 80.6005C43.4542 80.5293 43.5222 80.4542 43.5858 80.3755L87.6907 25.8665C90.8085 22.6589 90.7693 17.5595 87.5731 14.3995C84.3373 11.2002 79.091 11.2002 75.8552 14.3995Z"
                      fill="#84DB92" />
              </svg>
          </div>
          <div class="py-4 text-center">
              Se han cargado los archivos
              exitosamente
          </div>
      </p-dialog>
  </ng-container>
</p-dialog>