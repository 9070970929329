import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form/user-form.component';
import { AtomsModule } from '../atoms/atoms.module';
import { SkeletonProfileComponent } from './skeleton-form/skeleton-profile.component';
import { CardsSkeletonComponent } from './cards-skeleton/cards-skeleton.component';
import { GoogleMapComponent } from './google-map/google-map.component';


@NgModule({
  declarations: [
    UserFormComponent,
    SkeletonProfileComponent,
    CardsSkeletonComponent,
    GoogleMapComponent
  ],
  imports: [
    CommonModule,
    AtomsModule
  ],
  exports:[
    UserFormComponent,
    CardsSkeletonComponent,
    GoogleMapComponent
  ]
})
export class ComponentsModule { }
