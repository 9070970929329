export function cleanObject(obj: any): any {
    const cleanedObject: any = {};

    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== '') {
            cleanedObject[key] = obj[key];
        }
    }

    return cleanedObject;
}

export function transformDateStringToDate(dateString: string) {
    console.log(dateString);
    let transformedDate
    if(dateString){
        const [year, month, day] = dateString.split('-');
        // Create a new Date object using the components
        // Note: Months in JavaScript are zero-based, so we subtract 1 from the month
        transformedDate = new Date(Number(year), Number(month) - 1, Number(day));
    } else {
        transformedDate = new Date();
    }

    return transformedDate;
}

export function transformDateStringWithTimeToDate(dateString: string, timeString?: string) {
    // Split the date string into year, month, and day
    const [year, month, day] = dateString.split('-');

    // If a time string is provided, split it into hours, minutes, and seconds
    let [hours = '0', minutes = '0', seconds = '0'] = timeString ? timeString.split(':') : [];

    // Create a new Date object using the components
    // Note: Months in JavaScript are zero-based, so we subtract 1 from the month
    const transformedDate = new Date(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hours),
        Number(minutes),
        Number(seconds)
    );

    return transformedDate;
}

export function getYearsUntilNow(startYear: number): number[] {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }

    return years;
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function fixEncoding(inputString: string) {
    const replacements = {
        'Ã¡': 'á',
        'Ã©': 'é',
        'Ã­': 'í',
        'Ã³': 'ó',
        'Ãº': 'ú',
        'Ã±': 'ñ',
        'Ã‘': 'Ñ',
        'Ã¼': 'ü',
        'Ãœ': 'Ü',
        'Ã§': 'ç',
        // Add more replacements as needed
    };

    for (const [search, replace] of Object.entries(replacements)) {
        inputString = inputString.split(search).join(replace);
    }

    return inputString;
}