import { createSelector } from "@ngrx/store"
import { AppState } from "../appReducer"


export const selectExamenes = (state:AppState) => state.examenes

export const selectExamenesList = createSelector(
    selectExamenes,
    (state) => state.dataTable
)

export const selectExamenesPending = createSelector(
    selectExamenes,
    (state) => state.examenesPending
)

export const selectLoadingExamenes = createSelector(
    selectExamenes,
    (state) => state.loadingTable
)