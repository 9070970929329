import { createAction, props } from '@ngrx/store';
import { Classes, AttendanceI } from 'src/app/core/models/Attendance.interface';


export const presenteAction = createAction('[Attendance] Presente', 
props<{id:number, student_id:number, class_id:number, course_id:number}>()
)

export const ausenteAction = createAction('[Attendance] Ausente', 
props<{id:number, student_id:number, class_id:number, course_id:number}>()
)

export const getClasses = createAction('[Attendance] getClasses', 
props<{id:any}>()
)

export const loadedClasses = createAction('[Attendance] loadedClasses', 
props<{course: {data: AttendanceI}}>()
)

export const loadingAttendance = createAction('[Attendance] Loading')

export const updateClass = createAction('[Attendance] updateClass',
props<{id:number}>()
)

export const limpiarAttendance = createAction('[Attendance] Limpiar Attendace')