import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html'
})
export class GoogleMapComponent implements OnInit {
  @Input() plusCode: string | null | undefined = null;
  embedUrl: SafeResourceUrl | null = null;
  @Input() adress!:string | undefined
  mapsUrl !: string 
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.updateEmbedUrl();
  }

  updateEmbedUrl(): void {
    if(this.plusCode){
      this.mapsUrl =`https://www.google.com/maps?q=${this.plusCode}`
      const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
        this.plusCode
      )}&key=${environment.apiKeyGoogleMaps}`;
      this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }
  }
}
