import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { showExamenDialogFile } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectDialogExamenFile } from 'src/app/store/selectors/dialog.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-exam',
  templateUrl: './view-exam.component.html',
})
export class ViewExamComponent {

  _open!: Subscription
  @ViewChild('pdfViewer') public pdfViewer: any;
  status: { open: boolean, file?: string | undefined } = { open: false, file: undefined }
  file!: any
  fileName!: string | undefined
  apiUrl = environment.apiUrl

  closeDialog() {
    this.store.dispatch(showExamenDialogFile({ showExamenFile: { open: false, file: undefined } }))
  }
  config() {
  
  }
  constructor(
    private store: Store<AppState>,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this._open =
      this.store.select(selectDialogExamenFile)
        .subscribe(res => {
          this.status = res
          if (this.status.file) {
            this.file = this.sanitizer.bypassSecurityTrustResourceUrl(this.status.file) 
  
            // const filePath = this.status.file
            // const pathParts = filePath.split('/');
  
            // const fileName = pathParts[pathParts.length - 1];
            // 
          }
        })
  }
}
