import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { actionUpdateCapacitaciones } from 'src/app/store/actions/capacitaciones.actions';
import { dialogLinkPostCapacitacion } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { selectDialogLinksPost } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-link-post',
  templateUrl: './link-post.component.html'
})
export class LinkPostComponent implements OnInit, OnDestroy {
  _capacitacion!: Subscription
  _restApi!: Subscription
  _open!: Subscription
  loading = false
  step = 0
  selectedClass!:any
  link = new FormControl()
  capacitacion: CourseI | undefined
  open = false
  constructor(
    private store: Store<AppState>,
    private restApi: ApiRestService,
    private messageService:MessageService
  ) { }
  onEnterKeyPressed(event:any){
    if(this.link.value){
      this.step=1
    }
  }
  
  aceptar(){
    if(this.step == 0){
      this.onEnterKeyPressed(0)
    }else{
      const post =[ {
        class_id: this.selectedClass.id,
        url: this.link.value
      }]
      this.loading = true
      this._restApi = this.restApi.putApi(`courses/${this.capacitacion?.id}`, {post}).subscribe(
        {
          next: (res) => {
  
            this.messageService.add(
              {
                severity: 'success',
                summary: 'Éxito',
                detail: 'Curso editado con éxito'
              }
            );
            this.store.dispatch(actionUpdateCapacitaciones({ id: this.capacitacion?.id ? this.capacitacion.id : 0 }))
            this.loading = false
            this.closeDialog()
          },
          error: (err) => {
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Un error ha ocurrido',
                detail: err.message
              }
            );
            this.loading = false
          }
        }
      )
    }
  }

  closeDialog() {
    this.store.dispatch(dialogLinkPostCapacitacion({ postCapacitacion: false }))
    this._restApi?.unsubscribe()
    this.link.setValue(null)
    this.step = 0
  }
  ngOnInit(): void {
    this._capacitacion = this.store.select(selectCapacitacion)
      .subscribe(
        {
          next: (val) => this.capacitacion = val
        }
      )
    this._open = this.store.select(selectDialogLinksPost)
      .subscribe(
        {
          next: (val) => this.open = val
        }
      )
  }
  ngOnDestroy(): void {
    this._open.unsubscribe()
    this._capacitacion.unsubscribe()
  }
}
