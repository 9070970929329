import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';

import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { UserService } from 'src/app/core/services/user.service';
import * as actionAuth from '../actions/user.actions';
import { Router } from '@angular/router';
import { loadingListActive } from '../actions/capacitaciones.actions';
import { GetTokenService } from 'src/app/core/services/get-token.service';
import { Store } from '@ngrx/store';
import { AppState } from '../appReducer';
//import all requried services or any dependencies

@Injectable()
export class AuthEffects {
    constructor(
        private action$: Actions,
        private authService: UserService,
        private store: Store<AppState>,
        private router: Router,
        private getToken: GetTokenService
    ) { }

    effectName$ = createEffect(() =>
        this.action$.pipe(
            ofType(actionAuth.actionLoadingWithEmailAndPassword),
            mergeMap((action) =>
                this.authService.login({ email: action.email, password: action.password }).pipe(
                    tap((res) => {
                        localStorage.setItem('access_token', JSON.stringify(res));
                    }),
                    concatMap((res) => [

                        actionAuth.actionAuthMe({ token: res }), // se dispara effect effectAuthMe$
                      
                    ]),
                    catchError((error) => of(actionAuth.authError({ error })))
                )
            )
        )
    );
    changePage$ = createEffect(() =>
        this.action$.pipe(
            ofType(actionAuth.authAction),
            mergeMap((action) => {
                const { isLogged } = action;
                if (isLogged) {
                    const currentUrl = this.router.routerState.snapshot.url;
                    if (currentUrl == '/login') {
                        this.router.navigate(['/']);
                    }
                    return of(actionAuth.successAuth());
                }
                else return of(actionAuth.notLogged())
            })
        )
    );

    updatingUserInfo$ = createEffect(() =>
        this.action$.pipe(
            ofType(actionAuth.updateUserInfo),
            mergeMap(() => {
                const token = this.getToken.getToken()
                return this.authService.getUserData(token.access_token).pipe(
                    mergeMap((user) => [
                        actionAuth.getUserAction({ user: user }),
                    ]),
                    catchError((err) => {
                        mergeMap(()=>[
                            actionAuth.authError({ error: err }),
                        ])
                        localStorage.removeItem('access_token'),
                        
                        this.router.navigate(['login'])
                        return of(actionAuth.logoutAuth({ isLogged: false }))
                    })
                )
            })
        )
    )

    effectAuthMe$ = createEffect(() =>
        this.action$.pipe(
            ofType(actionAuth.actionAuthMe),
            mergeMap((action) => {
                const { token } = action;

                return this.authService.getUserData(token.access_token).pipe(
                    mergeMap((user) => [
                        actionAuth.authAction({ isLogged: true }), //Se dispara effecto ChangePage$
                        actionAuth.getUserAction({ user: user }),
                    ]),
                    catchError((err) => {
                        mergeMap(()=>[
                            actionAuth.authError({ error: err }),
                            
                        ])
                        localStorage.removeItem('access_token'),
                        this.router.navigate(['login'])
                        return of(actionAuth.logoutAuth({ isLogged: false }))
                    })
                )
            })
        )
    )
}

// localStorage.removeItem('access_token'),
// this.store.dispatch(logoutAuth({ isLogged: false }))
// this.router.navigate(['login'])