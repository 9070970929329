import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { DeleteDialogI, dialogPutAction } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectPutDialog } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-dialog-put-form',
  templateUrl: './dialog-put-form.component.html'
})
export class DialogPutFormComponent implements OnInit, OnDestroy {
  stateDialog: DeleteDialogI = {
    open: false
  }
  end = false
  _restApi!: Subscription
  loading = false
  name = new FormControl()
  rol = new FormControl()
  constructor(
    private store: Store<AppState>,
    private restAPI: ApiRestService,
    private messageService: MessageService
  ) { }
  acceptConfirm() {
    this.loading = true
    this._restApi = this.restAPI.putApi(this.stateDialog?.url ? this.stateDialog.url : '', { name: this.name.value, rol: this.rol.value, }).subscribe(
      {
        next: (res) => {
          this.end = true
          this.loading = false
          if (this.stateDialog.action) {
            this.stateDialog.action()
          }
          this.messageService.add({ severity: 'info', summary: 'Actualizado exitosamente', detail: '' });
        },
        error: (err) => {
          this.loading = false
          this.messageService.add({ severity: 'error', summary: 'Un error ha ocurrido intenta nuevamente', detail: '' });
        }
      }
    )
  }

  closeDialog() {
    this.store.dispatch(dialogPutAction({ putDialog: { open: false } }))
    this.end = false
  }

  ngOnInit(): void {
    this.store.select(selectPutDialog).subscribe(
      res => {
        this.stateDialog = res
        this.name.patchValue(res.params ? res.params.name : null)
        this.name.patchValue(res.params ? res.params.rol : null)
      }
    )
  }
  ngOnDestroy(): void {

  }
}
