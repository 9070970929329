import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appReducer';
import * as ui from '../../store/actions/ui.actions'
import { Subscription } from 'rxjs';
import { selectMatriculado } from 'src/app/store/selectors/matriculados.selector';
import { loadingMatriculado } from 'src/app/store/actions/matriculados.action';

@Component({
  selector: 'app-matriculados',
  templateUrl: './matriculados.component.html'
})
export class MatriculadosContainerComponent implements OnInit, OnDestroy {
  subs$!: Subscription
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {

  }

  _matriculado!: Subscription
  matriculado: any
  changeRoutes = (matriculado?: any) => {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');

    if (segments.length > 2 && segments[1] === 'matriculados') {
      const idParam = +segments[2]; // Convert the third segment to a number

      if (matriculado) {

      } else {
        this.store.dispatch(loadingMatriculado({ id: idParam.toString() }))
      }

      this.store.dispatch(ui.changeNavTabs({
        navTabs:
          [
            { name: 'Información', path: `matriculados/${idParam}` },
            {name:'Capacitaciones', path:`matriculados/${idParam}/capacitaciones`},
            {name:'Certificaciones', path:`matriculados/${idParam}/certificaciones`},
          ]
      }))
    }
  }

  ngOnInit(): void {
    this._matriculado = this.store.select(selectMatriculado)
      .subscribe(
        res => {
          if (res) {
            this.matriculado = {
              ...res,
              fullname: res.name + ' ' + res.last_name
            }

            this.store.dispatch(ui.changeBreadCrumb({
              breadCrumb:
                [
                  {
                    name: 'Profesionales',
                    url: 'matriculados'
                  },
                  {
                    name: this.matriculado.fullname
                  }
                ]
            }))
          }
          this.changeRoutes(res)

        }
      )



  }

  ngOnDestroy(): void {

  }
}
