<p-dialog [closable]="false" [visible]="open" [modal]="true" [draggable]="false" [resizable]="false">
    <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="91" height="71" viewBox="0 0 91 71" fill="none">
            <path
                d="M76.3552 2.39947C76.2771 2.47673 76.2037 2.55857 76.1354 2.64453L37.7735 50.9752L14.6448 28.1075C11.409 24.9082 6.16268 24.9082 2.92686 28.1075C-0.308953 31.3068 -0.308955 36.4939 2.92686 39.6932L32.1642 68.6005C35.4 71.7998 40.6463 71.7998 43.8821 68.6005C43.9542 68.5293 44.0222 68.4542 44.0858 68.3755L88.1907 13.8665C91.3085 10.6589 91.2693 5.55952 88.0731 2.39947C84.8373 -0.799823 79.591 -0.799823 76.3552 2.39947Z"
                fill="#84DB92" />
        </svg>
    </div>
    <div *ngIf="end">
        <p class="text-md text-center pt-4 pb-2">Asistencia confirmada correctamente</p>
    </div>
    <ng-container *ngIf="!end">
        <p class="text-md text-center pt-4 pb-2">¿Deseas confirmar tu asistencia?</p>
        <div class=" flex pt-[30px] gap-4">
            <app-button-form title="aceptar" (action)="acceptConfirm()"></app-button-form>
            <app-button-form (action)="closeDialog()" [secondary]="true" title="cancelar"></app-button-form>
        </div>

    </ng-container>
</p-dialog>