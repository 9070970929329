import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { showAdjuntarComprobante, showFirmFile } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';

import { Subscription } from 'rxjs'
import { selectDialogAdjuntar, selectDialogFirmFile } from 'src/app/store/selectors/dialog.selector';
import { MessageService } from 'primeng/api';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-firm-file',
  templateUrl: './firm-file.component.html',
  providers: [MessageService]
})
export class FirmFileComponent implements OnInit, OnDestroy {
  stateFirm: any
  end: boolean = false
  loading: boolean = false
  _open!: Subscription
  _subscriptionRest!: Subscription
  _subcriptionCapacitacion!: Subscription
  capacitacion!: CourseI | undefined
  form!: FormGroup
  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private messageService: MessageService,
    private restApi: ApiRestService
  ) { }


  uploadedFiles: any[] = [];
  show = true

  onChangeValue(event: any, type: any) {
    switch (type) {
      case "rol": {
        this.form.controls['rol'].patchValue(event)
      } break;
      case "name": {
        this.form.controls['name'].patchValue(event)
      } break;
    }
  }

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.show = false
    let formData = new FormData()
    formData.append('name', this.form.value.name)
    formData.append('file', this.uploadedFiles[0])
    formData.append('rol', this.form.value.rol)
    this.loading = true
    this._subscriptionRest = this.restApi.postApi('firms', formData).subscribe(
      res => {
        if (res) {
          this.form = this.fb.group({
            rol: new FormControl(""),
            name: new FormControl(""),
          });
          this.uploadedFiles = [];
          this.form.controls['rol'].patchValue('')
          this.form.controls['name'].patchValue('')
          this.end = true
          this.stateFirm.action()
          setTimeout(() => {
            this.closeDialog()
          }, 900)
        }
      }
    )
    this.messageService.add({ severity: 'info', summary: 'Archivo cargado', detail: '' });
  }

  closeDialog() {
    this.store.dispatch(showFirmFile({ showFirmFile: {
      open:false,
      action:undefined
    } }))
    this.end = false
    this.loading = false
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      rol: new FormControl(""),
      name: new FormControl(""),
    });

    this._open = this.store.select(selectDialogFirmFile)
      .subscribe(
        res => this.stateFirm = res
      )
  }

  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._subcriptionCapacitacion?.unsubscribe()
    this._subscriptionRest?.unsubscribe()
  }
}
