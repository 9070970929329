import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-number-incremetion',
  template: `
    <div  *ngIf="value!=0">{{ currentValue | number: '1.0-0' }}</div>
    <div *ngIf="value==0">{{ value }}</div>
  `
})
export class NumberIncremetionComponent implements OnInit {
  @Input() value!: number;
  currentValue: number = 0;

  ngOnInit(): void {
    this.incrementValue();
  }

  private incrementValue(): void {
    let interval = 1; // Adjust the interval duration as needed
   
    const steps = Math.ceil(this.value / interval);

    const increment = this.value / steps;
    if(this.value){

      const intervalId = setInterval(() => {
        this.currentValue += increment;
        if (this.currentValue >= this.value) {
          this.currentValue = this.value;
          clearInterval(intervalId);
        }
      }, 30);
    }
  }
}
