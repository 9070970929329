import { createReducer, on } from "@ngrx/store";
import { actionAuthMe, actionLoadingWithEmailAndPassword, authAction, authError, getUserAction, logoutAuth, notLogged, successAuth } from "../actions/user.actions";

export interface UserState {
    user?: any,
    isLogged: boolean
    loadingAuth: boolean
    error?: any
    token?: any
}

const initialState: UserState = {
    isLogged: false,
    loadingAuth: false,
}

export const userReducer = createReducer(
    initialState,
    on(actionLoadingWithEmailAndPassword, (state) => ({ ...state, loadingAuth: true })),
    on(getUserAction, (state, { user }) => ({ ...state, user, isLogged: true })),
    on(authAction, (state, { isLogged }) => ({ ...state, isLogged, loadingAuth: false })),
    on(authError, (state, { error }) => ({ ...state, loadingAuth: false, error })),
    on(actionAuthMe, (state, { token }) => ({ ...state, token, isLogged: true })),
    on(successAuth, (state) => ({ ...state, isLogged: true, loadingAuth:false })),
    on(logoutAuth, (state, { isLogged }) => ({ ...state, isLogged, loadingAuth:false })),
    on(notLogged, (state) => ({ ...state }))
);