import { createReducer, on } from "@ngrx/store";
import { actionCapacitacionesList, actionChangePage, actionPagination, capacitacionView, loadingCourse, capacitacionViewId, actionNavbarActions, actionLoadingCapacitaciones, actionMisCapacitacionesList, actionLoadingMisCapacitaciones, paymentFile, paramsCapacitaciones, allPaymentsForCapa } from "../actions/capacitaciones.actions";
import { ClassI, CourseI } from "src/app/core/models/Courses.interface";

export interface StateCapacitaciones {
    capacitacion?: CourseI
    capacitaciones?: CourseI[]
    id?: number
    paginator?: any
    navbarCapa?: any
    loading: boolean
    misCapacitaciones?: CourseI[]
    payment?: any,
    params?: any,
    payList?: any[]
}

const initialState = {
    loading: false,
}
const changeTitle = (classes: ClassI[] | undefined) => {
    if (classes)
        return classes.map((val, index) => { return { ...val, title: 'Clase ' + (index + 1) } })
    else return []
}

const changeData = (courses:CourseI[]) => {
    const coursesVideo = courses.map(course=>{
     const hasVideo = course.classes.find((val)=>val.video)
     return {
       ...course,
       grabacion:hasVideo
     }
    })
    return coursesVideo
  }
export const capacitacionesReducer = createReducer(
    initialState,
    on(loadingCourse, (state,) => ({ ...state })),
    on(capacitacionView, (state, { capacitacion }) => (
        {
            ...state,
            capacitacion: {
                ...capacitacion,
                classes: changeTitle(capacitacion?.classes)
            }
        })),
    on(actionCapacitacionesList, (state, { capacitaciones }) => ({ ...state, capacitaciones: changeData(capacitaciones)})),
    on(capacitacionViewId, (state, { id }) => ({ ...state, id })),
    on(actionChangePage, (state) => (state)),
    on(actionPagination, (state, { paginator }) => ({ ...state, paginator })),
    on(actionNavbarActions, (state, { navbarCapa }) => ({ ...state, navbarCapa })),
    on(actionLoadingCapacitaciones, (state, { loading }) => ({ ...state, loading })),
    on(paymentFile, (state, { payment }) => ({ ...state, payment })),
    on(actionLoadingMisCapacitaciones, (state, { loading }) => ({ ...state, loading })),
    on(actionMisCapacitacionesList, (state, { misCapacitaciones }) => ({ ...state, misCapacitaciones: changeData(misCapacitaciones) })),
    on(paramsCapacitaciones, (state, { params }) => ({ ...state, params })),
    on(allPaymentsForCapa, (state, { payList }) => ({ ...state, payList }))
)