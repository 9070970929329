import { createAction, props } from "@ngrx/store";

export const isLoading = createAction('[UI Loading] Change Loading',
    props<{ isLoading: boolean }>()
);

export const changeView = createAction('[UI Nav] Change View',
    props<{ viewScreen: string }>()
);
export const changeNavTabs = createAction('[UI Nav] Change Nav Tabs',
    props<{ navTabs: Array<any> }>()
);
export const changeBreadCrumb = createAction('[UI Nav] Change BreadCrumb',
    props<{ breadCrumb: Array<{ name: string, url?: string }> }>()
);




export const capacitacionesTabs = createAction('[UI Capacitaciones] Change Captaciones Tabs',
    props<{ capacitacionesTabs: Array<{ label: string, value: string }> }>())
export const capacitacionesActive = createAction('[UI Capacitaciones] Change Captacion Active',
    props<{ activeCapt: string }>())

