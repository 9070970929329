<p-dialog
  [closable]="false"
  [visible]="open"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div
    class="absolute top-0 border-2 rounded w-[30px] h-[30px] right-2 top-2 cursor-pointer border-primary text-primary flex items-center justify-center"
    (click)="closeDialog()"
  >
    X
  </div>

  <ng-container *ngIf="open">
    <div class="relative">
      <div class="flex justify-center">
        <svg width="77" height="87" viewBox="0 0 77 87" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_603_19286)">
          <path d="M66.5374 -0.000316447C67.7899 0.603102 68.1467 1.61598 68.1289 2.97367C68.0754 7.95547 68.1289 12.9409 68.1039 17.9226C68.1039 19.4168 67.3581 20.3471 66.1484 20.4369C64.8352 20.5159 63.8789 19.5425 63.8682 18.0699C63.8682 15.6634 63.8682 13.2569 63.8682 10.8504V4.33136H4.3036V82.6321H63.8646V81.6659C63.8646 73.7376 63.8646 65.8094 63.8646 57.8812C63.8646 56.2577 64.771 55.2699 66.1484 55.3669C66.6136 55.3994 67.0545 55.588 67.4006 55.9025C67.7467 56.217 67.978 56.6393 68.0575 57.1017C68.0972 57.4619 68.1127 57.8242 68.1039 58.1865V84.0939C68.1039 86.249 67.3902 86.9674 65.2135 86.9674C44.4237 86.9674 23.6351 86.9757 2.84768 86.9925C1.18835 86.9925 -0.00708008 86.1951 -0.00708008 84.1191C0.0357413 57.1065 0.0357413 30.094 -0.00708008 3.08143C-0.00708008 1.69859 0.314079 0.664164 1.54163 -0.00390625L66.5374 -0.000316447Z" fill="#37405B"/>
          <path d="M34.7923 68.4776C33.2543 68.4776 32.2623 67.4001 32.4943 65.9275C33.0474 62.4111 33.5933 58.8876 34.2428 55.3856C34.4013 54.6197 34.7726 53.9147 35.3133 53.3527C44.7959 43.7554 54.2975 34.1774 63.8181 24.6185C65.0992 23.3254 66.2268 23.3326 67.5186 24.6185C70.3448 27.4607 73.1698 30.3042 75.9937 33.1489C77.3283 34.4922 77.3354 35.6165 76.0151 36.9418C66.5135 46.5199 56.9976 56.0848 47.4675 65.6365C46.9303 66.1628 46.256 66.5252 45.5227 66.6817C42.1255 67.3175 38.7069 67.8455 35.2991 68.4058C35.1099 68.4561 34.9172 68.4633 34.7923 68.4776ZM37.1475 63.7329C37.5044 63.697 37.7791 63.679 38.0432 63.6359C39.9273 63.327 41.8186 63.0505 43.6921 62.6805C44.2572 62.574 44.7813 62.3102 45.2051 61.9191C51.1525 55.9783 57.0844 50.0183 63.0009 44.0392C63.215 43.8237 63.4149 43.5974 63.554 43.4501L57.3557 37.2112C57.2488 37.2944 57.1464 37.3831 57.0488 37.477C50.9396 43.6237 44.834 49.7705 38.7319 55.9172C38.5472 56.0831 38.4135 56.2987 38.3465 56.5386C37.9361 58.9055 37.5543 61.2653 37.1475 63.7329ZM66.655 40.1026L71.6509 35.0741L65.663 29.0112L60.6101 34.054L66.655 40.1026Z" fill="#37405B"/>
          <path d="M16.8574 20.3764C17.5711 19.6114 18.2063 18.893 18.8771 18.2214C20.8374 16.2315 22.8048 14.2512 24.7794 12.2806C25.7464 11.3144 27.0881 11.2461 27.9374 12.083C28.7867 12.9199 28.7689 14.2919 27.809 15.2617C24.6973 18.4081 21.5773 21.5461 18.4489 24.6758C17.4284 25.6958 16.1972 25.6887 15.1731 24.6758C13.6696 23.1888 12.1792 21.6886 10.7018 20.1753C9.78474 19.2378 9.74547 17.9017 10.5698 17.0576C10.7779 16.8568 11.0235 16.6996 11.2924 16.5951C11.5614 16.4907 11.8482 16.441 12.1364 16.449C12.4246 16.457 12.7083 16.5226 12.9711 16.6419C13.2338 16.7611 13.4705 16.9318 13.6672 17.1438C14.7128 18.1675 15.7226 19.2235 16.8574 20.3764Z" fill="#37405B"/>
          <path d="M16.8075 45.6552L24.2513 38.1341C24.5118 37.8755 24.7616 37.6061 25.0399 37.369C25.4466 37.0149 25.9691 36.8248 26.5068 36.8354C27.0444 36.846 27.5592 37.0566 27.9517 37.4265C28.3366 37.8282 28.5516 38.3644 28.5516 38.9225C28.5516 39.4805 28.3366 40.0168 27.9517 40.4184C27.0454 41.3846 26.0926 42.3077 25.1576 43.2452C22.9928 45.4266 20.8267 47.6056 18.6595 49.7822C17.3392 51.1076 16.2437 51.1004 14.9055 49.7606C13.5673 48.4209 12.1578 47.0058 10.7946 45.6193C9.77763 44.5921 9.69556 43.2631 10.5663 42.3867C11.437 41.5103 12.7644 41.6001 13.7779 42.6166C14.7913 43.6331 15.7726 44.6064 16.8075 45.6552Z" fill="#37405B"/>
          <path d="M16.7896 71.0057L24.2834 63.463C24.5225 63.2224 24.7508 62.9745 24.9971 62.7446C25.406 62.3695 25.9418 62.1658 26.495 62.1751C27.0482 62.1845 27.577 62.4062 27.9732 62.7949C28.3538 63.2019 28.5625 63.7418 28.5552 64.3007C28.5479 64.8596 28.3251 65.3938 27.9339 65.7905C27.4379 66.3328 26.9062 66.8393 26.3888 67.3601C23.8242 69.939 21.2573 72.5203 18.688 75.1039C17.3534 76.4401 16.2508 76.4437 14.9198 75.1039C13.5887 73.7642 12.1685 72.3491 10.8018 70.959C9.78477 69.9246 9.70626 68.5992 10.5841 67.7264C11.4619 66.8536 12.768 66.9291 13.7957 67.9635C14.7806 68.9297 15.7369 69.9246 16.7896 71.0057Z" fill="#37405B"/>
          </g>
          <defs>
          <clipPath id="clip0_603_19286">
          <rect width="77" height="87" fill="white"/>
          </clipPath>
          </defs>
          </svg>
      </div>
      <p class="text-md text-center pt-4 pb-2">
        ¿Deseas aprobar la evaluación presentada?
      </p>

      <div class="mb-3" [formGroup]="form">
        <p class="text-md text-start pt-4 pb-2">
          Motivo de rechazo
        </p>
        <textarea
          type="text"
          formControlName="motivo"
          class="rounded-[8px] ps-[20px] text-grey max-w-[456px] pe-[15px] py-2 outline-none border text-[16px] font-light w-full border border-secondary"
          placeholder="Motivo de rechazo"
          (sumbit)="onChangeValue($event)"
        ></textarea>
      </div>
        
      <div class="flex pt-[30px] gap-4">
        <app-button-form
          title="Aprobar"
          (action)="acceptConfirm()"
        ></app-button-form>
        <app-button-form
          (action)="desaprobar()"
          [secondary]="true"
          title="Desaprobar"
        ></app-button-form>
      </div>
    </div>
    <p-dialog
      [closable]="false"
      [visible]="end"
      [modal]="true"
      [draggable]="false"
      [resizable]="false"
    >
      <div class="flex justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
        >
          <path
            d="M75.8552 14.3995C75.7771 14.4767 75.7037 14.5586 75.6354 14.6445L37.2735 62.9752L14.1448 40.1075C10.909 36.9082 5.66268 36.9082 2.42686 40.1075C-0.808953 43.3068 -0.808955 48.4939 2.42686 51.6932L31.6642 80.6005C34.9 83.7998 40.1463 83.7998 43.3821 80.6005C43.4542 80.5293 43.5222 80.4542 43.5858 80.3755L87.6907 25.8665C90.8085 22.6589 90.7693 17.5595 87.5731 14.3995C84.3373 11.2002 79.091 11.2002 75.8552 14.3995Z"
            fill="#84DB92"
          />
        </svg>
      </div>
      <div class="py-4 text-center">
        Se ha aprobado el examen exitosamente
      </div>
    </p-dialog>
  </ng-container>
</p-dialog>

