<p-dialog [closable]="false" [visible]="open" [modal]="true" [draggable]="false" [resizable]="false">
    <div class="absolute top-0 border-2 rounded w-[30px] h-[30px] right-2 top-2 cursor-pointer border-primary text-primary flex items-center justify-center"
        (click)="closeDialog()">X</div>

    <ng-container *ngIf="open">
        <div class="min-w-[400px] text-center flex flex-col justify-center items-center gap-4">
            <ng-container *ngIf="step==0; else step2">
                <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M25.821 89.9998C32.6697 90.0116 39.2417 87.2945 44.0806 82.4479L56.0214 70.5072C61.8463 64.6836 64.5432 56.4294 63.2809 48.2893C62.0186 40.1488 56.9482 33.0999 49.6322 29.3137L46.7107 32.2352C45.4623 33.4641 44.6742 35.0849 44.4789 36.8257C49.582 39.045 53.2575 43.6459 54.2939 49.1138C55.3303 54.5817 53.5944 60.2086 49.6574 64.1414L37.7166 76.0829C33.4657 80.3281 27.274 81.9846 21.4713 80.4279C15.6687 78.8719 11.136 74.3399 9.58 68.5373C8.02332 62.7346 9.67973 56.5428 13.9243 52.292L18.7937 47.4219H18.7944C17.7308 43.7157 17.2866 39.859 17.479 36.0077L7.56009 45.9266V45.9273C2.71497 50.7674 -0.00781237 57.3358 -0.00781237 64.1851C-0.00850975 71.0338 2.71282 77.6023 7.55723 82.4447C12.4016 87.2863 18.9715 90.0042 25.8204 89.9999L25.821 89.9998Z"
                        fill="#535B73" />
                    <path
                        d="M45.9228 7.56528L33.9813 19.5068C28.1626 25.3339 25.4698 33.5858 26.7309 41.7229C27.9918 49.8612 33.0552 56.9108 40.365 60.7038L43.2922 57.7766C44.5294 56.5393 45.3133 54.9199 45.5163 53.1819C40.4173 50.9585 36.7475 46.3582 35.7125 40.8925C34.6775 35.4274 36.412 29.8041 40.3455 25.8704L52.287 13.9289C55.4415 10.7703 59.7217 8.99452 64.1853 8.99309C68.6488 8.99166 72.9303 10.7639 76.0869 13.9205C79.2429 17.0764 81.0156 21.3579 81.0144 25.8215C81.0131 30.2851 79.2373 34.5653 76.0793 37.7198L71.2084 42.5899C72.2713 46.2961 72.7156 50.1528 72.5238 54.0034L82.4435 44.0838V44.0844C88.9665 37.5607 91.5151 28.0518 89.1271 19.1402C86.739 10.2284 79.7787 3.26809 70.8675 0.880615C61.9564 -1.5074 52.4472 1.04033 45.9232 7.56419L45.9228 7.56528Z"
                        fill="#535B73" />
                </svg>

                <h2 class="text-center">Insertá el link de la grabación a continuación</h2>
                <div class="w-full">
                    <app-input (keyup.enter)="onEnterKeyPressed($event)" [control]="link" placeholder="url"></app-input>
                </div>
            </ng-container>
            <ng-template #step2>
                <div data-aos="fade-in" class="flex flex-col w-[90%]" data-aos-duration="900">
                    <ng-container *ngFor="let clase of capacitacion?.classes; let index=index" class="w-full">
                        <div class="flex w-full">
                            <div class="flex align-items-center w-full">
                                <p-radioButton [name]="'radio' + index" [value]="clase" [(ngModel)]="selectedClass"
                                    inputId="{{index}}"></p-radioButton>
                                <label [for]="index" class="ml-2 cursor-pointer"> Clase {{index + 1}}</label>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </ng-template>
            <div class="py-2 flex gap-4 justify-center items-center">
                <app-button-form title="aceptar" (action)="aceptar()" [disabled]="loading"></app-button-form>
                <app-button-form [secondary]="true" (action)="closeDialog()" title="cancelar"></app-button-form>
            </div>
        </div>
    </ng-container>
</p-dialog>