import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiRestService } from "src/app/core/services/api-rest.service";
import { getExamenes, loadedExamanesPending, loadedExamenes } from "../actions/examenes.actions";
import { mergeMap, tap } from "rxjs";

@Injectable()

export class ExamenesEffect {
    constructor(
      private actions$: Actions,
      private restService: ApiRestService,
    ) {}

    getExamanes$ = createEffect(
        () => this.actions$.pipe(
            ofType(getExamenes),
            mergeMap(() => 
                this.restService.getAPI('exams').pipe(
                    mergeMap((data) => 
                        [
                            loadedExamenes({dataTable: data.data.courses.data}),
                            loadedExamanesPending({examenesPending: data.data.exams_pending})
                        ]
                    )
                )
            )
    ))

}