<p-dialog [closable]="false" [visible]="open" [modal]="true" [draggable]="false" [resizable]="false">
    <div class="py-6 px-10 flex flex-col justify-center items-center">

        <ng-container *ngIf="!end">

            <div class="flex text-primary justify-center items-center flex-col">
                <img src="assets/icons/premioazul.svg" class="h-[80px] w-[80px]" alt="">
                <div class="pt-10">
                    <div *ngIf="user?.roles[0].id==1; else matri">
                        <h2 class="text-center  text-xl">¿Deseas inscribir a un matriculado a la capacitación?</h2>
                        <app-input placeholder="Matricula" [formControl]="matriculaForm"></app-input>
                        <p></p>
                        <p class="text-center pt-2">Seleccione las clases a las que desea inscribirse</p>
                    </div>
                    <ng-template #matri>
                        <h2 class="text-center  text-xl">¿Deseas inscribirte a la capacitación?</h2>
                    </ng-template>
                </div>
            </div>
            <div class="flex flex-col pt-4 gap-1 w-full">
                <form [formGroup]="form" class="flex flex-col w-full gap-2">
                    <ng-container formArrayName="asistencia">
                        <div *ngFor="let class of asistencia.controls; let index = index"
                            class="border-1 border border-primary rounded-[4px] flex gap-4 px-4 py-2 w-full font-light ">
                            <div [formGroupName]="index" class="w-full" [ngClass]="{'opacity-50 pointer-events-none': form.get('asistencia.'+index+'.total_cupos')?.value}">
                                <div class="flex w-full justify-between gap-2 items-center" >
                                    <div class="flex gap-2 items-center w-full">
    
                                        <p-checkbox formControlName="checked"
                                        [binary]="true"
                                        (click)="changeType(index)"
                                            [inputId]="index.toString()"></p-checkbox>
                                        <label class="cursor-pointer"  [for]="index">
                                            Clase {{index + 1}}
                                            {{
                                            form.get('asistencia.'+index+'.externa')?.value == 1
                                            && form.get('asistencia.'+index+'.virtual')?.value ==1
                                            ?
                                            "- Externa - Virtual"
                                            :
                                            form.get('asistencia.'+index+'.externa')?.value == 1
                                            && form.get('asistencia.'+index+'.in_person')?.value ==1
                                            ?
                                            "- Externa - Presencial"
                                            :
                                            form.get('asistencia.'+index+'.virtual')?.value ==1
                                            && form.get('asistencia.'+index+'.in_person')?.value !=1
                                            ?
                                            "- Virtual"
                                            :
                                            form.get('asistencia.'+index+'.virtual')?.value !=1
                                            && form.get('asistencia.'+index+'.in_person')?.value ==1
                                            ?
                                            "- Presencial"
                                            :
                                            ""
                                            }}
                                        </label>
                                    </div>
                                    <div *ngIf="
                                    (form.get('asistencia.'+index+'.externa')?.value == 1
                                    && form.get('asistencia.'+index+'.virtual')?.value !=1
                                    && form.get('asistencia.'+index+'.in_person')?.value !=1) || 
                                    (form.get('asistencia.'+index+'.externa')?.value != 1
                                    && form.get('asistencia.'+index+'.virtual')?.value ==1
                                    && form.get('asistencia.'+index+'.in_person')?.value !=1) ||   
                                    form.get('asistencia.'+index+'.externa')?.value != 1
                                    && form.get('asistencia.'+index+'.virtual')?.value !=1
                                    && form.get('asistencia.'+index+'.in_person')?.value ==1" class="text-secondary text-[14px] whitespace-nowrap">
                                        Cupos disponibles: {{form.get('asistencia.'+index+'.cupos')?.value}}
                                    </div>
                                </div>
                               
                                <ng-container
                                    *ngIf="form.get('asistencia.'+index+'.virtual')?.value == 1 && form.get('asistencia.'+index+'.in_person')?.value == 1">
                                    <div class="flex pt-3 flex-col gap-2">
                                        <div 
                                        [ngClass]="{'opacity-50 pointer-events-none': (form.get('asistencia.'+index+'.cupos_p')?.value==0)}" 
                                        class="flex gap-2 justify-between w-full">
                                            <div class="flex gap-2">
                                                <p-radioButton inputId="presencial" 
                                                    formControlName="type"
                                                    [disabled]="true"
                                                    [value]="'in_person'"
                                                    (onClick)="changeRadio(index, 'in_person')"></p-radioButton>
                                                <label for="presencial" class="ml-2 cursor-pointer"> Presencial</label>
                                            </div>
                                            <div *ngIf="form.get('asistencia.'+index+'.cupos_p')?.value!=0;else agotadoP"  class="text-secondary  text-[14px] whitespace-nowrap"> Cupos disponibles: {{form.get('asistencia.'+index+'.cupos_p')?.value}}</div>
                                            <ng-template #agotadoP >
                                                <div class="text-[14px] whitespace-nowrap">
                                                    Cupos agotados
                                                </div> 
                                            </ng-template>
                                        </div>
                                        <div 
                                        [ngClass]="{'opacity-50 pointer-events-none': (form.get('asistencia.'+index+'.cupos_virtual')?.value==0)}"
                                        class="flex gap-2 justify-between w-full">
                                            <div class="flex gap-2">
                                                <p-radioButton formControlName="type" inputId="virtual"
                                        
                                                    [value]="'virtual'"
                                                    (onClick)="changeRadio(index, 'virtual')"></p-radioButton>
                                                <label for="virtual" class="ml-2 cursor-pointer"> Virtual</label>
                                            </div>
                                            <div *ngIf="form.get('asistencia.'+index+'.cupos_virtual')?.value!=0;else agotadoV"  class="text-secondary  text-[14px] whitespace-nowrap"> Cupos disponibles: {{form.get('asistencia.'+index+'.cupos_virtual')?.value}}</div>
                                            <ng-template #agotadoV >
                                                <div class="text-[14px] whitespace-nowrap">
                                                    Cupos agotados
                                                </div> 
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </form>

            </div>
            <div class=" flex pt-[30px] gap-4">
                <app-button-form [disabled]="loadingReq" [onLoading]="loadingReq" [title]="loadingReq ? 'Mandando solicitud' : 'aceptar'" (action)="acceptInscription()"></app-button-form>
                <app-button-form (action)="closeDialog()" [secondary]="true" title="cancelar"></app-button-form>
            </div>
        </ng-container>

        <div *ngIf="end" class="py-4 text-center">
            <div class="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="91" height="90" viewBox="0 0 91 90" fill="none">
                    <g clip-path="url(#clip0_590_26907)">
                        <path
                            d="M1.62199 88.8802C0.464348 87.7244 0.173583 86.024 0.885145 84.5508L26.7669 30.8324C27.3881 29.5432 28.6018 28.6326 30.0122 28.3927C31.4227 28.1529 32.8711 28.6127 33.8843 29.6243L60.9706 56.6683C61.9838 57.6799 62.4443 59.1242 62.2041 60.5343C61.9639 61.9444 61.0501 63.1561 59.7606 63.7746L5.95819 89.6177C5.42181 89.8756 4.85472 90.0018 4.29487 90.0018C3.31602 90.0018 2.35884 89.6177 1.62199 88.882V88.8802ZM30.4674 31.0578C29.9111 31.1516 29.4524 31.4978 29.2086 32.0045L3.32685 85.7228C3.01441 86.3702 3.40993 86.839 3.53815 86.967C3.66638 87.0951 4.13594 87.49 4.78429 87.178L58.5867 61.3349C59.0942 61.0915 59.441 60.6335 59.5349 60.0781C59.6288 59.5227 59.4554 58.9764 59.0563 58.5779L31.9699 31.5339C31.6431 31.2075 31.215 31.0308 30.7635 31.0308C30.666 31.0308 30.5667 31.0398 30.4674 31.056V31.0578Z"
                            fill="#84DB92" />
                        <path
                            d="M52.357 67.1827C52.4527 67.1827 52.5502 67.1719 52.6478 67.152C53.3792 66.9934 53.8415 66.2721 53.6826 65.5418L49.2453 45.2651C49.0863 44.5348 48.3639 44.0732 47.6325 44.2319C46.9011 44.3906 46.4388 45.1119 46.5977 45.8421L51.035 66.1188C51.1741 66.7517 51.7339 67.1827 52.357 67.1827Z"
                            fill="#84DB92" />
                        <path
                            d="M42.4369 71.9466C42.5309 71.9466 42.6266 71.9376 42.7241 71.916C43.4555 71.7591 43.9215 71.0396 43.7625 70.3093L35.4658 31.8224C35.3087 31.0921 34.5881 30.6269 33.8567 30.7856C33.1252 30.9424 32.6593 31.6619 32.8182 32.3922L41.115 70.8791C41.2522 71.5138 41.8139 71.9466 42.4369 71.9466Z"
                            fill="#84DB92" />
                        <path
                            d="M32.5158 76.7106C32.6115 76.7106 32.7091 76.6998 32.8066 76.6799C33.538 76.5213 34.0004 75.8 33.8414 75.0697L25.8156 38.3841C25.6567 37.6539 24.9343 37.1922 24.2028 37.3509C23.4714 37.5096 23.0091 38.2309 23.168 38.9612L31.1938 75.6467C31.3329 76.2796 31.8928 76.7106 32.5158 76.7106Z"
                            fill="#84DB92" />
                        <path
                            d="M22.5984 81.4764C22.6941 81.4764 22.7917 81.4656 22.8892 81.4457C23.6206 81.2871 24.0829 80.5658 23.924 79.8355L18.2785 54.0321C18.1195 53.3018 17.3971 52.8402 16.6657 52.9989C15.9343 53.1576 15.472 53.8788 15.6309 54.6091L21.2764 80.4125C21.4155 81.0454 21.9753 81.4764 22.5984 81.4764Z"
                            fill="#84DB92" />
                        <path
                            d="M12.6879 86.2908C12.7836 86.2908 12.8812 86.28 12.9787 86.2602C13.7101 86.1015 14.1725 85.3802 14.0135 84.65L10.7375 69.6782C10.5785 68.9479 9.85613 68.4863 9.1247 68.645C8.39327 68.8037 7.93094 69.5249 8.08987 70.2552L11.3659 85.227C11.505 85.8599 12.0649 86.2908 12.6879 86.2908Z"
                            fill="#84DB92" />
                        <path
                            d="M83.3303 55.6964L80.5147 54.0339L77.5475 55.4079C77.0797 55.6243 76.5289 55.5575 76.128 55.2348C75.727 54.912 75.5428 54.3891 75.6566 53.886L76.37 50.698L74.145 48.3052C73.7946 47.9283 73.6881 47.3838 73.8723 46.9041C74.0565 46.4227 74.4972 46.0873 75.0101 46.0386L78.2663 45.7302L79.8592 42.8776C80.1102 42.4286 80.596 42.1582 81.1107 42.1852C81.6254 42.2104 82.0805 42.526 82.2864 42.9984L83.5849 45.9953L86.7942 46.6264C87.2999 46.7256 87.7062 47.1025 87.8399 47.6001C87.9753 48.0978 87.8146 48.6279 87.4281 48.9669L84.9737 51.1271L85.3656 54.3692C85.427 54.8795 85.1923 55.3826 84.7606 55.6639C84.5367 55.81 84.2784 55.8839 84.0202 55.8839C83.7818 55.8839 83.5434 55.8208 83.3303 55.6964ZM82.3605 48.5125C81.9234 48.426 81.5568 48.1321 81.378 47.7228L80.8868 46.5886L80.2836 47.6687C80.0669 48.0581 79.6713 48.3142 79.2289 48.3575L77.9954 48.4747L78.8388 49.3817C79.1422 49.708 79.265 50.1624 79.1675 50.597L78.8966 51.8033L80.0199 51.284C80.4244 51.0965 80.8958 51.1199 81.2787 51.3471L82.3442 51.9764L82.1961 50.7485C82.1437 50.3067 82.3099 49.8667 82.6458 49.5728L83.5759 48.7542L82.3605 48.5161V48.5125Z"
                            fill="#84DB92" />
                        <path
                            d="M41.9106 13.7438L39.644 12.1011L37.0037 13.0333C36.5179 13.2046 35.9761 13.0856 35.6076 12.7268C35.2392 12.368 35.1056 11.8306 35.2645 11.3402L36.1296 8.68047L34.4247 6.46257C34.1105 6.05325 34.0563 5.50328 34.2838 5.04167C34.5114 4.58005 34.9828 4.28794 35.4975 4.28794H38.2986L39.886 1.98348C40.1786 1.55974 40.6861 1.33795 41.1972 1.41188C41.7083 1.48581 42.1309 1.84284 42.2916 2.3315L43.1585 4.98937L45.844 5.78457C46.3389 5.93063 46.7073 6.34536 46.794 6.85205C46.8807 7.35874 46.673 7.87265 46.2558 8.17558L43.9911 9.82008L44.0633 12.615C44.0759 13.1289 43.796 13.6067 43.3391 13.8466C43.1404 13.9511 42.9237 14.0016 42.7088 14.0016C42.4289 14.0016 42.1489 13.9151 41.9124 13.7438H41.9106ZM41.7029 7.38038C41.2748 7.25416 40.9371 6.92598 40.7999 6.50224L40.5633 5.77376L40.128 6.40487C39.8752 6.77091 39.458 6.9909 39.0119 6.9909H38.2444L38.7121 7.60037C38.983 7.95379 39.0625 8.41721 38.9252 8.84095L38.6887 9.56944L39.4129 9.31338C39.8337 9.16552 40.2996 9.23224 40.6608 9.4937L41.2821 9.9445L41.2622 9.17815C41.2514 8.73276 41.4591 8.31082 41.8203 8.04936L42.4415 7.59857L41.7047 7.38038H41.7029Z"
                            fill="#84DB92" />
                        <path
                            d="M80.3256 16.9029L78.115 12.734L73.4212 12.1876C72.9083 12.1281 72.4749 11.7837 72.3015 11.2987C72.1282 10.8136 72.2455 10.2727 72.6049 9.90301L75.8937 6.51665L74.9636 1.89151C74.8624 1.38662 75.0557 0.869114 75.4638 0.553558C75.872 0.239806 76.4246 0.183908 76.887 0.411108L81.1292 2.48656L85.2469 0.173089C85.6966 -0.0793557 86.251 -0.0559144 86.6755 0.2362C87.1017 0.526511 87.3238 1.0332 87.2516 1.5435L86.5852 6.21372L90.0599 9.40894C90.4391 9.75696 90.5872 10.2907 90.441 10.7848C90.2947 11.2788 89.8811 11.6467 89.3736 11.735L84.7196 12.5447L82.7492 16.8326C82.5343 17.2996 82.072 17.6062 81.5573 17.6206C81.5446 17.6206 81.532 17.6206 81.5193 17.6206C81.0191 17.6206 80.5567 17.3447 80.322 16.9011L80.3256 16.9029ZM84.2301 7.72117C83.9014 7.42004 83.7443 6.97646 83.8075 6.53649L84.1868 3.875L81.8408 5.19312C81.4525 5.41131 80.9812 5.42393 80.5802 5.22919L78.162 4.04631L78.6911 6.68255C78.7796 7.11891 78.646 7.56971 78.3354 7.89067L76.4607 9.82007L79.1354 10.132C79.5779 10.1843 79.968 10.4494 80.1757 10.8425L81.4345 13.219L82.5578 10.7757C82.7438 10.3718 83.1176 10.0833 83.5565 10.0076L86.2095 9.54598L84.2283 7.72478L84.2301 7.72117Z"
                            fill="#84DB92" />
                        <path
                            d="M51.3402 40.8257C51.687 40.8257 52.0337 40.694 52.2974 40.429L53.3142 39.4138C53.8433 38.8855 53.8433 38.0289 53.3142 37.5006C52.785 36.9723 51.9272 36.9723 51.398 37.5006L50.3812 38.5158C49.8521 39.0441 49.8521 39.9006 50.3812 40.429C50.6449 40.6922 50.9917 40.8257 51.3384 40.8257H51.3402Z"
                            fill="#84DB92" />
                        <path
                            d="M57.0531 35.1223C57.3999 35.1223 57.7466 34.9906 58.0103 34.7256L74.5424 18.2193C75.0715 17.691 75.0715 16.8345 74.5424 16.3062C74.0132 15.7778 73.1554 15.7778 72.6262 16.3062L56.0941 32.8124C55.565 33.3407 55.565 34.1972 56.0941 34.7256C56.3578 34.9888 56.7046 35.1223 57.0513 35.1223H57.0531Z"
                            fill="#84DB92" />
                        <path
                            d="M50.1386 27.7527C50.6696 27.7527 51.1735 27.4389 51.3902 26.9178L56.9653 13.4877C57.2525 12.7971 56.9238 12.0073 56.2321 11.7206C55.5404 11.4357 54.7493 11.7621 54.4622 12.4527L48.8871 25.8828C48.5999 26.5734 48.9286 27.3632 49.6203 27.6499C49.7901 27.7202 49.9653 27.7527 50.1386 27.7527Z"
                            fill="#84DB92" />
                        <path
                            d="M57.8066 9.28098C58.3376 9.28098 58.8414 8.96722 59.0582 8.44611L59.3778 7.67796C59.665 6.98734 59.3363 6.19755 58.6446 5.91084C57.9529 5.62414 57.1619 5.95232 56.8747 6.64293L56.5551 7.41109C56.2679 8.1017 56.5966 8.89149 57.2883 9.1782C57.458 9.24852 57.6332 9.28098 57.8066 9.28098Z"
                            fill="#84DB92" />
                        <path
                            d="M85.6812 31.0344C85.8781 31.0344 86.0768 30.9911 86.2664 30.901L87.117 30.4934C87.7906 30.1707 88.076 29.3629 87.7509 28.6885C87.4276 28.0159 86.6186 27.731 85.9431 28.0555L85.0925 28.4631C84.4189 28.7858 84.1335 29.5937 84.4586 30.268C84.6916 30.7531 85.1756 31.0344 85.6812 31.0344Z"
                            fill="#84DB92" />
                        <path
                            d="M61.4371 42.6612C61.634 42.6612 61.8326 42.618 62.0223 42.5278L79.6795 34.0601C80.3531 33.7373 80.6385 32.9295 80.3134 32.2551C79.9901 31.5825 79.1792 31.2976 78.5056 31.6222L60.8484 40.0899C60.1747 40.4127 59.8894 41.2205 60.2144 41.8949C60.4474 42.3799 60.9314 42.6612 61.4371 42.6612Z"
                            fill="#84DB92" />
                        <path
                            d="M61.4365 50.7575H68.6786C69.4263 50.7575 70.0331 50.1516 70.0331 49.4051C70.0331 48.6586 69.4263 48.0527 68.6786 48.0527H61.4365C60.6888 48.0527 60.082 48.6586 60.082 49.4051C60.082 50.1516 60.6888 50.7575 61.4365 50.7575Z"
                            fill="#84DB92" />
                        <path
                            d="M38.2925 27.4155C38.9679 27.4155 39.5531 26.9106 39.6361 26.2236L40.568 18.4393C40.6565 17.6982 40.1274 17.0238 39.3833 16.9354C38.641 16.8471 37.9656 17.3754 37.8771 18.1183L36.9452 25.9026C36.8567 26.6437 37.3859 27.3181 38.1299 27.4065C38.1841 27.4137 38.2383 27.4155 38.2925 27.4155Z"
                            fill="#84DB92" />
                        <path
                            d="M88.3746 61.7569C89.0862 61.7569 89.684 61.2016 89.7255 60.4821C89.7688 59.7356 89.1981 59.0972 88.4523 59.054L87.4463 58.9963C86.7005 58.9512 86.0593 59.5228 86.016 60.2675C85.9726 61.014 86.5433 61.6523 87.2892 61.6956L88.2952 61.7533C88.3222 61.7533 88.3475 61.7551 88.3746 61.7551V61.7569Z"
                            fill="#84DB92" />
                        <path
                            d="M81.907 61.3818C82.6186 61.3818 83.2164 60.8264 83.2579 60.1069C83.3013 59.3622 82.7306 58.7221 81.9847 58.6788L67.0401 57.8151C66.2942 57.77 65.6531 58.3416 65.6097 59.0864C65.5664 59.8311 66.1371 60.4712 66.883 60.5145L81.8276 61.3782C81.8547 61.3782 81.88 61.38 81.907 61.38V61.3818Z"
                            fill="#84DB92" />
                        <path
                            d="M29.0324 17.6224C29.092 17.6224 29.1534 17.6188 29.2148 17.6098C29.9552 17.5106 30.4754 16.829 30.376 16.0879L28.7055 3.71992C28.6062 2.98062 27.9253 2.46131 27.1812 2.56048C26.4408 2.65966 25.9206 3.34125 26.02 4.08236L27.6905 16.4503C27.7826 17.1283 28.3642 17.6224 29.0306 17.6224H29.0324Z"
                            fill="#84DB92" />
                        <path
                            d="M30.0819 25.3887C30.1415 25.3887 30.2029 25.3851 30.2643 25.376C31.0066 25.2769 31.5249 24.5953 31.4256 23.8542L31.1782 22.0203C31.0788 21.281 30.3962 20.7617 29.6539 20.8609C28.9117 20.9601 28.3933 21.6417 28.4927 22.3828L28.7401 24.2166C28.8322 24.8946 29.4137 25.3887 30.0801 25.3887H30.0819Z"
                            fill="#84DB92" />
                    </g>
                    <defs>
                        <clipPath id="clip0_590_26907">
                            <rect width="90" height="90" fill="white" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <h2 class="text-xl text-center">Se ha inscripto correctamente</h2>
            <h2 class="text-md text-center">Se notificarán los detalles del evento por email</h2>
        </div>
    </div>
</p-dialog>