import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { changeNavTabs } from 'src/app/store/actions/ui.actions';
import { AppState } from 'src/app/store/appReducer';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html'
})
export class CardComponent implements OnChanges {
  @Input() img?: string | undefined
  @Input() title?: string | undefined
  @Input() date?: string | undefined
  dateType!: any
  @Input() time?: string | undefined
  @Input() showInscriptos: boolean = true
  @Input() rendirDistancia: boolean = false
  @Input() inscriptos: string | number | undefined
  @Input() cuposDisponibles: string | number | undefined
  @Input() asistentes: string | number | undefined
  @Input() status: string | undefined
  @Input() type: 'Profesional' | 'Personal' | undefined
  @Input() puntos?: string | number | undefined
  @Input() data?: any
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() payment!: any
  @Input() grabacion!:any
  formatedTime!: string

  constructor(private store: Store<AppState>, private router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['date'] && this.date) {
      this.dateType = new Date(this?.date + ' UTC')
    }
  }

  click(ev: any) {
    this.onClick.emit(ev)
  }
}
