import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store/appReducer';
import { TableI } from './table.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'TableAtom',
  templateUrl: './table.component.html',
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() columns!: TableI[]
  @Input() rows!: Array<any>
  @Output() onClick: EventEmitter<any> = new EventEmitter
  @Input() loading: boolean = true

  buttonAction(event: any, row: any) {
    this.onClick.emit(row)
  }

  constructor(private store: Store<AppState>, private sanitizer: DomSanitizer) { }

  uiSubs$!: Subscription
  ngOnInit(): void {
    

    this.step = this.sanitizer.bypassSecurityTrustHtml(this.step)
  }
  ngOnDestroy(): void {
    
  }

  step: any = [`<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M3.4375 11C3.8172 11 4.125 10.6922 4.125 10.3125C4.125 9.9328 3.8172 9.625 3.4375 9.625C3.0578 9.625 2.75 9.9328 2.75 10.3125C2.75 10.6922 3.0578 11 3.4375 11Z" fill="#535B73"/>
  <path d="M6.875 1.375C5.35622 1.375 4.125 2.60622 4.125 4.125V6.875H2.75C1.23122 6.875 0 8.10622 0 9.625V13.75C0 15.2688 1.23122 16.5 2.75 16.5H4.125V17.875C4.125 19.3938 5.35622 20.625 6.875 20.625H15.125C16.6438 20.625 17.875 19.3938 17.875 17.875V16.5H19.25C20.7688 16.5 22 15.2688 22 13.75V9.625C22 8.10622 20.7688 6.875 19.25 6.875H17.875V4.125C17.875 2.60622 16.6438 1.375 15.125 1.375H6.875ZM5.5 4.125C5.5 3.36561 6.11561 2.75 6.875 2.75H15.125C15.8844 2.75 16.5 3.36561 16.5 4.125V6.875H5.5V4.125ZM6.875 11C5.35622 11 4.125 12.2312 4.125 13.75V15.125H2.75C1.99061 15.125 1.375 14.5094 1.375 13.75V9.625C1.375 8.86561 1.99061 8.25 2.75 8.25H19.25C20.0094 8.25 20.625 8.86561 20.625 9.625V13.75C20.625 14.5094 20.0094 15.125 19.25 15.125H17.875V13.75C17.875 12.2312 16.6438 11 15.125 11H6.875ZM16.5 13.75V17.875C16.5 18.6344 15.8844 19.25 15.125 19.25H6.875C6.11561 19.25 5.5 18.6344 5.5 17.875V13.75C5.5 12.9906 6.11561 12.375 6.875 12.375H15.125C15.8844 12.375 16.5 12.9906 16.5 13.75Z" fill="#535B73"/>
</svg>`]

}
