import { createReducer, on } from "@ngrx/store";
import { getMatriculado, getMatriculados, loadingMatriculado, matriculadosAction, paginationMatriculados } from "../actions/matriculados.action";

export interface StateMatriculados {
    isLoading: boolean,
    matriculados: Array<any>
    matriculado?: any
    error?: any
}

const initialState: StateMatriculados = {
    isLoading: true,
    matriculados: []
}

export const matriculadosReducer = createReducer(
    initialState,
    on(getMatriculados, (state) => ({ ...state, isLoading: true })),
    on(loadingMatriculado, (state) => ({ ...state, isLoading: true })),
    on(matriculadosAction, (state, { matriculados }) => ({ ...state, matriculados, isLoading: false })),
    on(getMatriculado, (state, { matriculado }) => ({ ...state, matriculado, isLoading: false })),
    on(paginationMatriculados, (state) => ({ ...state, isLoading: true }))
)