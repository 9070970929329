import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert-copa',
  templateUrl: './alert-copa.component.html'
})
export class AlertCOPAComponent {
 @Input() image?:string = 'assets/icons/icono_puntos.svg'
 @Input() text?:string
}
 