import { createSelector } from "@ngrx/store"
import { AppState } from "../appReducer"


export const selectMatriculados = (state:AppState) => state.matriculados

export const selectMatriculadosList = createSelector(
    selectMatriculados,
    (state) => state.matriculados
)

export const selectLoadingMatriculados = createSelector(
    selectMatriculados,
    (state) => state.isLoading
)

export const selectMatriculado = createSelector(
    selectMatriculados,
    (state)=> state.matriculado
)

