<div class="relative inline-block text-left">
  <div *ngIf="imgUrl">
    <i *ngIf="optionsNotRead && optionsNotRead.length>0; else noBadge" (click)="openMenu()" pBadge
      [value]="optionsNotRead ? optionsNotRead.length.toString() : '0'">
      <img class="cursor-pointer opacity-100 transform ease-in-out duration-400 hover:opacity-80" [src]="imgUrl"
        alt="" />
    </i>
    <ng-template #noBadge>
      <i (click)="openMenu()">
        <img class="cursor-pointer opacity-100 transform ease-in-out duration-400 hover:opacity-80" [src]="imgUrl"
          alt="" />
      </i>
    </ng-template>
  </div>

  <div [ngClass]="{ hidden: !open }"
    class="absolute z-20 w-[500px] max-h-[600px] overflow-y-scroll transition ease duration-600 right-0 border-[2px] border-primary mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none "
    role="menu" [style.opacity]="open ? 1 : 0" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="p-5 border-primary " role="none">
      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
      <h2 class="pb-2">Notificaciones:</h2>
      <ng-container *ngIf="options && options.length>0; else noNotifications">
        <div class="w-full h-full" *ngFor="let option of options; let i = index">

          <div *ngIf="!option.read"
            class="flex items-center border-b-2 border-b-primary bg-[#eeeeee50] hover:brigthness(1.2) p-3 rounded-md">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.5" fill="#C3363A" />
            </svg>
            <a class="text-gray-700 flex flex-col items-center cursor-pointer gap-4 w-full block border-b-1 text-light border-b-primary px-4 py-2 transition duration-600 m "
              role="menuitem" tabindex="-1" id="menu-item-{{ i }}" (click)="accionGlobal(option.action)">
              <span>
                {{ option.title  }}
              </span>
              <span class="w-full flex justify-end">
                <small class="font-light">{{option.date}}</small>
              </span>
            </a>
          </div>

          <div *ngIf="option.read" class="flex items-center border-b-2 border-b-primary bg-[#fff]  p-3 rounded-md">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.5" stroke-width="1px" stroke="#000000" />
            </svg>
            <a class="text-gray-700 flex items-center cursor-pointer gap-4 w-full block border-b-1  text-light border-b-primary px-4 py-2 transition duration-600 m "
              role="menuitem" tabindex="-1" id="menu-item-{{ i }}" (click)="accionGlobal(option.action)">
              <span>
                {{ option.title }}
              </span>
              <span class="w-full flex justify-end">
                <small class="font-light">{{option.date}}</small>
              </span>
            </a>
          </div>

        </div>
      </ng-container>
      <ng-template #noNotifications>
        <div class="text-primary text-sm">
          Todavía no hay notificaciones
        </div>
      </ng-template>


    </div>
  </div>

</div>
<div *ngIf="open" (click)="open = false" class="fixed z-10 w-full h-screen top-0 left-0 bg-transparent"></div>