import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/store/appReducer';

import { logoutAuth } from 'src/app/store/actions/user.actions';
import { MenuItem, MessageService } from 'primeng/api';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';
import { selectBreadCrumb, selectNavTabs } from 'src/app/store/selectors/ui.selector';
import { UserI, infoMat } from 'src/app/core/models/UserI.interface';
import { selectCapacitacion, selectNavbarCapacitaciones } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { DialogWithActionsI, dialogWithACtions } from 'src/app/store/actions/dialog.actions';
import { selectDialogActions } from 'src/app/store/selectors/dialog.selector';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { actionUpdateCapacitaciones } from 'src/app/store/actions/capacitaciones.actions';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  _certificate!: Subscription
  certifcate!: any
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private messageService: MessageService,
    private restApi: ApiRestService
  ) {
    this.url = window.location.href
    this.items = [
      {
        label: 'Update',
        icon: 'pi pi-refresh',
        command: () => {
          this.update();
        }
      },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          this.delete();
        }
      },
    ];
  }
  navCapacitacionData: any
  red = true
  items!: MenuItem[];
  _subscriptionUser!: Subscription
  _subscriptionNavbarData!: Subscription
  infoMatriculado!: Array<any>
  _user!: Subscription
  userData!: UserI
  user$!: Observable<UserI>
  $subscriptionTabs!: Subscription
  tabs$!: Observable<any>
  breadCrumb$!: Observable<any>
  tabActive?: string
  token?: any
  _restApi!: Subscription
  _capacitacion!: Subscription
  capacitacion!: CourseI | undefined

  infoMatriculados?: infoMat[]

  dropDownOptionptions!: any[]
  dropDownOptionptionsAdmin = [
    {
      title: "Configuración",
      action: () => { this.router.navigate(['configuracion']) }
    },
    {
      title: "Cerrar sesión",
      action: () => {
        this.logOutFunc()
      }
    },
  ]

  dropDownOptionptionsMatriculado = [
    {
      title: "Perfil",
      action: () => { this.router.navigate(['perfil']) }
    },
    {
      title: "Cerrar sesión",
      action: () => {
        this.logOutFunc()
      }
    },
  ]

  dropDownNotificationOptions!: any[]
  dropDownNotificationOptionDefault = [
    {
      title: "Lorem ipsum dolor sit amet consectetur. Est nibh odio sapien urna neque mauris. Hace 15 min ",
      read: false,
      action: () => { }
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur. Est nibh odio sapien urna neque mauris. Hace 15 min ",
      read: true,
      action: () => { }
    },
  ]


  actionEmmit(event: any) {

  }

  activarDesactivarCapa() {
    this.store.dispatch(dialogWithACtions({ dialogActions: { ...this.dialogActionsState, loading: true } }))
    this._restApi = this.restApi
      .putApi(`courses/${this.capacitacion?.id}`,
        {
          status: this.capacitacion?.status_id == 1 ? 2 : 1
        }
      )
      .subscribe(
        {
          next: (res) => {
            this.store.dispatch(dialogWithACtions({ dialogActions: { ...this.dialogActionsState, loading: false, end: true } }))
            this.messageService.add(
              {
                severity: 'success',
                summary: 'Éxito',
                detail: `Se ha realizado con éxito la operación`
              }
            );
            this.store.dispatch(actionUpdateCapacitaciones({ id: this.capacitacion?.id ? this.capacitacion.id : 0 }))
          },
          error: (err) => {
            this.store.dispatch(dialogWithACtions({ dialogActions: { ...this.dialogActionsState, loading: false } }))
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Un error ha ocurrido',
                detail: err.message
              }
            );
          }
        }
      )

  }

  urlCertificate!: string

  getCertficateAlumn(res: CourseI) {
    const firmasId = res.firms.map((el: any) => { return el.id })
    const props = { firms: firmasId, capacitacion: res.title, course_id: res.id }
    this.restApi.getAPI('document', props)
      .subscribe(
        {
          next: (res) => { },
          error: (err) => {
            this.urlCertificate = err.url
          }
        }
      )
  }

  changeStatus() {
    this.store.dispatch(dialogWithACtions({
      dialogActions:
      {
        open: true,
        loading: false,
        end: false,
        action: () => {
          this.activarDesactivarCapa()
        },
        text: {
          question: `¿Quieres ${this.capacitacion?.status_id == 1 ? 'desactivar' : 'activar'} la capacitación ${this.capacitacion?.title}?`,
          end: `Capacitación ${this.capacitacion?.status_id == 1 ? 'desactivada' : 'activada'}`
        }
      }
    }))
  }

  logOutFunc() {
    localStorage.removeItem('access_token'),
      this.store.dispatch(logoutAuth({ isLogged: false }))
    this.router.navigate(['login'])
  }

  percent!: number

  url!: string

  readNotifications(id: any, created_at: any) {
    let props = {
      id: id,
      created_at: created_at
    }
    this.restApi.getAPI('notifications/' + id, props)
      .subscribe(
        {
          next: (res) => {
            this.messageService.add(
              {
                severity: 'success',
                summary: 'Éxito',
                detail: `Notificacion recibida con exito.`
              }
            );
            this.getNotifications();
          },
          error: (err) => {
            console.log(err);
          }
        }
      )
  }


  getNotifications() {
    const props = {}
    this.restApi.getAPI('notifications', props)
      .subscribe(
        {
          next: (res) => {
            let hoy = new Date().getTime();
            let result: any = [];
            res.map((n: any, index: any) => {
              let created_at = new Date(n.created_at).getTime();
              let diff = created_at - hoy;
              diff = diff / (1000 * 60 * 60 * 24);
              diff = Math.round(diff)
              let date = "Hoy"

              if (diff < 0) {
                date = "Hace " + (-diff) + " dias."
              } else {
                let time_start = formatDate(new Date(n.created_at), 'H:mm', 'en-US');
                let time_end = formatDate(new Date(), 'H:mm', 'en-US');
                

                let minuts_start : any = time_start.split(':');
                minuts_start = parseInt(minuts_start[0]) * 60 + parseInt(minuts_start[1]);
                let minuts_end : any = time_end.split(':')
                minuts_end = parseInt(minuts_end[0]) * 60 + parseInt(minuts_end[1]);

                let diff_minuts = minuts_end - minuts_start;
                let horas = Math.floor(diff_minuts / 60);
                let minutos = diff_minuts % 60;

                if(horas > 0){
                  if(horas == 1){
                    date = "Hace "+horas+" Hora"
                  }
                } else {
                  date = "Hace "+minutos+" minutos"
                }
              }

              let data = JSON.parse(n.data)
              if(typeof data === 'object'){
                let notification = {
                  title: data.title,
                  read: n.read_at ? true : false,
                  date: date,
                  action: () => { this.readNotifications(n.notifiable_id, n.created_at) }
                }
                result.push(notification)
              }
            })

            this.dropDownNotificationOptions = result
          },
          error: (err) => {
            console.log(err);
          }
        }
      )
  }

  save(severity: string) {
    this.messageService.add({ severity: severity, summary: 'Success', detail: 'Data Saved' });
  }

  update() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Updated' });
  }

  delete() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Deleted' });
  }
  _dialogActions!: Subscription
  dialogActionsState!: DialogWithActionsI
  ngOnInit(): void {
    this.token = localStorage.getItem('access_token');
    this.token = JSON.parse(this.token);
    this.token = this.token?.access_token

    this._dialogActions = this.store.select(selectDialogActions)
      .subscribe(res => this.dialogActionsState = res)
    this._subscriptionNavbarData = this.store.select(selectNavbarCapacitaciones)
      .subscribe(res => {
        this.navCapacitacionData = res
      })

    this.tabs$ = this.store.select(selectNavTabs)
    this.breadCrumb$ = this.store.select(selectBreadCrumb)

    this.user$ = this.store.select(selectUserAuth)
    this._user = this.user$.subscribe(user => {

      if (user?.roles[0]?.name == "administrador general") {
        this.dropDownOptionptions = this.dropDownOptionptionsAdmin
      } else {
        this.dropDownOptionptions = this.dropDownOptionptionsMatriculado
      }

      //#Notificaciones

      this.getNotifications()
      //#End

      this.userData = user
      this.infoMatriculados = [
        {
          img: "assets/icons/prof.svg",
          title: "Cursos <br> profesionales",
          tooltip: "El puntaje máximo será de 90 puntos. ",
          value: user?.course_profesional
        },
        {
          img: "assets/icons/personales.svg",
          title: "Cursos <br> personales",
          tooltip: "El puntaje máximo será de 30 puntos. ",
          value: user?.course_personal
        },
        {
          img: "assets/icons/examenes-aprobados.svg",
          title: "Evaluaciones <br> a distancia",
          tooltip: "A computar como “cursos profesionales”.",
          value: user?.exam
        },
        {
          img: "assets/icons/capacitaciones.svg",
          title: "Cursos <br> externos",
          tooltip: "El puntaje máximo será de 30 puntos. ",
          value: user?.certifications
        }
      ]
    })

    this._capacitacion = this.store.select(selectCapacitacion)
      .subscribe(res => {
        this.capacitacion = res
        if (res) this.getCertficateAlumn(res)
      })
  }


  ngOnDestroy(): void {
    this.$subscriptionTabs?.unsubscribe()
    this._subscriptionUser?.unsubscribe()
    this._user?.unsubscribe()
    this._subscriptionNavbarData?.unsubscribe()
    this._capacitacion?.unsubscribe()
    this._dialogActions?.unsubscribe()
    this._restApi?.unsubscribe()
  }




  shouldShowImage(): boolean {
    const currentRoute = this.router.url; // Get the current route
    return currentRoute !== '/' && currentRoute !== '/mis-capacitaciones' && currentRoute !== '/certificaciones'; // Return true if the current route is not the root ('/')
  }
}
