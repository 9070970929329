import { Injectable } from '@angular/core';
import { GetTokenService } from './get-token.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Mats } from '../models/Matriculado.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatriculadosService {

  constructor(private http: HttpClient, 
              private GetTokenS:GetTokenService) { }

  token = this.GetTokenS.getToken()

  getMatriculados():Observable<any>{

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });

    return this.http.get(environment.apiUrl + 'users', { headers });
  }

  getFile() {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });

    return this.http.get(environment.apiUrl + 'users/import/show', { headers,
      responseType: 'blob',
      observe: 'response'
    });
  }

}
