import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetTokenService } from './get-token.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InscriptionsService {

  constructor(private http:HttpClient, private GetTokenS:GetTokenService) { }
  
  postInscription(form:any): Observable<any>{
    const token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(environment.apiUrl+'inscriptions', form, { headers })
  }

  confirmInscription(form:any){
    const token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });
    const params = new HttpParams()
    .set('inscription_id',form.inscription_id)
    return this.http.get(environment.apiUrl+'inscriptions/confirm/'+form.inscription_id,{ headers, params })
  }

  deleteInscription(props:any){
    const token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token.access_token}`
    });
    const params = new HttpParams()
    .set('inscription_id',props.inscription_id)
    .set('student_id',props.student_id)
    return this.http.delete(environment.apiUrl+'inscriptions/baja/'+props.inscription_id, {headers, params})
  }

}
