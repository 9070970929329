import { AttendanceEffect } from "./attendance.effects";
import { CapacitacionEffect } from "./capacitacion.effect";
import { ExamenesEffect } from "./examenes.effects";
import { InscriptionEffect } from "./inscription.effects";
import { MatriculadosEffect } from "./matriculados.effect";
import { AuthEffects } from "./user.effects";

export const EffectsArray = [
    CapacitacionEffect,
    AuthEffects,
    InscriptionEffect,
    AttendanceEffect,
    MatriculadosEffect,
    ExamenesEffect
]