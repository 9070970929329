<div class="container">
  <div>
    <article class="flex flex-wrap sm:flex-no-wrap gap-6 justify-between pt-4" *ngIf="user$ | async as user">
      <div class="flex flex-wrap sm:flex-no-wrap gap-5">
        <app-button-tab [disabled]="loading" *ngFor="let tab of buttonTabs" [title]="tab.label" [value]="tab.value" [active]="tab.value == tabActive"
          (action)="changeActive($event)" />
      </div>

      <div *ngIf="user.roles[0]?.name == 'administrador general'"
        class="rounded-[8px] cursor-pointer text-bolder ffEuclid flex gap-2 items-center text-primary text-sm font-600 border-primary border-2 py-2 hover:bg-primary hover:text-white transition duration-400">
        <app-dropdown [title]="'Agregar nueva'" [options]="options"></app-dropdown>
      </div>
    </article>
    <ng-container *ngIf="loading">
      <app-cards-skeleton></app-cards-skeleton>
    </ng-container>
    <ng-container *ngIf="!loading">
      <main *ngIf="capacitaciones$ | async as capacitaciones">
        <div *ngIf="capacitaciones.length > 0; else alert">
          <section *ngIf="tabActive == 'Activas' || tabActive == 'Anteriores'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div *ngFor="let capacitacion of capacitaciones" class="w-[350px]">
              <app-card [title]="capacitacion.title" [date]="
                  capacitacion.current_class?.date
                    ? capacitacion.current_class?.date
                    : capacitacion.classes[0]?.date
                " [time]="
                  capacitacion.current_class?.time
                    ? capacitacion.current_class?.time
                    : capacitacion.classes[0]?.time
                " [payment]="capacitacion?.payment?.free" [inscriptos]="capacitacion.inscriptions.length"
                [grabacion]="capacitacion?.grabacion" [puntos]="capacitacion?.point?.grant" [data]="capacitacion"
                [type]="capacitacion?.type" [img]="capacitacion.images[0]?.url" (onClick)="dispatchCard($event)" />
            </div>
          </section>
          <section *ngIf="tabActive == 'Finalizadas'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of capacitaciones">
              <app-card [title]="capacitacion.title" [grabacion]="capacitacion?.grabacion"
                [date]="capacitacion.classes[0]?.date" [time]="capacitacion.classes[0]?.time"
                [asistentes]="capacitacion.inscriptions.length" [payment]="capacitacion?.payment?.free"
                [puntos]="capacitacion?.point?.grant" [showInscriptos]="false" [img]="capacitacion.images[0]?.url"
                [type]="capacitacion.type" [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
          </section>
          <section *ngIf="tabActive == 'Inactivas'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of capacitaciones">
              <app-card [title]="capacitacion.title" [date]="capacitacion.classes[0]?.date"
                [time]="capacitacion.classes[0]?.time" [grabacion]="capacitacion?.grabacion"
                [asistentes]="capacitacion.inscriptions.length" [puntos]="capacitacion?.point?.grant"
                [showInscriptos]="false" [img]="capacitacion.images[0]?.url" [type]="capacitacion.type"
                [payment]="capacitacion?.payment?.free" [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
          </section>
          <section *ngIf="tabActive == 'Disponibles'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of capacitaciones">
              <app-card [title]="capacitacion.title" [time]="
                  capacitacion.current_class?.time
                    ? capacitacion.current_class?.time
                    : capacitacion.classes[0].time
                " [date]="
                  capacitacion.current_class?.date
                    ? capacitacion.current_class?.date
                    : capacitacion.classes[0].date
                " [payment]="capacitacion?.payment?.free" [grabacion]="capacitacion?.grabacion"
                [inscriptos]="capacitacion.inscriptions.length" [cuposDisponibles]="cuposDisponibles(capacitacion)"
                [puntos]="capacitacion?.point?.grant" [showInscriptos]="false" [img]="capacitacion.images[0]?.url"
                [type]="capacitacion.type" [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
          </section>
          <section *ngIf="tabActive == 'Profesionales'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of capacitaciones">
              <app-card [title]="capacitacion.title" [date]="
                  capacitacion.current_class?.date
                    ? capacitacion.current_class?.date
                    : capacitacion.classes[0]?.date
                " [time]="
                  capacitacion.current_class?.time
                    ? capacitacion.current_class?.time
                    : capacitacion.classes[0]?.time
                " [grabacion]="capacitacion?.grabacion" [payment]="capacitacion?.payment?.free"
                [inscriptos]="capacitacion.inscriptions.length" [cuposDisponibles]="cuposDisponibles(capacitacion)"
                [puntos]="capacitacion?.point?.grant" [showInscriptos]="false" [img]="capacitacion.images[0]?.url"
                [type]="capacitacion.type" [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
          </section>
          <section *ngIf="tabActive == 'Personales'" class="mt-8 flex flex-wrap gap-6" data-aos="fade-left"
            data-aos-duration="800">
            <div class="w-[350px]" *ngFor="let capacitacion of capacitaciones">
              <app-card [title]="capacitacion.title" [date]="
              capacitacion.current_class?.date ?
              capacitacion.current_class?.date : capacitacion.classes[0].date" [time]="capacitacion.current_class?.time ?
              capacitacion.current_class?.time : 
              capacitacion.classes[0]?.time" [payment]="capacitacion?.payment?.free"
                [inscriptos]="capacitacion.inscriptions.length" [grabacion]="capacitacion?.grabacion"
                [cuposDisponibles]="cuposDisponibles(capacitacion)" [puntos]="capacitacion?.point?.grant"
                [showInscriptos]="false" [img]="capacitacion.images[0]?.url" [type]="capacitacion.type"
                [data]="capacitacion" (onClick)="dispatchCard($event)" />
            </div>
          </section>
        </div>
        <ng-template #alert>
          <div class="pt-8">
            <app-alert-copa [text]="'Todavía no hay cursos en esta sección.'"
              image="assets/icons/alert.svg"></app-alert-copa>
          </div>
        </ng-template>
      </main>
      <div class="card flex justify-end" *ngIf="this.rows?.last_page > 1">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="8" [totalRecords]="rows.total"
          [pageLinkSize]="rows?.last_page <= 5 ? rows.last_page : 5"></p-paginator>
      </div>
    </ng-container>
  </div>
</div>

<!-- <app-card 
    title="Lorem Ipsum Title"
    date = "Sat Jul 04 2023 21:10:00"
    [showInscriptos] = "true"
    [rendirDistancia] = "true"
    [capacitacion] = "object"
    inscriptos= "7"
    cuposDisponibles = "25"
    asistentes = "10"
    status = 'Aprobado'
    type  = 'Personal'
    puntos = '40'
/> -->