import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TableI } from 'src/app/atoms/table/table.interface';
import { CapacitacionRealizadaPersonal, CapacitacionRealizadaProf } from 'src/app/core/models/CapacitacionRealizada.Model';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { getYearsUntilNow } from 'src/app/core/utils/functions';
import { capacitacionView } from 'src/app/store/actions/capacitaciones.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectMatriculado } from 'src/app/store/selectors/matriculados.selector';

@Component({
  selector: 'app-capacitaciones-matriculados',
  templateUrl: './capacitaciones-matriculados.component.html'
})
export class CapacitacionesMatriculadosComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private restAPI: ApiRestService,
    private router: Router
  ) { }

  dataRowProf !: any[]
  dataRowPersonal!: any[]
  columns: TableI[] = [
    {
      label: 'Denominación',
      key: 'title',
      type: "string"
    },
    {
      label: 'Fecha de realización',
      key: 'created_at',
      type: "date"
    },
    {
      label: 'Puntos obtenidos',
      key: 'points',
      type: "string"
    },
    {
      label: 'Acciones',
      key: '',
      type: 'action',
      action: [
        {
          action: (event: any, row: any) => {
            this.store.dispatch(capacitacionView({ capacitacion: row }))
            this.router.navigate(['capacitaciones/' + row.id])
          },
          svg: 'assets/icons/table/eye.svg',
          svgHover: 'assets/icons/table/eyeHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        }

      ]
    }
  ]
  first = true
  loading = true
  actualYear: number = new Date().getFullYear();
  years = getYearsUntilNow(2023)
  _rest!: Subscription
  _rest2!: Subscription
  _matriculado!: Subscription
  matriculado: any
  activeIndex: any = 0

  changeActive(number: number) {
    if (number == this.activeIndex) {
      this.activeIndex = undefined
    } else {
      this.activeIndex = number
    }
  }

  changeYear(year: number) {
    this.actualYear = year
    this.getCoursesProps(year)
    this.loading = true
  }

  itsApproved(list: Array<any>) {
    if(list){
      const student = list.find((el:any)=>el.student_id == this.matriculado.id) 
      console.log(student.approved == "Aprobado")
      return student.approved == "Aprobado"
    }
    return false
  }

  getCoursesProps(year: any) {

    const params = {
      start_date: `${year}-01-01`,
      end_date: `${year}-12-31`,
      user_id: this.matriculado?.id
    }

    this._rest2 = this.restAPI.getAPI('courses',
      {
        ...params,
        type: "Profesional"
      })
      .subscribe(
        {
          next: ({ data }) => {
            this.loading = false
            if (data) {
              this.dataRowProf = data.map((el: any) => {
                return {
                  ...el,
                  points: this.itsApproved(el.inscriptions) ? el.point_earned : 0
                }
              })
              console.log(this.dataRowProf)
            }
          },
          error: (err) => {
            this.loading = false
          }
        }
      )

    this._rest = this.restAPI.getAPI('courses',
      {
        ...params,
        type: "Personal"
      })
      .subscribe(
        {
          next: ({ data }) => {
            this.loading = false
            if (data) {
              this.dataRowPersonal = data.map((el: any) => {
                return {
                  ...el,
                  points: this.itsApproved(el.inscriptions) ? el.point_earned : 0
                }
              })
            }

          },
          error: (err) => {
            this.loading = false
          }
        }
      )
  }

  ngOnInit(): void {

    this._matriculado = this.store.select(selectMatriculado).subscribe(
      mat => {
        this.matriculado = mat
        if (this.matriculado != undefined && this.first) {
          this.getCoursesProps(new Date().getFullYear())
          this.first = false
        }
      }
    )
  }

  ngOnDestroy(): void {
    this._matriculado?.unsubscribe()
    this._rest?.unsubscribe()
    this._rest2?.unsubscribe()
  }
}
