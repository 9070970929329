import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnyFn } from '@ngrx/store/src/selector';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/store/appReducer';
import { selectMatriculado } from 'src/app/store/selectors/matriculados.selector';

@Component({
  selector: 'app-info-matriculados',
  templateUrl: './info-matriculados.component.html'
})
export class InfoMatriculadosComponent implements OnInit, OnDestroy {
  _matriculado!: Subscription
  matriculado: any
  constructor(
    private store: Store<AppState>
  ) { }



  ngOnInit(): void {
    this._matriculado = this.store.select(selectMatriculado)
      .subscribe(
        res => {
          if (res) {
            this.matriculado = {
              ...res,
              fullname: res.name + ' ' + res.last_name
            }
          }
        }
      )
  }
  ngOnDestroy(): void {
    this._matriculado?.unsubscribe()
  }
}
