import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TableI } from 'src/app/atoms/table/table.interface';
import { CertificacionesModelMatriculado } from 'src/app/core/models/Certificaciones.Model';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { getYearsUntilNow } from 'src/app/core/utils/functions';
import { showExamenDialogFile } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectMatriculado } from 'src/app/store/selectors/matriculados.selector';

@Component({
  selector: 'app-certificaciones-matriculados',
  templateUrl: './certificaciones-matriculados.component.html'
})
export class CertificacionesMatriculadosComponent implements OnInit, OnDestroy {
  columns: TableI[] = [
    {
      label: 'Denominación',
      key: 'title',
      type: "string"
    },
    {
      label: 'Fecha',
      key: 'created_at',
      type: "date"
    },
    {
      label: 'Estado',
      key: 'status',
      type: "status"
    },
    {
      label: 'Puntos obtenidos',
      key: 'points',
      type: "string"
    },
    {
      label: 'Acciones',
      key: '',
      type: 'action',
      action: [
        {
          action: (event: any,row:any) => { 
            this.store.dispatch(showExamenDialogFile({ showExamenFile: { open: true, file: row.file } }))
           },
          svg: 'assets/icons/table/eye.svg',
          svgHover: 'assets/icons/table/eyeHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        }

      ]
    }
  ]
  _matriculado!: Subscription
  matriculado: any
  certifications!: any
  _rest!:Subscription
  actualYear: number = new Date().getFullYear();
  years = getYearsUntilNow(2023)
  loading = true

  dataRow!:Array<any>

  constructor(
    private store: Store<AppState>,
    private restAPI: ApiRestService
  ) { }

  

  changeYear(year:number){
    this.actualYear = year
    this.getCoursesProps(year)
    this.loading = true
  }

  getCoursesProps(year: any) {

    const params = {
      start_date: `${year}-01-01`,
      end_date: `${year}-12-31`,
      student_id: this.matriculado.id
    }


    this._rest = this.restAPI.getAPI('certifications',params)
    .subscribe(
      {
        next:({data})=>{
          this.loading = false
          this.certifications = data.certifications.data
          this.dataRow = this.certifications.map((el:any)=>{
            return {
              ...el,
              status:
               el.approved == null ? 'pendiente' : 
               el.approved == 1 ? 'aprobado' :
               'rechazado',
               points : el.grant ? el.grant : 0,
               
            }
          })
        },
        error:(err)=>{
          this.loading = false
        }
      }
    )
  }


  ngOnInit(): void {
    this._matriculado = this.store.select(selectMatriculado).subscribe(
      mat => {
        this.matriculado = mat
        if(this.matriculado){
          this.getCoursesProps(new Date().getFullYear())
        }
      }
    )
    
  }
  ngOnDestroy(): void {
    this._matriculado?.unsubscribe()
    this._rest?.unsubscribe()
  }
}
