import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AOS from 'aos';
import { AppState } from './store/appReducer';
import { selectIsLogged } from './store/selectors/user.selector';
import { Observable, tap } from 'rxjs';
import { actionAuthMe, authAction } from './store/actions/user.actions';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'COPA';
  isLogged$!: Observable<any>

  logged: any
  constructor(
    private store: Store<AppState>,
    private config: PrimeNGConfig
  ) { }

  
  ngOnInit(): void {
    this.isLogged$ = this.store.select(selectIsLogged)
    AOS.init();
    this.config.setTranslation({
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      monthNames: [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ],
      monthNamesShort: [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
      ]
    });
    

    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      this.store.dispatch(actionAuthMe({ token: JSON.parse(accessToken) }))
    } else {
      // No token, the user is not logged in.
    }

  }
  ngOnDestroy(): void {

  }
}
