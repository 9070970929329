import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PaginatorState } from 'primeng/paginator';
import { Observable, Subscription } from 'rxjs';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { DropdownI } from 'src/app/core/models/Dropdown.interface';
import { actionChangePage, actionLoadingCapacitaciones, capacitacionView, loadingListActive } from 'src/app/store/actions/capacitaciones.actions';
import { capacitacionesActive, capacitacionesTabs } from 'src/app/store/actions/ui.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectCapacitacionesList, selectLoadingCapacitaciones, selectRowsCapacitaciones } from 'src/app/store/selectors/capacitaciones.selector';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';


export interface PageEvent {
  first: any | undefined;
  rows: any | undefined;
  page: any | undefined;
  pageCount: any | undefined;
}

@Component({
  selector: 'app-capacitaciones',
  templateUrl: './capacitaciones.component.html'
})
export class CapacitacionesComponent implements OnInit, OnDestroy {

  tabActive?: string = 'Activas'
  tabSub$!: Subscription
  rowsSub$!: Subscription
  _capacitaciones!: Subscription
  _loading!: Subscription
  loading: boolean = true
  capacitaciones$!: Observable<CourseI[] | undefined>
  buttonTabs!: Array<{ label: string, value: string }>
  selectUser$!: Subscription
  options: Array<DropdownI> = [
    {
      title: "Nueva capacitacion",
      action: () => { this.router.navigate(['capacitacion/nueva']) }
    },
    {
      title: "Capacitacion externa",
      action: () => { this.router.navigate(['capacitacion/nueva-soft']) }
    },
  ]

  user$?: Observable<any>
  user: any

  constructor(private store: Store<AppState>, private router: Router) { }

  obtenerUser() {
    this.user$ = this.store.select(selectUserAuth)
  }
  changeActive(active: string) {
    this.store.dispatch(actionLoadingCapacitaciones({ loading: true }))
    this.store.dispatch(capacitacionesActive({ activeCapt: active }))
  }

  first: number = 0;

  rows?: any

  onPageChange(event: PaginatorState) {
    this.store.dispatch(actionChangePage({ page: event.page ? event.page + 1 : 1 }))
    if (event.first) {
      this.first = event.first;
      // this.store.dispatch(actionChangePage({ page: event.first  }))
    }
  }

  dispatchCard(event: any) {
    this.store.dispatch(capacitacionView({ capacitacion: event }))
    this.router.navigate(['capacitaciones', event.id])
  }

  cuposDisponibles(capacitacion: CourseI) {

    let cuposTotales = 0
    if (capacitacion.current_class) {
      cuposTotales = (+capacitacion.current_class?.virtual_cupo) + (+capacitacion.current_class?.in_person_cupo)
    }
    let cuposDisponibles = (+cuposTotales) - (+capacitacion.inscriptions_count)
    return cuposDisponibles
  }

  ngOnInit(): void {

    this.store.dispatch(actionLoadingCapacitaciones({ loading: true }))
    this.user$ = this.store.select(selectUserAuth)

    this.selectUser$ = this.store.select(selectUserAuth).subscribe(user => {
      this.user = user
      if (user)
        if (user?.roles[0]?.name == 'administrador general') {
          this.store.dispatch(capacitacionesActive({ activeCapt: 'Activas' }))
          this.store.dispatch(capacitacionesTabs({
            capacitacionesTabs: [
              {
                label:"Activas",
                value: "Activas"
              },
              {
                label:"Anteriores",
                value: "Anteriores"
              },
              {
                label:"Finalizadas",
                value: "Finalizadas"
              },
              {
                label:"Inactivas",
                value: "Inactivas"
              },
            ]
          }))
        } else {
          this.store.dispatch(capacitacionesActive({ activeCapt: 'Disponibles' }))
          this.store.dispatch(capacitacionesTabs({
            capacitacionesTabs: [
              {
                label:"Disponibles",
                value: "Disponibles"
              },
              {
                label:"Finalizadas",
                value: "Anteriores"
              },
              {
                label:"Profesionales",
                value: "Profesionales"
              },
              {
                label:"Personales",
                value: "Personales"
              },
            ]
          }))

        }
    })

    this.rowsSub$ = this.store.select(selectRowsCapacitaciones).subscribe(
      (rows) => {
        this.rows = rows
      }
    )

    this.capacitaciones$ = this.store.select(selectCapacitacionesList)
    this._capacitaciones = this.capacitaciones$.subscribe(list => {
      if (!list) this.store.dispatch(capacitacionesActive({ activeCapt: 'Activas' }))
    })
    this.tabSub$ = this.store.select('ui').subscribe(ui => {
      this.buttonTabs = ui.capacitacionesTabs
      this.tabActive = ui.activeCapt
    })

    this._loading = this.store.select(selectLoadingCapacitaciones)
      .subscribe(res => this.loading = res)

  }

  ngOnDestroy(): void {
    this.tabSub$?.unsubscribe()
    this.selectUser$?.unsubscribe()
    this.rowsSub$?.unsubscribe()
    this._capacitaciones?.unsubscribe()
    this._loading?.unsubscribe()
  }
}
