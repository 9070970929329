<p-dialog
  [closable]="false"
  [visible]="open"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <div
    class="absolute top-0 border-2 rounded w-[30px] h-[30px] right-2 top-2 cursor-pointer border-primary text-primary flex items-center justify-center"
    (click)="closeDialog()"
  >
    X
  </div>

  <ng-container *ngIf="open">
    <div class="w-[600px]">
      <iframe
        *ngIf="url"
        [src]="url"
        width="100%"
        height="700px"
        frameborder="0"
      ></iframe>
    </div>
    <p-dialog
      [closable]="false"
      [visible]="end"
      [modal]="true"
      [draggable]="false"
      [resizable]="false"
    >
      <div class="flex justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
        >
          <path
            d="M75.8552 14.3995C75.7771 14.4767 75.7037 14.5586 75.6354 14.6445L37.2735 62.9752L14.1448 40.1075C10.909 36.9082 5.66268 36.9082 2.42686 40.1075C-0.808953 43.3068 -0.808955 48.4939 2.42686 51.6932L31.6642 80.6005C34.9 83.7998 40.1463 83.7998 43.3821 80.6005C43.4542 80.5293 43.5222 80.4542 43.5858 80.3755L87.6907 25.8665C90.8085 22.6589 90.7693 17.5595 87.5731 14.3995C84.3373 11.2002 79.091 11.2002 75.8552 14.3995Z"
            fill="#84DB92"
          />
        </svg>
      </div>
      <div class="py-4 text-center">
        Se ha aprobado el certificado exitosamente
      </div>
    </p-dialog>
  </ng-container>
</p-dialog>
