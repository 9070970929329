import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutsModule } from './layouts/layouts.module';
import { MatriculadosModule } from './pages/matriculados/matriculados.module';
import { PagesModule } from './pages/home/pages.module';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from './store/appReducer';
import { ClickOutsideDirective } from './core/directives/click-outside.directive';
import { PrimeNGConfig } from 'primeng/api';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { EffectsArray } from './store/effects';
import PaymentInfoComponent from './atoms/payment-info/payment-info.component';
import { AtomsModule } from './atoms/atoms.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DialogsModule } from './dialogs/dialogs.module';


const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
  // ......
  primeConfig.ripple = true;
};

@NgModule({
  declarations: [
    AppComponent,
    ClickOutsideDirective,
    PaymentInfoComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutsModule,
    PagesModule,
    MatriculadosModule,
    HttpClientModule,
    AtomsModule,
    DialogsModule,
    //NGRX
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(EffectsArray),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [PrimeNGConfig],
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
