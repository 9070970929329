import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { DeleteDialogI, dialogDeleteAction } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectDialogDeleteForm } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-delete-dialogs',
  templateUrl: './delete-dialogs.component.html'
})
export class DeleteDialogsComponent implements OnInit, OnDestroy{
  stateDialog!:DeleteDialogI
  _stateDialog!:Subscription
  _restApi!:Subscription
  end=false
  loading = false
  constructor(
    private store:Store<AppState>,
    private restAPI: ApiRestService,
    private messageService: MessageService
  ){}

  acceptConfirm(){
    this.loading = true
    this._restApi = this.restAPI.deleteApi
    (this.stateDialog?.url ? this.stateDialog.url : '', this.stateDialog.params ? this.stateDialog.params : null).subscribe(
      {
        next:(res)=>{
          this.end=true
          this.loading = false
          if(this.stateDialog.action){
            this.stateDialog.action()
          }
          this.messageService.add({ severity: 'info', summary: 'Eliminado exitosamente', detail: '' });
        },
        error: (err)=>{
          this.loading = false
          this.messageService.add({ severity: 'error', summary: 'Un error ha ocurrido intenta nuevamente', detail: '' });
        }
      }
    )
  }

  closeDialog(){
    this.store.dispatch(dialogDeleteAction({deleteDialog:{
      open:false
    }}))
    this.end = false
  }
  ngOnInit(): void {
      this._stateDialog = this.store.select(selectDialogDeleteForm)
      .subscribe(
        {
          next:(res)=>{
            this.stateDialog = res
          }
        }
      )
  }
  ngOnDestroy(): void {
      this._stateDialog?.unsubscribe()
  }
}
