import { createReducer, on } from "@ngrx/store";
import { capacitacionesActive, capacitacionesTabs, changeBreadCrumb, changeNavTabs, changeView, isLoading } from '../actions/ui.actions';

export interface StateUI {
    viewScreen?: string
    navTabs?: Array<any>
    capacitacionesTabs: Array<{ label: string, value: string }>
    activeCapt: string
    isLoading: boolean
    breadCrumb: Array<{ name: string, url?: string }>
}

const initialState: StateUI = {
    capacitacionesTabs: [],
    activeCapt: 'Activas',
    isLoading: false,
    breadCrumb: [],
}

export const uiReducer = createReducer(
    initialState,
    on(changeView, (state, { viewScreen }) => ({ ...state, viewScreen })),
    on(changeNavTabs, (state, { navTabs }) => ({ ...state, navTabs })),
    on(changeBreadCrumb, (state, { breadCrumb }) => ({ ...state, breadCrumb })),
    on(capacitacionesTabs, (state, { capacitacionesTabs }) => ({ ...state, capacitacionesTabs })),
    on(capacitacionesActive, (state, { activeCapt }) => ({ ...state, activeCapt })),
    on(isLoading, (state, { isLoading }) => ({ ...state, isLoading })),
);
