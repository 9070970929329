import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, tap } from 'rxjs/operators';

import { MatriculadosService } from 'src/app/core/services/matriculados.service';
import { getMatriculado, getMatriculados, loadingMatriculado, matriculadosAction, paginationMatriculados } from '../actions/matriculados.action';
import { ApiRestService } from 'src/app/core/services/api-rest.service';

@Injectable()

export class MatriculadosEffect {

    constructor(
        private matServ: MatriculadosService,
        private restSvc: ApiRestService,
        private actions$: Actions
    ) { }

    matriculados$ = createEffect(
        () => this.actions$.pipe(
            ofType(getMatriculados),
            mergeMap(() =>
                this.restSvc.getAPI('users', null).pipe(
                    mergeMap((mat) => [
                        matriculadosAction({ matriculados: mat })
                    ])
                ))
        )
    )


    matriculado$ = createEffect(
        () => this.actions$.pipe(
            ofType(loadingMatriculado),
            mergeMap(({ id }) =>
                this.restSvc.getAPI(`users/${id}`).pipe(
                   mergeMap((matriculado)=>[
                    getMatriculado({matriculado})
                   ])
                )
            )
        )
    )

    paginaionMatriculados$ = createEffect(
        ()=>this.actions$.pipe(
            ofType(paginationMatriculados),
            mergeMap(({props})=>
                this.restSvc.getAPI('users',props).pipe(
                    mergeMap((res)=>[
                        matriculadosAction({matriculados:res})
                    ])
                )
            )
        )
    )

}