import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  transform(value: number): string {
    if (value === 1) {
      return '1er';
    } else if (value === 2) {
      return '2da';
    } else if (value === 3) {
      return '3er';
    } else {
      return `${value}ta`;
    }
  }

}
