import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../appReducer';

export const selectCapacitaciones = (state: AppState) => state.capacitaciones;


export const selectCapacitacion = createSelector(
  selectCapacitaciones,
  (state) => state.capacitacion
);

export const selectCapacitacionesList = createSelector(
  selectCapacitaciones,
  (state) => state.capacitaciones
);

export const selectRowsCapacitaciones = createSelector(
  selectCapacitaciones,
  (state) => state.paginator
)

export const selectNavbarCapacitaciones = createSelector(
  selectCapacitaciones,
  (state) => state.navbarCapa
)

export const selectLoadingCapacitaciones = createSelector(
  selectCapacitaciones,
  (state) => state.loading
)

export const selectMisCapacitaciones = createSelector(
  selectCapacitaciones,
  (state) => state.misCapacitaciones
)


export const selectPayment = createSelector(
  selectCapacitaciones,
  (state) => state.payment
)

export const selectParamsCapa = createSelector(
  selectCapacitaciones,
  (state)=> state.params
)