import { createReducer, on } from "@ngrx/store";
import { changeForm, changeFormSoft } from "../actions/fornCapacitacion.action";
import { CourseFormData } from "src/app/core/models/FormCapacitacion.Interface";


export interface StateForm {
    form?: CourseFormData
    formSoft?: Partial<CourseFormData>
}

const initialState: StateForm = {
    form: {
        payment: [
            {
                free: '1'
            }
        ]
    },
    formSoft: {

    }
}

export const formCaptacionReducer = createReducer(
    initialState,
    on(changeForm, (state, { form }) => ({ ...state, form })),
    on(changeFormSoft, (state, { formSoft }) => ({...state, formSoft}))
)