import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  MatriculadosContainerComponent } from './matriculados.component';
import { InfoMatriculadosComponent } from './info-matriculados/info-matriculados.component';
import { CapacitacionesMatriculadosComponent } from './capacitaciones-matriculados/capacitaciones-matriculados.component';
import { CertificacionesMatriculadosComponent } from './certificaciones-matriculados/certificaciones-matriculados.component';
import { MAtriculadosRoutingModule } from './matriculados-routing.module';
import { AtomsModule } from 'src/app/atoms/atoms.module';



@NgModule({
  declarations: [
    MatriculadosContainerComponent,
    InfoMatriculadosComponent,
    CapacitacionesMatriculadosComponent,
    CertificacionesMatriculadosComponent
  ],
  imports: [
    CommonModule,
    MAtriculadosRoutingModule,
    AtomsModule
  ],
  exports:[
    MatriculadosContainerComponent
  ]
})
export class MatriculadosModule { }
