import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardGuard } from './core/guards/auth-guard.guard';
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { adminGuard } from './core/guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home//pages.module').then(
        (m) => m.PagesModule
      ),
    canActivate: [authGuardGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth//auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: 'capacitaciones',
    loadChildren: () =>
      import('./pages/capacitaciones//capacitaciones.module').then(
        (m) => m.CapacitacionesModule
      ),
      canActivate: [authGuardGuard]
  },
  {
    path: 'matriculados',
    loadChildren: () =>
      import('./pages/matriculados//matriculados.module').then(
        (m) => m.MatriculadosModule
      ),
       canActivate: [authGuardGuard]
  },
  {
    path: 'configuracion',
    loadChildren: () =>
      import('./pages/configuration//configuration.module').then(
        (m) => m.ConfigurationModule
      ),
       canActivate: [authGuardGuard, adminGuard]
  },
  {
    path: 'capacitacion',
    loadChildren: () =>
      import('./pages/capacitacion//capacitacion.module').then(
        (m) => m.CapacitacionModule
      ),
       canActivate: [authGuardGuard, adminGuard]
  },
  {
    path: '**',
    redirectTo: '/', // Replace 'your-default-path' with the path you want to redirect to.
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
