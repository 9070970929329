import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { firmaGlobalFile, showAdjuntarComprobante, showCertificacionFile, showFirmFile } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';

import { Subscription } from 'rxjs'
import { selectDialogAdjuntar, selectDialogCertificacionFile, selectDialogFirmFile, selectDialogFirmaGloabalFile } from 'src/app/store/selectors/dialog.selector';
import { MessageService } from 'primeng/api';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';

@Component({
  selector: 'app-firma-file',
  templateUrl: './firma-global.component.html',
  providers: [MessageService]
})
export class FirmaGlobalComponent implements OnInit, OnDestroy {
  open: boolean = false
  end: boolean = false
  loading: boolean = false
  _open!: Subscription
  _subscriptionRest!: Subscription
  _subcriptionCapacitacion!: Subscription
  capacitacion!: CourseI | undefined
  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
    private restApi: ApiRestService
  ) { }


  uploadedFiles: any[] = [];
  show = true

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.show = false
    let formData = new FormData()
    formData.append('title', this.uploadedFiles[0].name)
    formData.append('file', this.uploadedFiles[0])
    this.loading = true
    this._subscriptionRest = this.restApi.postApi('certifications', formData).subscribe(
      res => {
        if (res) {
          this.end = true
          
          setTimeout(() => {
            this.closeDialog()
          }, 900)
        }
      }
    )

    this.messageService.add({ severity: 'info', summary: 'Archivo cargado', detail: '' });
  }

  closeDialog() {
    this.store.dispatch(firmaGlobalFile({ firmaGlobalFile: false }))
    this.end = false
    this.loading = false
  }

  ngOnInit(): void {
    this._open = this.store.select(selectDialogFirmaGloabalFile)
      .subscribe(
        res => this.open = res
      )
  }

  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._subcriptionCapacitacion?.unsubscribe()
    this._subscriptionRest?.unsubscribe()
  }
}
