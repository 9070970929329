import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownI } from 'src/app/core/models/Dropdown.interface';
import { AppState } from 'src/app/store/appReducer';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent {
  public open = false

  constructor(private store: Store<AppState>) {
  }

  @Input() options?: Array<DropdownI>

  @Input() imgUrl?: string
  @Input() title?: string

  @Output() action: EventEmitter<any> = new EventEmitter

  accionGlobal(action: any) {
    this.action.emit(action)
    action()
    this.openMenu()
  }



  openMenu() {
    this.open = !this.open

  }
}


