<article class="flex gap-3 flex-wrap text-primary items-center">
    <svg class="cursor-pointer" routerLink="/" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 15" fill="none">
        <path
            d="M7.35355 0.646447C7.15829 0.451184 6.84171 0.451184 6.64645 0.646447L0.646447 6.64645C0.552678 6.74021 0.5 6.86739 0.5 7V14C0.5 14.2761 0.723858 14.5 1 14.5H5.5C5.77614 14.5 6 14.2761 6 14V10H8V14C8 14.2761 8.22386 14.5 8.5 14.5H13C13.2761 14.5 13.5 14.2761 13.5 14V7C13.5 6.86739 13.4473 6.74021 13.3536 6.64645L12 5.29289V2C12 1.72386 11.7761 1.5 11.5 1.5H10.5C10.2239 1.5 10 1.72386 10 2V3.29289L7.35355 0.646447ZM1.5 13.5V7.20711L7 1.70711L12.5 7.20711V13.5H9V9.5C9 9.22386 8.77614 9 8.5 9H5.5C5.22386 9 5 9.22386 5 9.5V13.5H1.5Z"
            fill="#C3363A" />
    </svg>
    <div class="flex gap-3 flex-wrap items-center" *ngFor="let opt of list, let last = last, let first = first">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.77638 2.0529C7.02337 1.9294 7.32371 2.02951 7.4472 2.2765L10.4472 8.2765C10.5176 8.41727 10.5176 8.58295 10.4472 8.72372L7.4472 14.7237C7.32371 14.9707 7.02337 15.0708 6.77638 14.9473C6.52939 14.8238 6.42928 14.5235 6.55277 14.2765L9.44097 8.50011L6.55277 2.72372C6.42928 2.47673 6.52939 2.17639 6.77638 2.0529Z"
                fill="#37405B" />
        </svg>

        <p *ngIf="!opt.url" class="font-bold uppercase text-[18 px]" [ngClass]="last && !first ? 'text-[17px]' : ''">{{opt.name}}</p>
        <a *ngIf="opt.url" [routerLink]="opt.url" class="font-bold uppercase text-[18 px]" [ngClass]="last && !first ? 'text-[17px]' : ''">{{opt.name}}  </a>
    </div>
</article>