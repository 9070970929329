import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { State, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { TableI } from 'src/app/atoms/table/table.interface';
import { UserI } from 'src/app/core/models/UserI.interface';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { showCertificacionConfirm, showCertificacionFile, viewCertificacionFile } from 'src/app/store/actions/dialog.actions';
import { capacitacionesActive } from 'src/app/store/actions/ui.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectActiveTab } from 'src/app/store/selectors/ui.selector';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';

@Component({
  selector: 'app-certificaciones',
  templateUrl: './certificaciones.component.html'
})
export class CertificacionesComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private service: ApiRestService,
    private messageService: MessageService
  ) { }

  config_puntos: any
  config_certificacion: any
  loading: boolean = false
  load: boolean = true
  _subscriptionRest!: Subscription
  _user!: Subscription
  isCurrentDateInRange(start_Date: string, end_Date: string): boolean {
    const start = new Date(start_Date);
    const end = new Date(end_Date);
    const today = new Date();

    return today >= start && today <= end;
  }

  users$!: Observable<any>
  userData!: UserI
  data!: any
  certificaciones_pendientes: any
  columns: TableI[] = [
    {
      label: 'Denominación',
      key: 'name',
      type: "string"
    },
    {
      label: 'Profesional',
      key: 'matriculado_name',
      type: "string"
    },
    {
      label: 'Acciones',
      key: '',
      type: 'action',
      action: [
        {
          action: (event: any, row: any) => {
            this.store.dispatch(viewCertificacionFile({ viewCertificacionFile: { state: true, data: row } }))
          },
          svg: 'assets/icons/table/eye.svg',
          svgHover: 'assets/icons/table/eyeHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        },
        {
          action: (event: any, row: any) => {
            this.printImage(row.file)
          },
          svg: 'assets/icons/table/print.svg',
          svgHover: 'assets/icons/table/printHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        },
        {
          action: (ev: any, row: any) => {
            this.store.dispatch(showCertificacionConfirm({ showCertificacionConfirm: { state: true, data: row, action: () => { this.getCertifications() } } }))
          },
          svg: 'assets/icons/table/ok.svg',
          svgHover: 'assets/icons/table/okHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        },
      ]
    }
  ]
  active = 'Pendientes de aprobación'
  active$!: Observable<any>
  matriculadoTab = [
    {
      name: 'Pendientes de aprobación',
    },
    {
      name: 'Aprobadas',
    },
    {
      name: 'Rechazadas',
    },

  ]
  certificacionesMatriculado: any = null

  /*
  [
    {
      name: 'Certificacion 1.jpg',
      date: '02/04/2023',
      points: '10'
    },
    {
      name: 'Certificacion 2.jpg',
      date: '05/04/2023',
      points: '20'
    },
    {
      name: 'Certificacion 3.jpg',
      date: '02/04/2023',
      points: '10'
    }
  ]
  */

  printImage(imageOrPdfUrl: string) {
    const printWindow = window.open(imageOrPdfUrl, '_blank');

    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      console.error('Failed to open print window.');
    }
  }

  prueba() {
  }

  cargarImagen($event: any) {
    this.store.dispatch(showCertificacionFile({
      showCertificacionFile: { state: true, action: () => { this.getPendigCertifications() } }
    }))
    //this.uploadFirmaImg.nativeElement.click()
  }

  changeActive(active: string) {
    this.certificacionesMatriculado = null;
    this.store.dispatch(capacitacionesActive({ activeCapt: active }))

    switch (active) {
      case 'Aprobadas': {
        /*
        Pendientes de parobacion
        */
        let params = {
          approved: 1
        }
        this.service.getAPI("certifications", params).subscribe(res => {

          this.loading = false
          this.certificacionesMatriculado = res.data.map((c: any, index: any) => {
            return { ...c, name: c.file.split('/')[6] }
          })
        }, (err) => {
          //#Error
          if (typeof err.error.msg !== "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg[key]
                }
              );
            })
          }

          if (typeof err.error.msg == "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              return this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg
                }
              );
            })
          }

          this.loading = false
        }
        )
      } break;
      case 'Pendientes de aprobación': {
        /*
        Pendientes de parobacion
        */
        let params = {
          pending: 1
        }
        this.service.getAPI("certifications", params).subscribe(res => {
          this.loading = false
          this.certificacionesMatriculado = res.data.map((c: any, index: any) => {
            return { ...c, name: c.file.split('/')[6] }
          })
        }, (err) => {
          //#Error
          if (typeof err.error.msg !== "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg[key]
                }
              );
            })
          }

          if (typeof err.error.msg == "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              return this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg
                }
              );
            })
          }

          this.loading = false
        }
        )
      } break;
      case 'Rechazadas': {
        /*
        Pendientes de parobacion
        */
        let params = {
          approved: 0
        }
        this.service.getAPI("certifications", params).subscribe(res => {
          this.loading = false
          this.certificacionesMatriculado = res.data.map((c: any, index: any) => {
            return { ...c, name: c.file.split('/')[6] }
          })
        }, (err) => {
          //#Error
          if (typeof err.error.msg !== "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg[key]
                }
              );
            })
          }

          if (typeof err.error.msg == "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              return this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg
                }
              );
            })
          }

          this.loading = false
        }
        )
      } break;
    }

  }

  getPendigCertifications() {
    this.store.dispatch(capacitacionesActive({ activeCapt: 'Pendientes de aprobación' }));

    let params = {
      pending: 1
    }
    this.service.getAPI("certifications", params).subscribe(res => {
      this.loading = false
      this.certificacionesMatriculado = res.data.map((c: any, index: any) => {
        return { ...c, name: c.file.split('/')[6] }
      })
    }, (err) => {
      //#Error
      if (typeof err.error.msg !== "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg[key]
            }
          );
        })
      }

      if (typeof err.error.msg == "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          return this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg
            }
          );
        })
      }

      this.loading = false
    }
    )
  }

  getCertifications() {
    let params = {
      pending: 1
    }
    this._subscriptionRest = this.service.getAPI("certifications", params).subscribe(res => {
      this.load = false

      this.data = res.data.certifications.data.map((c: any, index: any) => {
        return { ...c, name: c.file.split('/')[6], matriculado_name: c.student.name + ' ' + c.student.last_name }
      })
      this.certificaciones_pendientes = res.data.certifications_pending
      this.loading = false
    }, (err) => {
      //#Error
      if (typeof err.error.msg !== "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg[key]
            }
          );
        })
      }

      if (typeof err.error.msg == "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          return this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg
            }
          );
        })
      }

      this.loading = false
    }
    )
  }

  ngOnInit(): void {
    this.store.dispatch(capacitacionesActive({ activeCapt: 'Pendientes de aprobación' }))
    //this.subscription$ = this.store.select('certificaciones').subscribe(res => {
    //  this.data = res.certificaciones
    //})
    this.active$ = this.store.select(selectActiveTab)
    this.users$ = this.store.select(selectUserAuth)

    this._user = this.users$.subscribe(user => {
      this.userData = user
      if (user.roles[0].name !== "administrador general") {
        /* 
            Configuracion de certificados
            */
        this.service.getAPI("setting/certification", null).subscribe(res => {

          this.config_certificacion = res
          this.loading = false
          this.load = false
        }, (err) => {
          //#Error
          if (typeof err.error.msg !== "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg[key]
                }
              );
            })
          }

          if (typeof err.error.msg == "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              return this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg
                }
              );
            })
          }

          this.loading = false
        }
        )
        /*
        Configuracion de puntos
        */
        this.service.getAPI("setting/point", null).subscribe(res => {
          this.config_puntos = res
          this.loading = false
        }, (err) => {
          //#Error
          if (typeof err.error.msg !== "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg[key]
                }
              );
            })
          }

          if (typeof err.error.msg == "string") {
            Object.keys(err.error.msg).map((key: any, index: any) => {
              return this.messageService.add(
                {
                  severity: 'error',

                  summary: 'Un error ha ocurrido',
                  detail: err.error.msg
                }
              );
            })
          }

          this.loading = false
        }
        )

        this.getPendigCertifications()
      }

      if (user.roles[0].name == "administrador general") {
        this.getCertifications()
      }
    })

  }

  ngOnDestroy(): void {
    this._subscriptionRest?.unsubscribe()
    this._user?.unsubscribe()
  }
}
