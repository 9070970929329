<div (click)="click(data)" pRipple
    class="w-full styled-box-green  h-full shadow-xl cursor-pointer hover:border-secondary border-transparent border-[3px] transition ease-in-out duration-400">
    <img class="w-full h-[250px] object-cover bg-primary-100"
        [src]=" (img=='' || img == undefined) ? 'assets/image/card-img.png' : img" alt="">
    <div class="flex flex-col p-5 gap-y-[80px] w-full">
        <h3 class="text-2xl text-primary font-[500]">{{title}} </h3>
        <div class="flex flex-col text-primary-100 gap-y-4 ">

            <p *ngIf="status"  class="flex gap-4 items-center font-light uppercase" [ngClass]="{
                    'text-green': status === 'Aprobado',
                    'text-primary-100': status === 'Pendiente',
                    'text-secondarry-100': status === 'Rechazado'
                }">
                <span>
                    <img [src]="status === 'Aprobado' ? 'assets/icons/success.png' :
              status === 'Pendiente' ? 'assets/icons/pending.png' :
              'assets/icons/reject.png'" alt="Status" />
                </span>
                {{status}}
            </p>


            <p *ngIf="rendirDistancia" class="flex gap-4 items-center font-light text-secondary">
                <span><img class="w-5 h-5" src="assets/icons/journal-text.svg" alt=""></span>
                Disponible "Rendir a distancia"
            </p>
            <p *ngIf="grabacion" class="flex gap-4 items-center font-light text-secondary">
                <span><img class="h-5 w-5" src="assets/icons/camara.svg" alt=""></span>
                Grabación disponible
            </p>
            <p class="flex gap-4 items-center font-light">
                <span><img class="w-5 h-5" src="assets/icons/calendar.svg" alt=""></span>
                {{dateType | date : 'dd-MM-yyyy' : 'UTC' }} a las {{ time?.slice(0, 5) }}
            </p>
            <p *ngIf="showInscriptos" class="flex gap-4 items-center font-light">
                <span><img class="w-5 h-5" src="assets/icons/people-fill.svg" alt=""></span>
                Inscriptos: {{inscriptos}}
            </p>
            <p *ngIf="asistentes" class="flex gap-4 items-center font-light">
                <span><img class="w-5 h-5" src="assets/icons/people-fill.svg" alt=""></span>
                {{asistentes}} {{asistentes == 1? 'asistente' : 'asistentes'}}
            </p>

            <p *ngIf="cuposDisponibles" class="flex gap-4 items-center font-light">
                <span><img class="w-5 h-5" src="assets/icons/people-fill.svg" alt=""></span>
                Cupos Disponibles: {{cuposDisponibles}}
            </p>

            <p *ngIf="type" class="flex gap-4 items-center font-light">
                <span><img class="w-5 h-5" src="assets/icons/award.svg" alt=""></span>
                {{type}} - {{puntos ? puntos : '0'}} puntos
            </p>
            
            <p *ngIf="payment == 0 || payment == 1" class="flex gap-4 items-center font-light">
                <span>
                    <i class="w-5 h-5 pi pi-money-bill text-secondary"></i>
                </span>
                <span>
                    Curso {{ payment == 1 ? 'Gratuito' : 'Pago'}}
                </span>
            </p>
        </div>
    </div>
</div>