import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { showAdjuntarComprobante } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';

import { Subscription } from 'rxjs'
import { selectDialogAdjuntar } from 'src/app/store/selectors/dialog.selector';
import { MessageService } from 'primeng/api';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { capacitacionViewId } from 'src/app/store/actions/capacitaciones.actions';

@Component({
  selector: 'app-pay-file',
  templateUrl: './pay-file.component.html',
  providers: [MessageService]
})
export class PayFileComponent implements OnInit, OnDestroy {
  open: boolean = false
  end: boolean = false
  loading:boolean = false
  _open!: Subscription
  _subscriptionRest!: Subscription
  _subcriptionCapacitacion!: Subscription
  capacitacion!: CourseI | undefined
  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
    private restApi: ApiRestService

  ) { }

  uploadedFiles: any[] = [];
  show = true


  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.show = false
    if (this.capacitacion && this.open) {
      let formData = new FormData()
      formData.append('payment_id', this.capacitacion.payment.id.toString())
      formData.append('file', this.uploadedFiles[0])
      for (const entry of (formData as any).entries()) {
      }
      this.loading = true
      this._subscriptionRest = this.restApi.postApi('payments/user', formData).subscribe(
        res => {
          if(res){
            this.end = true
            if(this.capacitacion)
            this.store.dispatch(capacitacionViewId({id:this.capacitacion?.id}))
            setTimeout(()=>{
              this.closeDialog()
            },900)
          }
        }
      )
    }

    this.messageService.add({ severity: 'info', summary: 'Archivo cargado', detail: '' });
  }


  closeDialog() {
    this.store.dispatch(showAdjuntarComprobante({ showComprobantePago: false }))
    this.end = false
    this.loading = false
  }
  ngOnInit(): void {
    this._open = this.store.select(selectDialogAdjuntar)
      .subscribe(
        res => this.open = res
      )

    this._subcriptionCapacitacion = this.store.select(selectCapacitacion).subscribe
      (capacitacion => this.capacitacion = capacitacion)
  }
  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._subcriptionCapacitacion?.unsubscribe()
    this._subscriptionRest?.unsubscribe()
  }
}
