<section>
  <div *ngIf="!load, else elseBlock" data-aos="fade-left" data-aos-duration="800">
    <article *ngIf="users$ | async as user">
      <div *ngIf="user.roles[0].name != 'administrador general'">
        <!-- <div class="py-2">
          <app-alert-copa
            [text]="
              'Sólo se podrán obtener ' +
              config_puntos.certificaciones +
              ' puntos en total por los capacitaciones personales.'
            "
          ></app-alert-copa>
        </div> -->
        <div class="py-2">
          <app-alert-copa
          *ngIf="isCurrentDateInRange(config_certificacion.start_date,config_certificacion.end_date)"
            [text]="
              'El plazo para presentaciones estará abierto desde el ' +
              config_certificacion.start_date +
              ' al ' +
              config_certificacion.end_date +
              '.'
            "
            image="assets/icons/alert.svg"
          ></app-alert-copa>
        </div>
        <div class="py-2">
          <app-alert-copa
          *ngIf="!isCurrentDateInRange(config_certificacion.start_date,config_certificacion.end_date)"
            [text]="
              'Aún no está disponible el período para presentar certificados externos. El mismo se habilitará del ' +
              config_certificacion.start_date +
              ' al ' +
              config_certificacion.end_date +
              '.'
            "
            age="assets/icons/calendarBlue.svg"
          ></app-alert-copa>
        </div>
        <div class="py-6 flex gap-4 justify-between flex-wrap">
          <div class="flex gap-4 flex-wrap">
            <app-button-tab
              *ngFor="let item of matriculadoTab"
              [active]="item.name == (active$ | async)"
              [title]="item.name"
              [value]="item.name"
              (action)="changeActive($event)"
            ></app-button-tab>
          </div>
  
          <app-button-form
          *ngIf="isCurrentDateInRange(config_certificacion.start_date,config_certificacion.end_date)"
            icon="pi-plus-circle"
            (action)="cargarImagen($event)"
            title="Nuevo certificado"
            type="file"
          ></app-button-form>
        </div>
  
        <div *ngIf="certificacionesMatriculado">
          <div
            *ngFor="let certificado of certificacionesMatriculado; let i = index"
            class="border-primary-100 border justify-between items-center gap-6 flex p-4"
            [ngClass]="
              i + 1 == certificacionesMatriculado.length
                ? 'border-b-primary'
                : 'border-b-transparent'
            "
          >
            <div class="flex w-full px-4 justify-between items-center">
              <div class="flex gap-6 items-center">
                <span
                  *ngIf="(active$ | async) == 'Pendientes de aprobación'"
                  class="w-[20px] h-[20px] rounded-[2px] border-2 border-primary border"
                ></span>
                <img
                  src="assets/icons/aprobada.svg"
                  *ngIf="(active$ | async) == 'Aprobadas'"
                  class="w-[22px] h-[22px] rounded-[2px]"
                />
                <img
                  src="assets/icons/rechazada.svg"
                  *ngIf="(active$ | async) == 'Rechazadas'"
                  class="w-[22px] h-[22px]"
                />
                <div class="flex flex-col">
                  <small class="font-light flex items-center gap-4"
                    >{{ certificado.date | date : "dd/MM/yyyy" }}
                    <span
                      class="font-light"
                      *ngIf="(active$ | async) == 'Aprobadas'"
                      >APROBADA
                    </span>
                    <span
                      class="font-light"
                      *ngIf="(active$ | async) == 'Rechazadas'"
                      >RECHAZADA
                    </span>
                  </small>
                  <p class="text-primary">{{ certificado.title }}</p>
                </div>
              </div>
              <div
                *ngIf="(active$ | async) == 'Aprobadas'"
                class="text-md text-primary pe-6"
              >
                {{ certificado.grant }} PUNTOS
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <article *ngIf="user.roles[0].name == 'administrador general'">
        <div class="pt-2 pb-8 pointer-events-none">
          <app-button-tab
           
            [active]="true"
            [title]="certificaciones_pendientes+' Certificados pendientes de corrección'"
          ></app-button-tab>
        </div>
        <div class="mt-6">
          <TableAtom [loading]="false" [columns]="columns" [rows]="data" />
        </div>
      </article>
    </article>
  </div>

  <ng-template #elseBlock data-aos="fade-left" data-aos-duration="800">
    <p-skeleton height="60px" styleClass="mb-7 mt-2" width="485px"></p-skeleton>
    <p-skeleton height="180px" styleClass="mb-1"></p-skeleton>
</ng-template>
</section>
