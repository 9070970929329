import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { capacitacionViewId, updateListCapacitaciones } from 'src/app/store/actions/capacitaciones.actions';
import { dialogExamenes } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { selectDialogExamen } from 'src/app/store/selectors/dialog.selector';

@Component({
  selector: 'app-exam-file',
  templateUrl: './exam-file.component.html'
})
export class ExamFileComponent implements OnInit, OnDestroy {
  open = false
  loading = false
  end = false
  _open!: Subscription
  _capacitacion!: Subscription
  _subscriptionRest!: Subscription
  capacitacion!: CourseI | undefined

  uploadedFiles: any[] = [];
  constructor(
    private store: Store<AppState>,
    private restService: ApiRestService,
    private messageService: MessageService
  ) { }

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    let formData = new FormData()
    formData.append('title', this.uploadedFiles[0].name)
    formData.append('name', this.uploadedFiles[0].name)
    formData.append('file', this.uploadedFiles[0])
    if (this.capacitacion)
      formData.append('course_id', (this.capacitacion?.id).toString())
    this.loading = true
    this._subscriptionRest = this.restService.postApi('exams', formData).subscribe(
      {
        next: res => {
          if (res) {
            this.end = true
            setTimeout(() => {
              this.closeDialog()
            }, 900)
            this.messageService.add({ severity: 'info', summary: 'Archivo cargado', detail: '' });
            if(this.capacitacion)
            this.store.dispatch(capacitacionViewId({ id: this.capacitacion?.id }))
          }
        },
        error: err => {
          this.messageService.add({ severity: 'error', summary: 'Un error ha ocurrido', detail: err.message });
        }

      }
    )

  }
  closeDialog() {
    this.store.dispatch(dialogExamenes({ dialogExamen: false }))
    this._subscriptionRest?.unsubscribe()
  }
  ngOnInit(): void {
    this._open = this.store.select(selectDialogExamen)
      .subscribe(
        res => this.open = res
      )

    this._capacitacion = this.store.select(selectCapacitacion)
      .subscribe(res => this.capacitacion = res)
  }
  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._capacitacion?.unsubscribe()
  }
}
