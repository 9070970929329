<section data-aos="fade-left" data-aos-duration="800">
    <div class="pb-6 flex gap-4">
        <app-button-tab *ngFor="let year of years" [disabled]="loading" [active]="actualYear == year" (click)="changeYear(year)" [value]="year.toString()" [title]="year.toString()"></app-button-tab>
    </div>
    <div class="flex justify-between items-center w-full pb-4">
        <app-titles-atom title="Certificaciones presentadas"></app-titles-atom>
        <h2 class="text-4xl text-primary">{{certifications?.length ? certifications.length : 0}}</h2>
    </div>
    <div class="pt-4">
        <TableAtom [loading]="loading" [columns]="columns" [rows]="dataRow"/>
    </div>
</section>
