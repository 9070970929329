import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tablepipes'
})
export class TablepipesPipe implements PipeTransform {

  transform(value: string | number, type: string): string | number {
    if (type == 'date') {
      const date = new Date(value);
      const hours = this.padZero(date.getHours());
      const minutes = this.padZero(date.getMinutes());
      const day = this.padZero(date.getDate());
      const month = this.padZero(date.getMonth() + 1);
      const year = date.getFullYear();
      if(`${day}/${month}/${year}` != 'NaN/NaN/NaN'){
        return `${day}/${month}/${year}`;
      }else{
        return '-'
      }
    }
    if (type == 'money') {
    
      let val = Number(value)
      return new Intl.NumberFormat('en-DE').format(val);
    }
    if(type == 'idCOPA'){
      return value?.toString().replace(/(\d{1})(\d{4})(\d{1})/, '$1-$2-$3')
    }
    else {
      return value
    }

  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }
}
