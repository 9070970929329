import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { cleanObject } from 'src/app/core/utils/functions';
import { updateUserInfo } from 'src/app/store/actions/user.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private restAPI: ApiRestService,
    private messageService: MessageService
  ) { }
  _user!: Subscription
  user: any
  form!: FormGroup
  profilePic!: string
  changePassword = false
  loading = false
  startLoading = true
  @ViewChild('imagenPerfil') uploadPerfil!: ElementRef

  imgChange(data: any) {
    const formData = new FormData()
    formData.append('profile_picture', data)

    this.restAPI.postApi('auth', formData, {_method:'PUT'}).subscribe(
      {
        next: (res) => {
          this.messageService.add(
            {
              severity: 'info',
              summary: 'Éxito',
              detail: data == 'delete' ? 'Foto de perfil eliminada' : 'Imagen cargada con éxito'
            }
          )
          this.store.dispatch(updateUserInfo())
          this.loading = false
        },
        error: (err) => {
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Error',
              detail: 'Un error ha ocurrido intenta nuevamente más tarde'
            }
          )
          this.loading = false
        }
      }
    )
  }
  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      // Display the selected image
      
      this.loading = true
     
      const reader = new FileReader();

      reader.onloadend = () => {
        // `reader.result` contains the contents of the file as a data URL
        const dataUrl = reader.result as string;

        // Convert data URL to a blob
        const blob = this.dataURLtoBlob(dataUrl);

        this.imgChange(blob);
      };

      reader.readAsDataURL(file);
    }
  }

  dataURLtoBlob(dataUrl: string): Blob {
    // Convert data URL to a blob
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  deleteImage() {
    this.loading = true
    this.imgChange('delete')
  }

  cargarImagen() {
    this.uploadPerfil.nativeElement.click()
  }

  editUser() {
    const formToSend = (cleanObject(this.form.value))
    this.loading = true
    this.restAPI.putApi('auth', formToSend).subscribe(
      {
        next: (res) => {
          this.messageService.add(
            {
              severity: 'info',
              summary: 'Éxito',
              detail: 'Datos del perfil actualizados'
            }
          )
          this.store.dispatch(updateUserInfo())
          this.loading = false
        },
        error: (err) => {
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Error',
              detail: 'Un error ha ocurrido intenta nuevamente más tarde'
            }
          )
          this.loading = false
        }
      }
    )

  }
  ngOnInit(): void {
    this.form = this.fb.group({
      name: new FormControl(),
      email: new FormControl(),
      last_name: new FormControl(),
      password: new FormControl()
    })
    this._user = this.store.select(selectUserAuth)
      .subscribe(
        {
          next: (res) => {
            this.user = res
            if (res) {
              this.form.controls['name'].patchValue(res.name )
              this.form.controls['last_name'].patchValue( res.last_name)
              this.form.controls['email'].patchValue(res.email)
              this.startLoading = false
            }
          }
        }
      )

  }

  ngOnDestroy(): void {
    this._user?.unsubscribe()
  }
}
