import { createAction, props } from "@ngrx/store";
import { Mats } from "src/app/core/models/Matriculado.interface";

// action action
export const matriculadosAction = createAction('[Matriculados] Carga de matriculados',
    props<{ matriculados: Mats[] }>());

export const getMatriculados = createAction('[Matriculados] Get matriculados')

export const getMatriculado = createAction(
    '[Matriculados] Obtención de un matriculado',
    props<{ matriculado: any }>())

export const loadingMatriculado = createAction(
    '[Matriculados] Buscando Matriculado en la API',
    props<{id:string}>()
)

export const paginationMatriculados = createAction(
    '[Matriculados] Cambiando con props',
    props<{props:any}>()
)