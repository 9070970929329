import { createReducer, on } from '@ngrx/store';
import { DeleteDialogI, actionLoadClases, dialogDeleteAction, dialogExamenes, dialogLinkPostCapacitacion, showAdjuntarComprobante, showCertificacionConfirm, showCertificacionFile, showConfirmBaja, showConfirmarClase, showDeleteCapacitacion, showDialogFile, showFirmFile, showInscripcionClases, showPaymentInfo, showExamenConfirm, dialogPutAction, dialogWithACtions, DialogWithActionsI, showExamenDialogFile, viewCertificacionFile, firmaGlobalFile, dialogRecovery } from '../actions/dialog.actions';
import { ClassI } from 'src/app/core/models/Courses.interface';
import { deleteSuccesInscription } from '../actions/inscription.actions';

export interface StateDialog {

    showPaymentInfo: boolean
    inscripcionClase: boolean
    classes?: ClassI[]
    confirmacionClase: boolean
    confirmBaja: boolean
    isLoading: boolean
    successBaja: boolean
    showComprobantePago: boolean
    showFirmFile: {
        open: boolean,
        action?: any
    }
    showCertificacionFile: {
        state: boolean,
        action: any
    },
    showCertificacionConfirm: any
    viewCertificacionFile: any
    firmaGlobalFile: boolean
    showExamenConfirm: any
    showDelete: boolean
    showFile: {
        open: boolean,
        file?: string | undefined
    },
    deleteDialog: DeleteDialogI
    postCapacitacion: boolean
    dialogExamen: boolean
    putDialog: DeleteDialogI
    dialogActions: DialogWithActionsI,
    showExamenFile: {
        open:boolean,
        file?: string
    }
    dialogRecovery: DialogWithActionsI
}

const initialState: StateDialog = {
    inscripcionClase: false,
    showPaymentInfo: false,
    confirmacionClase: false,
    confirmBaja: false,
    isLoading: false,
    successBaja: false,
    showComprobantePago: false,
    showFirmFile: {
        open: false
    },
    showCertificacionFile: {
        state: false,
        action: null
    },
    viewCertificacionFile: {
        state: false,
        data: null
    },
    showCertificacionConfirm: {
        state: false,
        data: null,
        action: null
    },
    firmaGlobalFile: false,
    showExamenConfirm: {
        state: false,
        data: null
    },
    showDelete: false,
    showFile: {
        open: false
    },
    deleteDialog: {
        open: false,
        url: '',
        action: null
    },
    putDialog: {
        open: false,
    },
    postCapacitacion: false,
    dialogExamen: false,
    dialogActions: {
        open: false,
        end: false,
        loading:false
    },
    showExamenFile: {
        open: false,
    },
    dialogRecovery: {
        open:false, end:false, loading:false
    }
}

export const dialogReducer = createReducer(
    initialState,
    on(showPaymentInfo,
        (state, { showPaymentInfo }) => ({ ...state, showPaymentInfo })),
    on(showInscripcionClases,
        (state, { inscripcionClase }) => ({ ...state, inscripcionClase })),
    on(showConfirmarClase,
        (state, { confirmacionClase }) => ({ ...state, confirmacionClase })),
    on(showConfirmBaja,
        (state, { confirmBaja }) => ({ ...state, confirmBaja })),
    on(actionLoadClases,
        (state, { classes }) => ({ ...state, classes })),
    on(deleteSuccesInscription,
        (state, { successBaja }) => ({ ...state, successBaja })),
    on(dialogLinkPostCapacitacion,
        (state, { postCapacitacion }) => ({ ...state, postCapacitacion })),
    on(showAdjuntarComprobante,
        (state, { showComprobantePago }) => ({ ...state, showComprobantePago })),
    on(showFirmFile, (state, { showFirmFile }) => ({ ...state, showFirmFile })),
    on(showCertificacionFile, (state, { showCertificacionFile }) => ({ ...state, showCertificacionFile })),
    on(showCertificacionConfirm, (state, { showCertificacionConfirm }) => ({ ...state, showCertificacionConfirm })),
    on(firmaGlobalFile, (state, { firmaGlobalFile }) => ({ ...state, firmaGlobalFile })),
    on(viewCertificacionFile, (state, { viewCertificacionFile }) => ({ ...state, viewCertificacionFile })),
    on(showExamenConfirm, (state, { showExamenConfirm }) => ({ ...state, showExamenConfirm })),
    on(showDeleteCapacitacion,
        (state, { showDelete }) => ({ ...state, showDelete })),
    on(showDialogFile,
        (state, { showFile }) => ({ ...state, showFile })),
    on(showExamenDialogFile,
        (state, { showExamenFile }) => ({ ...state, showExamenFile })),
    on(dialogDeleteAction, (state, { deleteDialog }) => ({ ...state, deleteDialog })),
    on(dialogExamenes,
        (state, { dialogExamen }) => ({ ...state, dialogExamen })),
    on(dialogPutAction, (state, { putDialog }) => ({ ...state, putDialog })),
    on(dialogWithACtions, (state, { dialogActions }) => ({ ...state, dialogActions })),
    on(dialogRecovery, (state, { dialogRecovery }) => ({ ...state, dialogRecovery })),

)