
<div  class="tabsHome" data-aos="fade-left" *ngIf="!loading; else elseBlock">
    <div>
        <div class="pt-2 pb-8 pointer-events-none">
            <app-button-tab [active]="true" title="{{examsPending}} evaluaciones pendientes de corrección"></app-button-tab>
        </div>
        
        <p-accordion class="text-primary pt-2" >
            <p-accordionTab *ngFor="let item of data, let index = index" class="text-primary">
                <ng-template  pTemplate="header">
                    <p class="opacity-0">
                        Header I
                    </p>
                    <div (click)="changeActive(index)" class="flex absolute px-8 top-0 left-0 h-full items-center justify-between w-full">
                        <span class="vertical-align-middle">{{item.title}}</span>
                        <span data-aos-duration="800"  [ngClass]="activeIndex == index ? 'rotate-0' : 'rotate-180'"  class="visible flex items-center transform transition duration-600 ease-in-out">
                            <svg 
                            class=""
                            xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M23.1187 12.8687C22.777 13.2104 22.223 13.2104 21.8813 12.8687L12 2.98744L2.11872 12.8687C1.77701 13.2104 1.22299 13.2104 0.881283 12.8687C0.539574 12.527 0.539574 11.973 0.881283 11.6313L11.3813 1.13128C11.723 0.789573 12.277 0.789573 12.6187 1.13128L23.1187 11.6313C23.4604 11.973 23.4604 12.527 23.1187 12.8687Z"
                                    fill="#C3363A" />
                            </svg>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="overflow-x-scroll" *ngIf="exams">
                        <TableAtom [loading]="false" [columns]="columns" [rows]="exams[index]"/>
                    </div>
                </ng-template>
            </p-accordionTab>
        
        
        </p-accordion>
    </div>
</div>

<ng-template #elseBlock>
    <p-skeleton height="54px" styleClass="mb-7 mt-2" width="485px"></p-skeleton>
    <p-skeleton height="80px" styleClass="mb-1"></p-skeleton>
    <p-skeleton height="80px" styleClass="mb-1"></p-skeleton>
    <p-skeleton height="80px" styleClass="mb-1"></p-skeleton>
    <p-skeleton height="80px" styleClass="mb-1"></p-skeleton>
    <p-skeleton height="80px" styleClass="mb-1"></p-skeleton>
</ng-template>