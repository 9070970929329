import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { CapacitacionesComponent } from './capacitaciones/capacitaciones.component';
import { AtomsModule } from '../../atoms/atoms.module';
import { ExamenesComponent } from './examenes/examenes.component';
import { CertificacionesComponent } from './certificaciones/certificaciones.component';
import { MisCapacitacionesComponent } from './mis-capacitaciones/mis-capacitaciones.component';
import { PagesRoutingModule } from './pages-routing.module';
import { MatriculadosComponent } from './matriculados/matriculados.component';
import { ComponentsModule } from 'src/app/components/components.module';



@NgModule({
  declarations: [
    HomeComponent,
    CapacitacionesComponent,
    ExamenesComponent,
    CertificacionesComponent,
    MisCapacitacionesComponent,
    MatriculadosComponent
  ],
  imports: [
    AtomsModule, 
    CommonModule,
    PagesRoutingModule,
    ComponentsModule
  ],
  exports: [
    HomeComponent,
  ]
})
export class PagesModule { }
