import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { showExamenConfirm } from 'src/app/store/actions/dialog.actions';
import { AppState } from 'src/app/store/appReducer';

import { Subscription } from 'rxjs'
import { selectDialogExamenConfirm } from 'src/app/store/selectors/dialog.selector';
import { MessageService } from 'primeng/api';
import { ApiRestService } from 'src/app/core/services/api-rest.service';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { getExamenes } from 'src/app/store/actions/examenes.actions';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { capacitacionViewId } from 'src/app/store/actions/capacitaciones.actions';

@Component({
  selector: 'app-examen-confirm',
  templateUrl: './examen-confirm.component.html',

})

export class ExamenConfirmComponent implements OnInit, OnDestroy{

  open: boolean = false
  end: boolean = false
  loading: boolean = false
  _open!: Subscription
  _subscriptionRest!: Subscription
  _subcriptionCapacitacion!: Subscription
  data: any
  capacitacion!: CourseI | undefined
  @Input() info!: any
  form!: FormGroup

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private service: ApiRestService,
    private messageService: MessageService,
  ) { }


  uploadedFiles: any[] = [];
  show = true

  acceptConfirm() {
    let setForm = {
      _method: "PUT",
      approved: 1,
    }
    
    this.service.putApi("exams/" + this.data?.id, setForm, null).subscribe(res => {
      this.end = true
      this.loading = false
      this.updateData()
      setTimeout(() => {
        this.closeDialog()
      }, 900)
    }, (err) => {
      //#Error
      if (typeof err.error.msg !== "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg[key]
            }
          );
        })
      }
      if (typeof err.error.msg == "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          return this.messageService.add(
            {
              severity: 'error',
              summary: ' Un error ha ocurrido',
              detail: err.error.msg
            }
          );
        })
      }

      this.loading = false
    }
    )
  }

  closeDialog(){
    this.store.dispatch(showExamenConfirm({ showExamenConfirm: { state: false, data: this.data } }))
    this.end = false
    this.loading = false
  }

  updateData(){
    this.store.dispatch(getExamenes())
    if(this.capacitacion){
      this.store.dispatch(capacitacionViewId({id: this.capacitacion.id}))
    }
  }

  desaprobar() {

    let setForm = {
      _method: "PUT",
      approved: 0,
      motivo: this.form.value.motivo
    }

    this.service.putApi("exams/" + this.data?.id, setForm, null).subscribe(res => {

      this.messageService.add(
        {
          severity: 'success',
          summary: 'Éxito',

          detail: res.data.msg
        }
      );
      this.closeDialog()
      this.updateData()
    }, (err) => {
      //#Error
      if (typeof err.error.msg !== "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg[key]
            }
          );
        })
      }

      if (typeof err.error.msg == "string") {
        Object.keys(err.error.msg).map((key: any, index: any) => {
          return this.messageService.add(
            {
              severity: 'error',

              summary: 'Un error ha ocurrido',
              detail: err.error.msg
            }
          );
        })
      }

      this.loading = false
    }
    )
  }

  onChangeValue(event: any) {
    this.form.controls['motivo'].patchValue(event)
  }

  ngOnInit(): void {

    this.form = this.fb.group({
      motivo: new FormControl(),
    });
    
    this._open = this.store.select(selectDialogExamenConfirm)
      .subscribe(
        (res) => {
          this.open = res.state
          this.data = res.data
        }
      )

    this._subcriptionCapacitacion = this.store.select(selectCapacitacion).subscribe((res) => 
        this.capacitacion = res
    )
  }

  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._subcriptionCapacitacion?.unsubscribe()
    this._subscriptionRest?.unsubscribe()
  }
}