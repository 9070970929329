import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mergeMap, tap } from "rxjs";
import { presenteAction, getClasses, updateClass, ausenteAction, loadedClasses } from '../actions/attendance.actions';
import { AttendanceService } from '../../core/services/attendance.service';

@Injectable()
export class AttendanceEffect {
  constructor(
    private actions$: Actions,
    private attendanceService:AttendanceService
  ) { }

  attendance$ = createEffect(
    () => this.actions$.pipe(
        ofType(presenteAction, ausenteAction),
        mergeMap(({id, student_id, class_id, course_id}) => this.attendanceService.postAttendance(id, student_id, class_id).pipe(
            mergeMap(() => [
                updateClass({id: course_id})
            ])
        )
        )
    )
  )

  updateClass$ = createEffect(
    () => this.actions$.pipe(
      ofType(updateClass),
      mergeMap(({ id }) =>
        this.attendanceService.getAttendance(id).pipe(
          mergeMap((res) => [
            getClasses({ id: res }),
            loadedClasses({course: res})
          ])
        )
      )
  ))
}