import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as printJS from 'print-js';
import { Subscription } from 'rxjs';
import { TableI } from 'src/app/atoms/table/table.interface';
import { showDialogFile, showExamenConfirm, showExamenDialogFile } from 'src/app/store/actions/dialog.actions';
import { getExamenes } from 'src/app/store/actions/examenes.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectExamenesList, selectExamenesPending, selectLoadingExamenes } from 'src/app/store/selectors/examenes.selector';

@Component({
  selector: 'app-examenes',
  templateUrl: './examenes.component.html'
})
export class ExamenesComponent implements OnInit, OnDestroy {
  activeIndex: number | undefined ;
  _exams!: Subscription
  data?: any | undefined
  _examsPending!:Subscription
  examsPending?:any

  loading:boolean = true
  _loadingSub!:Subscription

  exams?:any


  constructor(private store: Store<AppState>) { }

  changeActive(number: number) {
    if (number == this.activeIndex) {
      this.activeIndex = undefined
    } else {
      this.activeIndex = number
    }

  }

  columns: TableI[] = [
    {
      label: 'Denominación',
      key: 'denominacion',
      type: "string"
    },
    {
      label: 'Profesional',
      key: 'matriculado_name',
      type: "string"
    },
    {
      label: 'Puntos',
      key: 'points',
      type: "string"
    },
    {
      label: 'Acciones',
      key: '',
      type: 'action',
      action: [
        {
          action: (event: any, row: any) => {
            this.store.dispatch(showExamenDialogFile({ showExamenFile: { open: true, file: row.file } }))
          },
          svg: 'assets/icons/table/eye.svg',
          svgHover: 'assets/icons/table/eyeHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        },
        {
          action: (event: any, row: any) => {
            if((row.file as string).includes('.pdf')){
              window.open(row.file, '_blank');
            }else{
              printJS(row.file, 'image')
            }
          },
          svg: 'assets/icons/table/print.svg',
          svgHover: 'assets/icons/table/printHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        },
        {
          action: (event: any, row: any) => {
            this.store.dispatch(showExamenConfirm({ showExamenConfirm: { state: true, data: row } }))
          },
          svg: 'assets/icons/table/ok.svg',
          svgHover: 'assets/icons/table/okHover.svg',
          class: 'border-primary rounded hover:primary text-primary hover:text-white'
        },
      ]
    }
  ]

  prueba(){}

  ngOnInit(): void {

    this._loadingSub = this.store.select(selectLoadingExamenes)
    .subscribe((d) =>  this.loading = d)

    this.store.dispatch(getExamenes())
    this._exams = this.store.select(selectExamenesList).subscribe(
      res => {
        this.data = res
        this.exams = res?.map((exams)=>{
          return exams.exams_details.map((exams_details: {
              id: number; 
              title: string; 
              student: { 
                name: string;
                last_name: string; 
              };
              file:string;
              approved:any
            }) => {
            return{
              denominacion: exams_details.title,
              matriculado_name: exams_details.student?.name + ' ' + exams_details.student?.last_name,
              points: exams.point.grant,
              id: exams_details.id,
              file: exams_details.file,
              approved: exams_details.approved
            }
          })
          .filter((exam: { approved: number; }) => exam.approved == null)
        })
      }
    )

    this._examsPending = this.store.select(selectExamenesPending).subscribe(
      res => {
        this.examsPending = res
      }
    )
  }
  ngOnDestroy(): void {
    this._exams.unsubscribe()
    this._examsPending.unsubscribe()
    this._loadingSub.unsubscribe()
  }


}
