import { Expression } from "@angular/compiler";
import { createAction, props } from "@ngrx/store";
import { UserI } from "src/app/core/models/UserI.interface";


// action action
export const actionLoadingWithEmailAndPassword = createAction(
    '[AUTH] Loading Authentication',
    props<{ email: string; password: string }>()
)

export const getUserAction = createAction(
    '[User] Get User Data',
    props<{ user: any }>()
);

export const authAction = createAction(
    '[AUTH] Logged',
    props<{ isLogged: boolean }>()
)


export const authError = createAction(
    '[AUTH] Failed to authenticate',
    props<{ error: any }>()

)

export const actionAuthMe = createAction(
    '[AUTH] Searching data',
    props<{ token: any }>()
)

export const successAuth = createAction(
    '[AUTH] Success auth'
)

export const notLogged = createAction(
    '[AUTH] Not logged'
)

export const logoutAuth = createAction(
    '[AUTH] Log out',
    props<{ isLogged: any }>()
) 

export const updateUserInfo = createAction(
    '[User] Updating User Info'
)