import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CourseI } from '../models/Courses.interface';
import { environment } from 'src/environments/environment';
import { GetTokenService } from './get-token.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(private http: HttpClient, private GetTokenS: GetTokenService) { }

  token = this.GetTokenS.getToken()

  getCourseId(id: number | string): Observable<CourseI> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });
    const params = new HttpParams()
      .set('id', typeof id === 'string' ? id : id.toString());
    return this.http.get<CourseI>(environment.apiUrl + 'courses/' + id, { headers, params }); // Adjust the path as needed
  }
  deleteCourse(id: number | string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });
    const params = new HttpParams()
      .set('id', typeof id === 'string' ? id : id.toString());
    return this.http.delete(environment.apiUrl + 'courses/' + id, { headers, params }); // Adjust the path as needed
  }

  getCourse(): Observable<CourseI> {
    // Specify the path to your JSON file
    const jsonFilePath = 'assets/JSONS/course.json'; // Adjust the path as needed

    return this.http.get<CourseI>(jsonFilePath);
  }
  getCourses(): Observable<CourseI[]> {
    // Specify the path to your JSON file
    const jsonFilePath = 'assets/JSONS/courses.json'; // Adjust the path as needed

    return this.http.get<CourseI[]>(jsonFilePath);
  }

  getCourses3(props: any, token?: string): Observable<any> {
    let headers
    this.token = this.GetTokenS.getToken()
    if (this.token) {
      headers = new HttpHeaders({
        'Authorization': `Bearer ${this.token.access_token}`
      });
    }
    else {
      headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
    }

    let params = new HttpParams()

    Object.keys(props).forEach(key => {
      const value = props[key];

      if (Array.isArray(value)) {
        // If the value is an array, add multiple parameters with the same key and different indices
        value.forEach((item, index) => {
          params = params.append(`${key}[${index}]`, item.toString());
        });
      } else {
        // For other types, convert them to string
        params = params.set(key, value.toString());
      }
    });


    return this.http.get(environment.apiUrl + 'courses', { headers, params });
  }


  postCourse(form: any): Observable<any> {
    this.token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    });
    return this.http.post(environment.apiUrl + 'courses', form, { headers })
  }

  newCourseSoft(form: any): Observable<any> {
    this.token = this.GetTokenS.getToken()
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token.access_token}`
    })

    return this.http.post(environment.apiUrl + 'courses/soft', form, { headers })
  }
}
