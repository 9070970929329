import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { capacitacionesActive, capacitacionesTabs, changeNavTabs } from 'src/app/store/actions/ui.actions';
import { AppState } from 'src/app/store/appReducer';
import { selectActiveTab, selectTabs } from 'src/app/store/selectors/ui.selector';
import { navMatriculado } from '../home.component';
import { actionChangePage, actionLoadingMisCapacitaciones, capacitacionView } from 'src/app/store/actions/capacitaciones.actions';
import { selectLoadingCapacitaciones, selectMisCapacitaciones, selectRowsCapacitaciones } from 'src/app/store/selectors/capacitaciones.selector';
import { CourseI } from 'src/app/core/models/Courses.interface';
import { Router } from '@angular/router';
import { PaginatorState } from 'primeng/paginator';


@Component({
  selector: 'app-mis-capacitaciones',
  templateUrl: './mis-capacitaciones.component.html'
})
export class MisCapacitacionesComponent implements OnInit, OnDestroy {
  buttonTabs$!: Observable<Array<any>>
  _rows!: Subscription
  rows!: any
  first: number = 0;
  tabActive$!: any
  _loading!: Subscription
  loading: boolean = true
  _misCapacitaciones!: Subscription
  misCapacitaciones!: CourseI[] | undefined

  constructor(private store: Store<AppState>, private router: Router) { }

  changeActive(active: string) {
    this.store.dispatch(actionLoadingMisCapacitaciones({ loading: true }))
    this.store.dispatch(capacitacionesActive({ activeCapt: active }))
  }
  dispatchCard(event: any) {
    this.store.dispatch(capacitacionView({ capacitacion: event }))
    this.router.navigate(['capacitaciones', event.id])
  }
  onPageChange(event: PaginatorState) {
    this.store.dispatch(
      actionChangePage({ page: event.page ? event.page + 1 : 1 }))
    if (event.first) {
      this.first = event.first;
      // this.store.dispatch(actionChangePage({ page: event.first  }))
    }
  }

  cuposDisponibles(capacitacion: CourseI) {

    let cuposTotales = 0
    if (capacitacion.current_class) {
      cuposTotales = (+capacitacion.current_class?.virtual_cupo) + (+capacitacion.current_class?.in_person_cupo)
    }
    let cuposDisponibles = (+cuposTotales) - (+capacitacion.inscriptions_count)
    return cuposDisponibles
  }

  ngOnInit(): void {
    this.changeActive('A realizar')
    this.store.dispatch(changeNavTabs({ navTabs: navMatriculado }))
    this.store.dispatch(capacitacionesTabs({ capacitacionesTabs: [{ label: 'A realizar', value: "A realizar" }, { label: 'Finalizadas', value: "Finalizadas" }] }))
    this.store.dispatch(actionLoadingMisCapacitaciones({ loading: true }))
    this.buttonTabs$ = this.store.select(selectTabs)
    this.tabActive$ = this.store.select(selectActiveTab)
    this._loading = this.store.select(selectLoadingCapacitaciones)
      .subscribe(res => this.loading = res)
    this._misCapacitaciones = this.store.select(selectMisCapacitaciones)
      .subscribe(res => {
        this.misCapacitaciones = res
      })
    this._rows = this.store.select(selectRowsCapacitaciones).subscribe(
      (rows) => {
        this.rows = rows
      }
    )

  }



  ngOnDestroy(): void {
    this._loading?.unsubscribe()
    this._misCapacitaciones?.unsubscribe()
    this._rows?.unsubscribe()
  }

}
