import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs';
import { AppState } from 'src/app/store/appReducer';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';

export const adminGuard: CanActivateFn = (route, state) => {
  const store = inject(Store<AppState>)
  const router = inject(Router)

  return store.select(selectUserAuth).pipe(
    filter((user) => !!user?.roles[0]),
    map((user) => {
      if (user?.roles[0]?.name === 'administrador general') {
        return true
      } else {
        router.navigate([''])
        return false
      }
    }),

  );

};
