import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/appReducer';
import { Observable, Subscription, catchError } from 'rxjs';
import { selectDialogClase, selectDialogClases } from 'src/app/store/selectors/dialog.selector';
import { showInscripcionClases } from 'src/app/store/actions/dialog.actions';
import { ClassI, CourseI } from 'src/app/core/models/Courses.interface';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { selectCapacitacion } from 'src/app/store/selectors/capacitaciones.selector';
import { InscriptionsService } from 'src/app/core/services/inscriptions.service';
import { actionUpdateCapacitaciones } from 'src/app/store/actions/capacitaciones.actions';
import { MessageService } from 'primeng/api';
import { selectUserAuth } from 'src/app/store/selectors/user.selector';

interface dataToSend {
  course_id: any
  classes: any
  matricula?: any
}
@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html'
})
export class InscriptionComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private inscriptionSvc: InscriptionsService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) { }
  _open!: Subscription
  open!: boolean
  end: boolean = false
  _user!: Subscription
  user: any
  _classes!: Subscription
  classes!: any[] | undefined
  _capacitacion!: Subscription
  capacitacion!: CourseI | undefined
  matriculaForm = new FormControl()

  loadingReq =false

  _httpRequ!: Subscription

  selectedClasses: any[] = [];
  changeRadio: any = (index: any, type: any) => {
    this.asistencia.controls[index].patchValue({
      type: type,
      checked: true
    })
  }

  closeDialog() {
    this.store.dispatch(showInscripcionClases({ inscripcionClase: false }))
    this.form.reset()
    this.asistencia.clear();
    this.end = false
  }
  changeType(index: any) {
    if (
      this.form.get('asistencia.' + index + '.virtual')?.value == 1
      && this.form.get('asistencia.' + index + '.in_person')?.value == 1) {
      this.asistencia.controls[index].patchValue({
        type: null,
        checked: false
      })
    } else {
      this.asistencia.controls[index].patchValue({
        type: null,
      })

    }
  }


  acceptInscription() {
    const clases = this.form.controls['asistencia'].value
    this.loadingReq= true
    let dataToSend: dataToSend = {
      course_id: this.capacitacion?.id,
      classes: clases.map((res: any) => {
        if (res.checked) {
          return {
            id: res.id,
            type: res.type ? res.type : res.in_person == 1 ?
              'in_person' :
              'virtual'
          }
        }
        return null
      }).filter((item: any) => item !== null)
    }
    if (this.matriculaForm.value != null) {
      dataToSend = { ...dataToSend, matricula: this.matriculaForm.value }
    }

    this._httpRequ = this.inscriptionSvc.postInscription(dataToSend).subscribe(
      {
        next: (res) => {
          if (this.capacitacion) {
            this.store.dispatch(actionUpdateCapacitaciones({ id: this.capacitacion.id }))
            this.end = true
            this.loadingReq = false
            setTimeout(() => {
              this.closeDialog()

            }, 1000)
          }
        },
        error: (err) => {
          this.loadingReq = false
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Un error ha ocurrido intenta nuevamente',
              detail: err.message
            }
          );
        },
      }
    )


  }

  form!: FormGroup
  get asistencia() {
    return this.form.controls["asistencia"] as FormArray
  }

  populateClasesAssist(classes: any[]) {
    classes.forEach((el: any) => {
      const inscriptionVirtual = el?.inscriptions_virtual_count != null ? Number(el.inscriptions_virtual_count) : 0
      const inscriptionPresencial = el?.inscriptions_in_person_count != null ? Number(el.inscriptions_in_person_count) : 0
      const cupoTotalClase = inscriptionPresencial + inscriptionVirtual

      const limiteCupo = el?.virtual_cupo != null ? Number(el.virtual_cupo) : 0 +
        el?.in_person_cupo ? Number(el.in_person_cupo) : 0


      const clase = this.fb.group({
        id: new FormControl(el.id),
        checked: new FormControl(el.externa == 1 ? true : false),
        externa: new FormControl(el.externa ? el.externa : null),
        virtual: new FormControl(el.virtual ? el.virtual : null),
        in_person: new FormControl(el.in_person ? el.in_person : null),
        type: new FormControl(null),
        total_cupos: new FormControl((limiteCupo - cupoTotalClase) == 0),
        cupos: new FormControl(el.virtual ? el.virtual_cupo - el.inscriptions_virtual_count : el.in_person_cupo - el.inscriptions_in_person_count),
        cupos_virtual: new FormControl(el.virtual && el.virtual_cupo - el.inscriptions_virtual_count),
        cupos_p: new FormControl(el.in_person && el.in_person_cupo - el.inscriptions_in_person_count),
      });
      (this.form.get('asistencia') as FormArray).push(clase);
    })
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      asistencia: this.fb.array([])
    });
    this._open = this.store.select(selectDialogClase)
      .subscribe(
        res => {
          this.open = res
          if (this.classes && res && this.capacitacion) {
            this.populateClasesAssist(this.classes)
          }
        }
      )
    this._classes = this.store.select(selectDialogClases)
      .subscribe(
        classes => {
          if (classes) {
            this.asistencia.clear()
            this.classes = classes || [];

          }
        }
      )

    this._capacitacion = this.store.select(selectCapacitacion)
      .subscribe(
        res => {
          this.capacitacion = res
        }
      )

    this._user = this.store.select(selectUserAuth)
      .subscribe(res => this.user = res)
  }

  ngOnDestroy(): void {
    this._open?.unsubscribe()
    this._classes?.unsubscribe()
    this._capacitacion?.unsubscribe()
    this._httpRequ?.unsubscribe()
    this._user?.unsubscribe()
    this.selectedClasses = []
  }
}
